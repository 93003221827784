import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { locale } from '../../locales'
import { ServiceContext } from '../../providers/ServicesProvider'
import { selectedClientState } from '../../state/SelectedPharmacyState'
import theme from '../../styles/theme'
import { sortBy } from 'lodash'
import { PlatformApiPaths } from '../../PlatformApiPaths'
import { ClientTypes } from '../../types/entities/ClientPermission'
import Box from '@mui/material/Box'
import { User, UserBase } from '../../types/entities/User'
import UserDetailsContainer from './components/UserDetailsContainer'
import MoreMenu from '../../components/Interactions/MoreMenu'
import {
  NewUserAuthId,
  PermissionNames,
  PharmacyFeatureFlags,
} from '../../constants'
import ModalDialog from '../../components/Interactions/ModalDialog'
import UserDetails from './components/UserDetails'
import ConfirmDialog from '../../components/Interactions/ConfirmDialog'
import Alert from '@mui/material/Alert'
import VirtualizedSelectableList from '../../components/Data/VirtualizedSelectableList'
import Typography from '@mui/material/Typography'
import Markdown from 'marked-react'
import { GetErrorMessage } from '../../utils/ErrorHandling'
import { authenticatedUserState } from '../../state/AuthenticationState'
import AutoSizingBox from '../../components/Util/AutoSizingBox'
import { Client, ClientPharmacyGroup } from './entities/Entities'
import DialogContentText from '@mui/material/DialogContentText'
import Button from '@mui/material/Button'
import { useGlobalIsLoading } from '../../hooks/useIsLoading'

const translations =
  locale.translation.SettingsPage.UserManagementTab.UserManagement

const permissions = [
  PermissionNames.Commercials,
  // PermissionNames.Dispenses,
  // PermissionNames.Forecasts,
  PermissionNames.Stock,
  PermissionNames.MonthEndReconciliation,
  PermissionNames.BookInHealthscore,
]

const permissionKeyToFeatureFlags: { [key: string]: string } = {
  Commercials: PharmacyFeatureFlags.EnableAnalytics,
  MER: PharmacyFeatureFlags.EnableMonthEndReconciliation,
  BHS: PharmacyFeatureFlags.EnableBookInHealthScore,
}

const odsToFeatureFlagsMapper = (
  clientDetails: Client
): { [key: string]: string[] } => {
  return Object.fromEntries(
    clientDetails.pharmacies.map((p) => [
      p.odsCode || '',
      p.setFeatureFlags || [],
    ])
  )
}

const UserManagement: FC<{
  clientDetails: Client
  shouldBustLoadUserCacheRef: React.MutableRefObject<boolean>
}> = ({ clientDetails, shouldBustLoadUserCacheRef }) => {
  const { platformHttpService, authenticationService } =
    useContext(ServiceContext)
  const [authenticatedUser, setAuthenticatedUser] = useRecoilState(
    authenticatedUserState
  )
  const selectedClient = useRecoilValue(selectedClientState)
  const [users, setUsers] = useState<UserBase[] | null>([])
  const [groups] = useState<ClientPharmacyGroup[] | undefined>(
    clientDetails.pharmacyGroups
  )
  const [selectedUser, setSelectedUser] = useState<UserBase | null>(null)
  const [userDetails, setUserDetails] = useState<User | null>(null)
  const { setIsLoading } = useGlobalIsLoading()
  const [isAddingUser, setIsAddingUser] = useState<boolean>(false)
  const [isRemovingUser, setIsRemovingUser] = useState<boolean>(false)
  const [isAssigningAllPermissions, setIsAssigningAllPermissions] =
    useState<boolean>(false)
  const [userDetailsToPropagate, setUserDetailsToPropagate] = useState<{
    user: User
    propagationRootClientId: string
  } | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [updateError, setUpdateError] = useState<string | null>(null)
  const handleRefreshUser = async (updatedUser: User) => {
    if (updatedUser.authId === authenticatedUser?.userId) {
      const refreshResult = await authenticationService.refreshTokenAsync()
      if (refreshResult.user) {
        setAuthenticatedUser({ ...refreshResult.user, bustCache: true })
      }
    }
  }

  const odsToFeatureFlagsMap = useMemo(
    () => odsToFeatureFlagsMapper(clientDetails),
    [clientDetails]
  )

  const handleLoadUserDetails = async (user: UserBase) => {
    setUserDetails(null)
    setError(null)
    if (selectedClient && user?.authId) {
      setIsLoading(true)
      const response = await platformHttpService.getAsync<User>(
        PlatformApiPaths.GetUserWithPermissions(
          selectedClient,
          user.authId,
          shouldBustLoadUserCacheRef.current
        ),
        'UsersBaseUri'
      )
      setIsLoading(false)
      if (response.hasErrors) {
        setError(GetErrorMessage(response.statusCode))
        return
      } else {
        shouldBustLoadUserCacheRef.current = false
      }
      setUserDetails(response.data)
    }
  }

  const handleSelectedUserIndexChanged = async (index: number) => {
    if (index >= 0 && users) {
      const user = users[index]
      setSelectedUser(user)
      handleLoadUserDetails(user)
    }
  }

  const handleUserUpdated = async (updatedUser: User): Promise<boolean> => {
    if (selectedClient && users && selectedUser) {
      setIsLoading(true)
      setUpdateError(null)
      const response = await platformHttpService.putAsync(
        PlatformApiPaths.CreateUpdateUser(selectedClient),
        updatedUser,
        'UsersBaseUri'
      )
      setIsLoading(false)
      if (response.hasErrors) {
        setUpdateError(GetErrorMessage(response.statusCode))
        return false
      }

      const userInArrayIndex = users.findIndex(
        (u) => u.authId === updatedUser.authId
      )
      if (userInArrayIndex >= 0) {
        const result = [...users]
        result.splice(userInArrayIndex, 1, updatedUser)
        setUsers(result)
      }
      await handleRefreshUser(updatedUser)
    }
    return true
  }

  const handleClickedAddUser = () => {
    setSelectedUser(null)
    setUserDetails(null)
    setIsAddingUser(true)
  }

  const handleClickedRemoveUser = () => {
    setIsRemovingUser(true)
  }

  const handleClickedAssignAllPermissions = () => {
    setIsAssigningAllPermissions(true)
  }

  const handleClickedForceChangePassword = async () => {
    if (selectedClient && selectedUser) {
      setIsLoading(true)
      const response = await platformHttpService.postAsync(
        PlatformApiPaths.ForceChangeUserPassword(selectedClient),
        selectedUser,
        'UsersBaseUri'
      )
      setIsLoading(false)
      if (response.hasErrors) {
        if (
          response.errors[0].message ===
          'ERROR_USER_NOT_FORCE_TO_CHANGE_PASSWORD'
        ) {
          setUpdateError(translations.ResetPasswordError)
        } else {
          setUpdateError(GetErrorMessage(response.statusCode))
        }
      }
    }
  }

  const handleAddUserDetails = async (
    authId: string,
    email: string,
    fullName: string
  ) => {
    if (selectedClient) {
      setIsAddingUser(false)
      setUpdateError(null)
      setIsLoading(true)
      const response = await platformHttpService.postAsync<UserBase>(
        PlatformApiPaths.CreateUpdateUser(selectedClient),
        {
          userName: email,
          fullName,
        },
        'UsersBaseUri'
      )
      setIsLoading(false)
      if (!response.hasErrors) {
        const u = [response.data!, ...users!]
        setUsers(u)
        setSelectedUser(response.data!)
      } else {
        setUpdateError(GetErrorMessage(response.statusCode))
      }
    }
  }

  const handleRemoveUser = async () => {
    setIsRemovingUser(false)
    setUpdateError(null)
    if (selectedClient && users && selectedUser?.authId) {
      setIsLoading(true)
      const response = await platformHttpService.deleteAsync(
        PlatformApiPaths.DeleteUser(selectedClient, selectedUser.authId),
        null,
        'UsersBaseUri'
      )
      setIsLoading(false)
      if (!response.hasErrors) {
        const removedUserIndex = users.findIndex(
          (u) => u.authId === selectedUser.authId
        )
        if (removedUserIndex >= 0) {
          const u = [...users]
          u.splice(removedUserIndex, 1)
          setUsers(u)
          setUserDetails(null)
        }
      } else {
        setUpdateError(GetErrorMessage(response.statusCode))
      }
    }
  }

  const handleAssignAllPermissions = async () => {
    setIsAssigningAllPermissions(false)
    if (userDetails) {
      const userDetailsToUpdate: User = {
        authId: userDetails.authId,
        fullName: userDetails.fullName,
        userName: userDetails.userName,
        clientPermissions: [],
      }

      userDetails.clientPermissions.forEach((cp) => {
        let permissionsToAdd = [...permissions]
        if (cp.clientType === ClientTypes.Company) {
          permissionsToAdd.push(PermissionNames.Manage)
        }
        if (cp.clientType === ClientTypes.Pharmacy) {
          permissionsToAdd = permissionsToAdd.filter(
            (p) =>
              odsToFeatureFlagsMap[cp.clientId]?.includes(
                permissionKeyToFeatureFlags[p]
              ) || p === PermissionNames.Stock
          )
        }
        userDetailsToUpdate.clientPermissions.push({
          clientId: cp.clientId,
          clientType: cp.clientType,
          name: cp.name,
          permissions: [...permissionsToAdd],
          setFeatureFlags: cp.setFeatureFlags,
        })
      })

      if (await handleUserUpdated(userDetailsToUpdate)) {
        setUserDetails(userDetailsToUpdate)
      }
    }
  }

  const handlePropagatePermissions = async (
    user: User,
    propagationRootClientId: string
  ) => {
    const rootClientPermission = user.clientPermissions.find(
      (cp) => cp.clientId === propagationRootClientId
    )

    if (rootClientPermission) {
      // get group ids that need to be updated
      let groupsIdsToUpdate: string[] = []
      if (rootClientPermission.clientType === ClientTypes.PharmacyGroup) {
        groupsIdsToUpdate = [rootClientPermission.clientId]
      } else if (
        rootClientPermission.clientType === ClientTypes.Company &&
        groups
      ) {
        groupsIdsToUpdate = groups.map((g) => g.groupId!)
      }

      // get pharmacy ids that need to be updated
      let pharmacyIdsToUpdate: string[] = []
      if (
        rootClientPermission.clientType === ClientTypes.PharmacyGroup &&
        groups
      ) {
        pharmacyIdsToUpdate =
          groups
            .find((g) => g.groupId === groupsIdsToUpdate[0])!
            .pharmacies.map((p) => p.odsCode!) ?? []
      } else if (rootClientPermission.clientType === ClientTypes.Company) {
        pharmacyIdsToUpdate = user.clientPermissions
          .filter((cp) => cp.clientType === ClientTypes.Pharmacy)
          .map((cp) => cp.clientId)
      }

      // Non company permissions shouldn't include manage
      const nonCompanyPermissions = rootClientPermission.permissions.filter(
        (p) => p !== PermissionNames.Manage
      )
      // Apply permissions
      const updatedClientPermissions = user.clientPermissions.map((cp) => {
        if (
          cp.clientType === ClientTypes.Company &&
          cp.clientId === rootClientPermission.clientId
        ) {
          // Company
          return {
            ...cp,
            permissions: rootClientPermission.permissions,
          }
        } else if (
          cp.clientType === ClientTypes.PharmacyGroup &&
          groupsIdsToUpdate.includes(cp.clientId)
        ) {
          // Matched pharmacy group
          return {
            ...cp,
            permissions: nonCompanyPermissions,
          }
        } else if (
          cp.clientType === ClientTypes.Pharmacy &&
          pharmacyIdsToUpdate.includes(cp.clientId)
        ) {
          // Matched pharmacy
          const filteredPermissions = nonCompanyPermissions.filter(
            (permission) => {
              // Stock is not in the list of feature flags so it's always allowed
              if (permission === PermissionNames.Stock) {
                return true
              }
              // For other permissions check if the required feature flag is enabled
              const requiredFeatureFlag =
                permissionKeyToFeatureFlags[permission]
              return odsToFeatureFlagsMap[cp.clientId]?.includes(
                requiredFeatureFlag
              )
            }
          )

          return {
            ...cp,
            permissions: filteredPermissions,
          }
        }
        // Unmatched
        return cp
      })

      const userDetailsToUpdate = {
        ...userDetails,
        clientPermissions: updatedClientPermissions,
      }

      if (await handleUserUpdated(userDetailsToUpdate)) {
        setUserDetails({ ...userDetailsToUpdate })
        setUserDetailsToPropagate(null)
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (selectedClient) {
        setIsLoading(true)
        setUserDetails(null)
        setSelectedUser(null)
        setError(null)

        try {
          const usersResponse = await platformHttpService.getAsync<UserBase[]>(
            PlatformApiPaths.GetAllUsersByCompanyId(selectedClient),
            'UsersBaseUri'
          )

          if (!usersResponse.hasErrors) {
            setUsers(
              sortBy(usersResponse.data, (u) => u.fullName?.toLowerCase())
            )
          } else {
            throw new Error(GetErrorMessage(usersResponse.statusCode))
          }
        } catch (error: any) {
          setError(GetErrorMessage(error.statusCode))
        } finally {
          setIsLoading(false)
        }
      }
    }

    fetchData()
  }, [selectedClient, setIsLoading, platformHttpService])

  const validateUsernameUnique = (username: string) => {
    const matchingUsers =
      users?.filter(
        (user) =>
          user.userName?.toLowerCase().trim() === username.toLowerCase().trim()
      ).length || 0
    return matchingUsers === 0
  }

  return (
    <>
      {error && (
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      )}
      {updateError && (
        <Alert variant="filled" severity="error">
          {updateError}
        </Alert>
      )}
      {users && users.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            height: '100%',
          }}
        >
          <Box
            data-testid="users-list-pane"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              borderRight: `1px solid ${theme.palette.grey[300]}`,
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                height: theme.spacing(4),
              }}
            >
              <MoreMenu
                items={[
                  {
                    label: translations.MoreMenu.AddUser,
                    onClicked: handleClickedAddUser,
                  },
                ]}
              />
            </Box>
            <VirtualizedSelectableList
              rowHeight={parseInt(theme.spacing(9))}
              rowCount={users.length}
              height={'100%'}
              width={'400px'}
              items={users}
              buttonContent={(u) => {
                const user = u as UserBase
                return (
                  <>
                    <Typography variant="body1">{user.fullName}</Typography>
                    <Typography variant="body2">{user.userName}</Typography>
                  </>
                )
              }}
              onSelectedItemIndexChanged={handleSelectedUserIndexChanged}
              selectedIndex={
                selectedUser
                  ? users.findIndex((u) => u.authId === selectedUser.authId)
                  : undefined
              }
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              paddingLeft: theme.spacing(1),
            }}
            data-testid="users-details-pane"
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                height: theme.spacing(4),
              }}
            >
              {userDetails && (
                <MoreMenu
                  items={[
                    {
                      label: translations.MoreMenu.RemoveUser,
                      disabled:
                        userDetails.authId === authenticatedUser?.userId,
                      onClicked: handleClickedRemoveUser,
                    },
                    {
                      label: '-',
                      onClicked: () => {},
                    },
                    {
                      label: translations.MoreMenu.AssignAllPermissions,
                      onClicked: handleClickedAssignAllPermissions,
                    },
                    {
                      label: '-',
                      onClicked: () => {},
                    },
                    {
                      label: translations.MoreMenu.ForceChangePassword,
                      onClicked: handleClickedForceChangePassword,
                    },
                  ]}
                />
              )}
            </Box>
            <AutoSizingBox>
              {userDetails && (
                <UserDetailsContainer
                  key={userDetails.authId}
                  user={userDetails}
                  onUserUpdated={async (user, updatedClientId) => {
                    const updatedClientType = user.clientPermissions.find(
                      (cp) => cp.clientId === updatedClientId
                    )?.clientType

                    if (
                      updatedClientId &&
                      (updatedClientType === ClientTypes.Company ||
                        updatedClientType === ClientTypes.PharmacyGroup)
                    ) {
                      setUserDetailsToPropagate({
                        user,
                        propagationRootClientId: updatedClientId,
                      })
                    } else {
                      await handleUserUpdated(user)
                    }
                  }}
                  odsToFeatureFlagsMap={odsToFeatureFlagsMap}
                />
              )}
            </AutoSizingBox>
          </Box>
        </Box>
      )}
      {isAddingUser && (
        <ModalDialog
          onClosed={() => setIsAddingUser(false)}
          title={translations.MoreMenu.AddTitle}
        >
          <UserDetails
            authId={NewUserAuthId}
            email={''}
            fullName={''}
            autoEdit={true}
            onUpdatedDetails={handleAddUserDetails}
            onCancel={() => setIsAddingUser(false)}
            isUserUnique={validateUsernameUnique}
          />
        </ModalDialog>
      )}
      {isRemovingUser && (
        <ConfirmDialog
          isCancelPrimary={true}
          cancelText={translations.RemoveUserDialog.Cancel}
          title={translations.RemoveUserDialog.Title}
          okText={translations.RemoveUserDialog.Yes}
          text={translations.RemoveUserDialog.Text(
            selectedUser!.fullName,
            selectedUser!.userName,
            selectedClient!.name
          )}
          onCancel={() => setIsRemovingUser(false)}
          onOk={handleRemoveUser}
        />
      )}
      {isAssigningAllPermissions && (
        <ConfirmDialog
          isCancelPrimary={true}
          cancelText={translations.AssignAllPermissionsDialog.Cancel}
          title={translations.AssignAllPermissionsDialog.Title}
          okText={translations.AssignAllPermissionsDialog.Yes}
          text={translations.AssignAllPermissionsDialog.Text(
            selectedUser!.fullName,
            selectedUser!.userName
          )}
          onCancel={() => setIsAssigningAllPermissions(false)}
          onOk={handleAssignAllPermissions}
        />
      )}
      {userDetailsToPropagate && (
        <ModalDialog
          title={translations.AssignGivenPermissionsDialog.Title}
          onClosed={() => setUserDetailsToPropagate(null)}
        >
          <>
            <DialogContentText component={'section'}>
              <Markdown
                value={translations.AssignGivenPermissionsDialog.Text}
              />
            </DialogContentText>
            <Box
              sx={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'row',
                marginTop: theme.spacing(1),
                justifyContent: 'space-between',
                maxHeight: theme.spacing(4),
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'flex-start',
                  gap: theme.spacing(1),
                  flexGrow: 1,
                }}
              >
                <Button
                  data-testid="confirm-dialog-ok-button"
                  variant="contained"
                  color={'warning'}
                  sx={{ color: theme.palette.common.white }}
                  onClick={async () => {
                    if (await handleUserUpdated(userDetailsToPropagate.user)) {
                      setUserDetailsToPropagate(null)
                    }
                  }}
                >
                  {translations.AssignGivenPermissionsDialog.Yes}
                </Button>
                <Button
                  data-testid="propagate-button"
                  variant="contained"
                  color={'error'}
                  sx={{ color: theme.palette.common.white }}
                  onClick={async () => {
                    await handlePropagatePermissions(
                      userDetailsToPropagate.user,
                      userDetailsToPropagate.propagationRootClientId
                    )
                  }}
                >
                  {translations.AssignGivenPermissionsDialog.Propagate}
                </Button>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  gap: theme.spacing(1),
                  flexGrow: 1,
                }}
              >
                <Button
                  data-testid="confirm-dialog-cancel-button"
                  autoFocus
                  variant="contained"
                  color={'primary'}
                  onClick={() => {
                    setUserDetailsToPropagate(null)
                  }}
                >
                  {translations.AssignGivenPermissionsDialog.Cancel}
                </Button>
              </Box>
            </Box>
          </>
        </ModalDialog>
      )}
    </>
  )
}

export default UserManagement
