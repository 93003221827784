import { FC, useContext, useEffect, useState } from 'react'
import ModalDialog from '../../../components/Interactions/ModalDialog'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import FileUpload from '../../../components/Interactions/FileUpload'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import { locale } from '../../../locales'
import { Accept, FileRejection } from 'react-dropzone'
import { SupplierUniquePerId } from '../../../types/entities/Supplier'
import theme from '../../../styles/theme'
import { useSuppliersWithFinancials } from '../../../hooks/useSuppliers'
import { MimeTypes, WebSocketActions } from '../../../constants'
import { ServiceContext } from '../../../providers/ServicesProvider'
import { PlatformApiPaths } from '../../../PlatformApiPaths'
import {
  MonthEndReconciliation,
  MonthEndReconciliationUploadResponse,
  NewMonthEndReconciliationResponse,
  PdfPreprocessorResult,
} from '../entities/mer-entities'
import { ClientSelection } from '../../../types/entities/ClientPermission'
import { useRecoilState } from 'recoil'
import { webSocketLastMessageState } from '../../../state/WebSocketState'

const translation = locale.translation.MerPage.MonthEndReconciliationUploadPopup
const acceptedFileTypes: Accept = {
  'application/pdf': ['.pdf'],
}

const MonthEndReconciliationUploadPopup: FC<{
  selectedClient: ClientSelection | null
  onClose: () => void
  onProcessedSuccess: (merId: string, odsCode: string) => void
}> = ({ selectedClient, onClose, onProcessedSuccess }) => {
  const { platformHttpService, baseHttpService } = useContext(ServiceContext)
  const { merSuppliers } = useSuppliersWithFinancials()
  const [fileError, setFileError] = useState<string | null>(null)
  const [acceptedFile, setAcceptedFile] = useState<File | null>(null)
  const [selectedSupplier, setSelectedSupplier] =
    useState<SupplierUniquePerId | null>(null)
  const [isProcessing, setIsProcessing] = useState(false)
  const [webSocketLastMessage, setWebSocketLastMessage] = useRecoilState(
    webSocketLastMessageState
  )
  const [currentMerId, setCurrentMerId] = useState<string | null>(null)

  const handleAcceptedFiles = (files: File[]) => {
    if (files.length > 1) {
      setFileErrorAndClearAcceptedFile(
        translation.ErrorCannotAcceptMultipleFiles
      )
    } else if (files.length === 1) {
      const file = files[0]
      if (acceptedFileTypes[file.type]) {
        setFileError(null)
        setAcceptedFile(file)
      } else {
        const er = translation.ErrorNotAValidFile(file.name, 'PDF')
        setFileErrorAndClearAcceptedFile(er)
      }
    }
  }

  const handleRejectedFiles = (files: FileRejection[]) => {
    if (files.length > 1) {
      setFileErrorAndClearAcceptedFile(
        translation.ErrorCannotAcceptMultipleFiles
      )
    } else if (files.length === 1) {
      setFileErrorAndClearAcceptedFile(
        translation.ErrorNotAValidFile(files[0].file.name, 'PDF')
      )
    }
  }

  const setFileErrorAndClearAcceptedFile = (error: string) => {
    setFileError(error)
    setAcceptedFile(null)
  }

  const handleSelectSupplier = (e: SelectChangeEvent<string>) => {
    const supplier = merSuppliers.find((s) => s.supplierId === e.target.value)
    if (supplier) {
      setSelectedSupplier(supplier)
    }
  }

  const handleUpload = async () => {
    setFileError(null)
    try {
      if (selectedClient && selectedSupplier && acceptedFile) {
        setIsProcessing(true)

        // Create MER
        const newMerResponse =
          await platformHttpService.postAsync<NewMonthEndReconciliationResponse>(
            PlatformApiPaths.NewMonthEndReconciliation(selectedClient.clientId),
            { supplierId: selectedSupplier.supplierId },
            'MerBaseUri'
          )
        if (
          newMerResponse.hasErrors ||
          !newMerResponse.data?.monthEndReconciliationId
        ) {
          throw new Error('CREATE_NEW_MER_FAILED')
        }

        // Request upload url
        const requestUploadResponse =
          await platformHttpService.postAsync<MonthEndReconciliationUploadResponse>(
            PlatformApiPaths.RequestUploadMerStatement(selectedClient.clientId),
            {
              monthEndReconciliationId:
                newMerResponse.data.monthEndReconciliationId,
              mimeType: MimeTypes.PDF,
            },
            'MerBaseUri'
          )
        if (
          requestUploadResponse.hasErrors ||
          !requestUploadResponse.data?.uploadId
        ) {
          throw new Error('REQUEST_MER_UPLOAD_FAILED')
        }
        // Upload file to S3
        const s3Response = await baseHttpService.performRequestAsync(
          requestUploadResponse.data?.methodToUse as 'PUT' | 'POST',
          requestUploadResponse.data?.preSignedUrl as string,
          new Uint8Array(await acceptedFile.arrayBuffer()),
          { 'Content-Type': MimeTypes.PDF },
          undefined,
          false
        )
        if (s3Response.hasErrors) {
          throw new Error('MER_S3_UPLOAD_FAILED')
        }

        // PreProcess PDF
        const pdfPreprocessResponse =
          await platformHttpService.postAsync<PdfPreprocessorResult>(
            PlatformApiPaths.PreprocessMerPdf(selectedClient.clientId),
            {
              monthEndReconciliationId:
                newMerResponse.data.monthEndReconciliationId,
              uploadId: requestUploadResponse.data.uploadId,
            },
            'MerBaseUri'
          )
        if (pdfPreprocessResponse.hasErrors) {
          throw new Error('MER_PDF_PREPROCESS_FAILED')
        }

        // OCR
        setCurrentMerId(newMerResponse.data.monthEndReconciliationId)
        const ocrResponse =
          await platformHttpService.postAsync<MonthEndReconciliation>(
            PlatformApiPaths.OcrProcessMerStatement(selectedClient.clientId),
            {
              monthEndReconciliationId:
                newMerResponse.data.monthEndReconciliationId,
              monthEndReconciliationMimeType: MimeTypes.PDF,
              monthEndReconciliationuploadId:
                requestUploadResponse.data.uploadId,
              pdfPreprocessorData: pdfPreprocessResponse.data,
              useAsyncOcr: true,
            },
            'MerBaseUri'
          )
        if (ocrResponse.hasErrors) {
          throw new Error('MER_OCR_FAILED')
        }
        // Expecting a websocket result to continue
      }
    } catch (error) {
      setIsProcessing(false)
      setFileError(translation.ErrorCreatingMer(error))
    }
  }

  useEffect(() => {
    if (
      currentMerId &&
      selectedClient?.clientId &&
      isProcessing &&
      webSocketLastMessage
    ) {
      if (
        webSocketLastMessage.action === WebSocketActions.MerOcrCompleted &&
        webSocketLastMessage.monthEndReconciliationId === currentMerId &&
        webSocketLastMessage.odsCode === selectedClient.clientId
      ) {
        if (webSocketLastMessage.isSuccess) {
          onProcessedSuccess(
            webSocketLastMessage.monthEndReconciliationId,
            webSocketLastMessage.odsCode
          )
        } else {
          setFileError(translation.ErrorCreatingMer('MER_OCR_FAILED'))
          setIsProcessing(false)
        }
        setWebSocketLastMessage(null)
      }
    }
  }, [
    currentMerId,
    selectedClient,
    isProcessing,
    webSocketLastMessage,
    onProcessedSuccess,
    setWebSocketLastMessage,
  ])

  return (
    <ModalDialog
      closeDisabled={isProcessing}
      onClosed={() => {
        onClose()
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(2),
        }}
      >
        {fileError && (
          <Alert variant="filled" severity="error">
            {fileError}
          </Alert>
        )}
        {acceptedFile && (
          <Alert variant="filled" severity="success">
            {translation.SelectedFile(acceptedFile.name)}
          </Alert>
        )}
        <FileUpload
          dropzoneText={translation.DropZoneTitle}
          dragRejectText={translation.DragRejectText}
          accept={acceptedFileTypes}
          onAcceptedFiles={handleAcceptedFiles}
          onRejectedFiles={handleRejectedFiles}
          isUploading={isProcessing}
          disabled={isProcessing}
        />
        <FormControl>
          <InputLabel id="select-supplier-label">
            {translation.SelectASupplier}
          </InputLabel>
          <Select
            value={selectedSupplier?.supplierId || ''}
            onChange={(e) => {
              handleSelectSupplier(e)
            }}
            data-testid="suppplier-select"
            labelId="select-supplier-label"
            label={translation.SelectASupplier}
            disabled={!acceptedFile || isProcessing}
          >
            {merSuppliers.map((s) => (
              <MenuItem key={s.supplierId} value={s.supplierId}>
                {s.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => handleUpload()}
          disabled={!acceptedFile || !selectedSupplier || isProcessing}
          data-testid="show-mer-upload-button"
        >
          {translation.UploadAndProcess(
            selectedSupplier?.displayName,
            acceptedFile?.name
          )}
        </Button>
      </Box>
    </ModalDialog>
  )
}
export default MonthEndReconciliationUploadPopup
