import { useContext, useEffect } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { ServiceContext } from '../providers/ServicesProvider'
import {
  suppliersWithVatAndMerState as suppliersWithFinancials,
  suppliersForUploadState,
  suppliersUniquePerIdState,
  merSuppliersState,
  priceFilesSuppliersState,
  suppliersIdDisplayNameDictionaryState,
} from '../state/SuppliersState'
import {
  SupplierWithFinancials,
  SupplierForUpload,
  SupplierUniquePerId,
} from '../types/entities/Supplier'
import { PlatformApiPaths } from '../PlatformApiPaths'
import { groupBy, map } from 'lodash'
import { useGlobalIsLoading } from './useIsLoading'

export const useSuppliersUniquePerId = () => {
  const serviceContext = useContext(ServiceContext)
  const httpService = serviceContext.platformHttpService
  const [suppliers, setSuppliers] = useRecoilState(suppliersUniquePerIdState)
  const [
    suppliersIdDisplayNameDictionary,
    setSuppliersIdDisplayNameDictionary,
  ] = useRecoilState(suppliersIdDisplayNameDictionaryState)
  const { setIsLoading } = useGlobalIsLoading()

  useEffect(() => {
    const getSuppliers = async () => {
      return await httpService.getAsync<SupplierUniquePerId[]>(
        PlatformApiPaths.GetSuppliersPerId,
        'SuppliersBaseUrl'
      )
    }
    if (suppliers == null) {
      setIsLoading(true)
      getSuppliers().then((response) => {
        setIsLoading(false)
        if (response?.data) {
          setSuppliers(response.data)
          setSuppliersIdDisplayNameDictionary(
            response.data.reduce((acc, supplier) => {
              acc[supplier.supplierId] = supplier.displayName
              return acc
            }, {} as Record<string, string>)
          )
        }
      })
    }
  }, [
    httpService,
    setSuppliers,
    suppliers,
    setSuppliersIdDisplayNameDictionary,
    setIsLoading,
  ])

  return {
    suppliers: suppliers ?? [],
    suppliersIdDisplayNameDictionary: suppliersIdDisplayNameDictionary ?? {},
  }
}

export const useSuppliersForUpload = () => {
  const serviceContext = useContext(ServiceContext)
  const httpService = serviceContext.platformHttpService
  const suppliers = useRecoilValue(suppliersForUploadState)
  const setSuppliers = useSetRecoilState(suppliersForUploadState)
  const { setIsLoading } = useGlobalIsLoading()

  useEffect(() => {
    const getSuppliers = async () => {
      return await httpService.getAsync<SupplierForUpload[]>(
        PlatformApiPaths.GetSuppliersForUpload,
        'SuppliersBaseUrl'
      )
    }
    if (suppliers == null) {
      setIsLoading(true)
      getSuppliers().then((response) => {
        setIsLoading(false)
        if (response?.data) {
          setSuppliers(response.data)
        }
      })
    }
  }, [httpService, setIsLoading, setSuppliers, suppliers])

  return suppliers ?? []
}

export const useSuppliersWithFinancials = () => {
  const serviceContext = useContext(ServiceContext)
  const httpService = serviceContext.platformHttpService
  const suppliersUniquePerId = useRecoilValue(suppliersUniquePerIdState)
  const [suppliersWithVat, setSuppliersWithVat] = useRecoilState(
    suppliersWithFinancials
  )
  const [merSuppliers, setMerSuppliers] = useRecoilState(merSuppliersState)
  const [priceFilesSuppliers, setPriceFilesSuppliers] = useRecoilState(
    priceFilesSuppliersState
  )
  const { setIsLoading } = useGlobalIsLoading()

  useEffect(() => {
    const getSuppliers = async () => {
      return await httpService.getAsync<SupplierWithFinancials[]>(
        PlatformApiPaths.GetSuppliersWithFinancials,
        'SuppliersBaseUrl'
      )
    }
    if (
      suppliersUniquePerId != null &&
      (suppliersWithVat == null ||
        merSuppliers == null ||
        priceFilesSuppliers == null)
    ) {
      setIsLoading(true)
      getSuppliers().then((response) => {
        setIsLoading(false)
        if (response?.data) {
          setSuppliersWithVat(response.data)
          setMerSuppliers(
            map(
              groupBy(
                response.data.filter((s) => s.supportsMer),
                (g) => g.supplierId
              ),
              (g) => g[0]
            )
          )
          setPriceFilesSuppliers(
            map(
              groupBy(
                response.data.filter((s) => s.supportsPriceFileUploads),
                (g) => g.supplierId
              ),
              (g) => g[0]
            )
          )
        }
      })
    }
  }, [
    httpService,
    setMerSuppliers,
    setSuppliersWithVat,
    setPriceFilesSuppliers,
    suppliersWithVat,
    priceFilesSuppliers,
    merSuppliers,
    setIsLoading,
    suppliersUniquePerId,
  ])

  return {
    merSuppliers: merSuppliers ?? [],
    suppliersWithVat: suppliersWithVat ?? [],
    priceFilesSuppliers: priceFilesSuppliers ?? [],
  }
}
