import { FC, useEffect, useReducer, useRef, useState } from 'react'
import { ClientPharmacy } from '../entities/Entities'
import Box from '@mui/material/Box'
import VirtualizedSelectableList from '../../../components/Data/VirtualizedSelectableList'
import theme from '../../../styles/theme'
import Typography from '@mui/material/Typography'
import { locale } from '../../../locales'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import EditableSection from './EditableSection'
import EditGroupNameSection from './EditGroupNameSection'
import { InputSectionWithTagsHandle } from '../../../components/Forms/InputSectionWithTags'
import MultiEmailInput from '../../../components/Forms/MultiEmailInput'

const translations =
  locale.translation.SettingsPage.GroupsManagementTab.GroupsManagement
    .GroupDetailsContainer

interface GroupDetailsContainerState {
  clientPharmacies: ClientPharmacy[]
  groupPharmacies: ClientPharmacy[]
  groupName: string
  complianceEmailRecipients: string[]
}

interface InitialiseListsAction {
  type: 'initialiseLists'
  payload: {
    clientPharmacies: ClientPharmacy[]
    groupPharmacies: ClientPharmacy[]
    complianceEmailRecipients: string[]
  }
}

interface MovePharmacyToGroupAction {
  type: 'movePharmacyToGroup'
  payload: ClientPharmacy
}

interface MovePharmacyToClientAction {
  type: 'movePharmacyToClient'
  payload: {
    pharmacyToMove: ClientPharmacy
    initialClientPharmacies: ClientPharmacy[]
  }
}

interface UpdateGroupNameAction {
  type: 'updateGroupName'
  payload: string
}

interface UpdateEmailRecipientsAction {
  type: 'updateEmailRecipients'
  payload: string[]
}

const reducer = (
  state: GroupDetailsContainerState,
  action:
    | InitialiseListsAction
    | MovePharmacyToGroupAction
    | MovePharmacyToClientAction
    | UpdateGroupNameAction
    | UpdateEmailRecipientsAction
) => {
  const clientPharmaciesWithoutGroupPharmacies = (
    clientPharmacies: ClientPharmacy[],
    groupPharmacies: ClientPharmacy[]
  ) => {
    return clientPharmacies.filter(
      (p) => groupPharmacies.findIndex((gp) => gp.odsCode === p.odsCode) === -1
    )
  }

  let updatedGroupPharmacies: ClientPharmacy[]
  switch (action.type) {
    case 'initialiseLists':
      return {
        ...state,
        clientPharmacies: clientPharmaciesWithoutGroupPharmacies(
          action.payload.clientPharmacies,
          action.payload.groupPharmacies
        ),
        groupPharmacies: action.payload.groupPharmacies,
        complianceEmailRecipients: action.payload.complianceEmailRecipients,
      }
    case 'movePharmacyToGroup':
      updatedGroupPharmacies = [...state.groupPharmacies, action.payload]
      return {
        ...state,
        clientPharmacies: clientPharmaciesWithoutGroupPharmacies(
          state.clientPharmacies,
          updatedGroupPharmacies
        ),
        groupPharmacies: updatedGroupPharmacies,
      }
    case 'movePharmacyToClient':
      updatedGroupPharmacies = [
        ...state.groupPharmacies.filter(
          (g) => g.odsCode !== action.payload.pharmacyToMove.odsCode
        ),
      ]
      return {
        ...state,
        clientPharmacies: clientPharmaciesWithoutGroupPharmacies(
          action.payload.initialClientPharmacies,
          updatedGroupPharmacies
        ),
        groupPharmacies: updatedGroupPharmacies,
      }
    case 'updateGroupName':
      return { ...state, groupName: action.payload }

    case 'updateEmailRecipients':
      return { ...state, complianceEmailRecipients: action.payload }
    default:
      return state
  }
}

const GroupDetailsContainer: FC<{
  groupName: string
  groupId: string
  clientPharmacies: ClientPharmacy[]
  groupPharmacies: ClientPharmacy[]
  complianceEmailRecipients: string[]
  isGroupNameUnique: (groupName: string) => boolean
  onGroupUpdated: (
    groupId: string,
    groupName: string,
    groupPharmacies: ClientPharmacy[],
    requiresClientsListUpdate: boolean,
    complianceEmailRecipients: string[]
  ) => void
}> = (props) => {
  const [state, dispatch] = useReducer(reducer, {
    clientPharmacies: [],
    groupPharmacies: [],
    groupName: props.groupName,
    complianceEmailRecipients: props.complianceEmailRecipients,
  } as GroupDetailsContainerState)

  const [groupName, setGroupName] = useState<string>('')

  const [isEditingGroupName, setIsEditingGroupName] = useState(false)
  const [isEditingComplianceEmails, setIsEditingComplianceEmails] =
    useState(false)
  const [isEditingGroups, setIsEditingGroups] = useState(false)
  const [hasInvalidEmails, setHasInvalidEmails] = useState<boolean>(false)
  const emailsInputSectionWithTagsHandle =
    useRef<InputSectionWithTagsHandle>(null)

  const handleClientPharmaciesClick = (item: object) => {
    dispatch({ type: 'movePharmacyToGroup', payload: item as ClientPharmacy })
  }

  const handleErrorsChanged = (hasError: boolean) => {
    setHasInvalidEmails(hasError)
  }

  const handleGroupPharmaciesClick = (item: object) => {
    dispatch({
      type: 'movePharmacyToClient',
      payload: {
        pharmacyToMove: item as ClientPharmacy,
        initialClientPharmacies: props.clientPharmacies,
      },
    })
  }

  const handleGroupNameUpdated = (updatedGroupName: string) => {
    dispatch({ type: 'updateGroupName', payload: updatedGroupName })
    props.onGroupUpdated(
      props.groupId,
      updatedGroupName,
      state.groupPharmacies,
      true,
      props.complianceEmailRecipients
    )
  }

  const handleGroupsUpdated = (newTags: string[]) => {
    props.onGroupUpdated(
      props.groupId,
      groupName,
      state.groupPharmacies,
      true,
      newTags
    )
  }

  useEffect(() => {
    dispatch({
      type: 'initialiseLists',
      payload: {
        clientPharmacies: props.clientPharmacies,
        groupPharmacies: props.groupPharmacies,
        complianceEmailRecipients: props.complianceEmailRecipients,
      },
    })
  }, [
    props.clientPharmacies,
    props.complianceEmailRecipients,
    props.groupPharmacies,
  ])

  useEffect(() => {
    setGroupName(props.groupName)
  }, [props.groupName])

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        gap: theme.spacing(2),
        padding: theme.spacing(1),
      }}
    >
      <EditGroupNameSection
        groupName={groupName}
        onGroupNameUpdated={handleGroupNameUpdated}
        isEditing={isEditingGroupName}
        onEditingChanged={setIsEditingGroupName}
        isGroupNameUnique={props.isGroupNameUnique}
      />
      <EditableSection
        sectionTitle={translations.DailyComplianceReportSectionTitel}
        onUpdate={() => {
          const complianceEmailRecipients =
            emailsInputSectionWithTagsHandle.current?.getTags() ?? []
          handleGroupsUpdated(complianceEmailRecipients)
          dispatch({
            type: 'updateEmailRecipients',
            payload: complianceEmailRecipients,
          })
        }}
        updateDisabled={!isEditingComplianceEmails || hasInvalidEmails}
        onCancel={() => {
          emailsInputSectionWithTagsHandle.current?.clearValue()
          setIsEditingComplianceEmails(false)
          dispatch({
            type: 'initialiseLists',
            payload: {
              clientPharmacies: props.clientPharmacies,
              groupPharmacies: props.groupPharmacies,
              complianceEmailRecipients: props.complianceEmailRecipients,
            },
          })
        }}
        isEditing={isEditingComplianceEmails}
        onEditingChanged={setIsEditingComplianceEmails}
        titleSx={{ fontWeight: 600 }}
      >
        <MultiEmailInput
          ref={emailsInputSectionWithTagsHandle}
          emails={state.complianceEmailRecipients}
          isDisabled={!isEditingComplianceEmails}
          onErrorsChanged={handleErrorsChanged}
          label={translations.RecipientsEmailsLabel}
        />
      </EditableSection>
      <EditableSection
        sectionTitle={translations.GroupPharmacies}
        updateDisabled={false}
        onUpdate={() => {
          handleGroupsUpdated(state.complianceEmailRecipients)
        }}
        onCancel={() => {
          dispatch({
            type: 'initialiseLists',
            payload: {
              clientPharmacies: props.clientPharmacies,
              groupPharmacies: props.groupPharmacies,
              complianceEmailRecipients: props.complianceEmailRecipients,
            },
          })
        }}
        onEditingChanged={setIsEditingGroups}
        isEditing={isEditingGroups}
        sx={{ flexGrow: 1 }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
            gap: theme.spacing(2),
            opacity: isEditingGroups ? 1 : 0.5,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(1),
            }}
          >
            <Typography variant="button" sx={{ fontWeight: 600 }}>
              {translations.AvailablePharmacies(state.groupName)}
            </Typography>
            <VirtualizedSelectableList
              disabled={!isEditingGroups}
              rowHeight={parseInt(theme.spacing(9))}
              rowCount={state.clientPharmacies.length}
              height={'100%'}
              width={'400px'}
              items={state.clientPharmacies}
              itemButtonSx={{ paddingLeft: 0 }}
              buttonContent={(p) => {
                const pharmacy = p as ClientPharmacy
                return (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <Box sx={{ flexGrow: 1, textAlign: 'left' }}>
                      <Typography variant="body1">
                        {pharmacy.pharmacyName}
                      </Typography>
                      <Typography variant="body2">
                        {pharmacy.odsCode}
                      </Typography>
                    </Box>
                    <Box sx={{ alignSelf: 'center' }}>
                      <ArrowForwardIosIcon />
                    </Box>
                  </Box>
                )
              }}
              disableSelectionHighlight={true}
              onClickedItem={handleClientPharmaciesClick}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(1),
            }}
          >
            <Typography variant="button" sx={{ fontWeight: 600 }}>
              {translations.PharmaciesInGroup(state.groupName)}
            </Typography>
            <VirtualizedSelectableList
              disabled={!isEditingGroups}
              rowHeight={parseInt(theme.spacing(2))}
              rowCount={state.groupPharmacies.length}
              height={'100%'}
              width={'400px'}
              items={state.groupPharmacies}
              itemButtonSx={{ paddingLeft: 0 }}
              buttonContent={(p) => {
                const pharmacy = p as ClientPharmacy
                return (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <Box sx={{ alignSelf: 'center' }}>
                      <ArrowBackIosNewIcon />
                    </Box>
                    <Box
                      sx={{
                        flexGrow: 1,
                        textAlign: 'left',
                        paddingX: theme.spacing(2),
                      }}
                    >
                      <Typography variant="body1">
                        {pharmacy.pharmacyName}
                      </Typography>
                      <Typography variant="body2">
                        {pharmacy.odsCode}
                      </Typography>
                    </Box>
                  </Box>
                )
              }}
              disableSelectionHighlight={true}
              onClickedItem={handleGroupPharmaciesClick}
            />
          </Box>
        </Box>
      </EditableSection>
    </Box>
  )
}

export default GroupDetailsContainer
