import { configurationSettings } from '../configuration/configurationSettings'
import { executeInOtlpSpan, setSpanAttributes } from '../otlp/otlp-provider'
import { IAuthenticationService } from '../types/authentication/IAuthenticationService'
import { HttpResponse } from '../types/http/HttpResponse'
import IPlatformHttpService from '../types/http/IPlatformHttpService'
import { HttpServiceBase } from './HttpServiceBase'
import { Span } from '@opentelemetry/api'

export class PlatformApiHttpService
  extends HttpServiceBase
  implements IPlatformHttpService
{
  private baseUri = configurationSettings.PlatformApi.BaseUri
  private webSocketConnectionId: string | null = null

  constructor(private authenticationService: IAuthenticationService) {
    super()
  }

  setWebSocketConnectionId(webSocketConnectionId: string | null): void {
    this.webSocketConnectionId = webSocketConnectionId
  }

  private async getCurrentUserToken(): Promise<{
    idToken: string
    userId: string
  }> {
    const currentUser = await this.authenticationService.getCurrentUserAsync()
    if (!currentUser.user?.idToken) {
      throw new Error('Attemted to make a request with an unauthenticated user')
    }
    return {
      idToken: currentUser.user.idToken,
      userId: currentUser.user.userId,
    }
  }

  async getAsync<T>(
    path: string,
    platformApiConfigUriKey?: string,
    abortSignal?: AbortSignal,
    parentSpan?: Span
  ): Promise<HttpResponse<T>> {
    return await this.performPlatformRequestAsync(
      'GET',
      path,
      null,
      platformApiConfigUriKey,
      abortSignal,
      parentSpan
    )
  }

  async postAsync<T>(
    path: string,
    body: any,
    platformApiConfigUriKey?: string,
    abortSignal?: AbortSignal,
    parentSpan?: Span,
    mapJsonToT?: boolean
  ): Promise<HttpResponse<T>> {
    return await this.performPlatformRequestAsync(
      'POST',
      path,
      body ? JSON.stringify(body) : null,
      platformApiConfigUriKey,
      abortSignal,
      parentSpan,
      mapJsonToT
    )
  }

  async putAsync<T>(
    path: string,
    body: any,
    platformApiConfigUriKey?: string,
    parentSpan?: Span
  ): Promise<HttpResponse<T>> {
    return await this.performPlatformRequestAsync(
      'PUT',
      path,
      body ? JSON.stringify(body) : null,
      platformApiConfigUriKey,
      undefined,
      parentSpan
    )
  }

  async deleteAsync(
    path: string,
    body: any,
    platformApiConfigUriKey?: string,
    parentSpan?: Span
  ): Promise<HttpResponse<void>> {
    return await this.performPlatformRequestAsync(
      'DELETE',
      path,
      body ? JSON.stringify(body) : null,
      platformApiConfigUriKey,
      undefined,
      parentSpan
    )
  }

  private async performPlatformRequestAsync<T>(
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    path: string,
    body?: any | undefined,
    platformApiConfigUriKey?: string,
    abortSignal?: AbortSignal,
    parentSpan?: Span,
    mapJsonToT?: boolean
  ): Promise<HttpResponse<T>> {
    let baseUrl = this.baseUri
    if (platformApiConfigUriKey) {
      baseUrl =
        configurationSettings.PlatformApi[platformApiConfigUriKey] ??
        this.baseUri
    }

    const pathSplit = path.split('?')
    const pathRoute = pathSplit[0]
    let pathQuery: string | undefined = undefined
    if (pathSplit.length > 1) {
      pathQuery = pathSplit[1]
    }

    return (await executeInOtlpSpan(
      pathRoute,
      async (span, traceId, spadId, setStatus) => {
        const { idToken, userId } = await this.getCurrentUserToken()
        var response = await super.performRequestAsync(
          method,
          `${baseUrl}/${path}`,
          body,
          {
            'Content-Type': 'application/json',
            Authorization: idToken,
            'X-Srx-WebSocket-Id': this.webSocketConnectionId ?? '',
            Traceparent: `00-${traceId}-${spadId}-01;now=${Date.now()};`,
          },
          abortSignal,
          mapJsonToT
        )
        setSpanAttributes(span, {
          'http.query': pathQuery,
          'http.method': method,
          'http.status_code': response.statusCode?.toString() ?? '',
          'stockrx.user_id': userId,
        })
        setStatus(response.hasErrors, response.errors.join(', '))
        return response
      },
      parentSpan
    )) as HttpResponse<T>
  }
}
