import { Accept } from 'react-dropzone'
import { locale } from '../../../locales'
import { SupplierForUpload } from '../../../types/entities/Supplier'
import { IServiceContext } from '../../../providers/ServicesProvider'
import { ClientSelection } from '../../../types/entities/ClientPermission'
import { FC, useCallback, useEffect, useReducer, useRef } from 'react'
import ModalDialog from '../../../components/Interactions/ModalDialog'
import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography/Typography'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'
import ErrorIcon from '@mui/icons-material/Error'
import Alert from '@mui/material/Alert/Alert'
import Markdown from 'marked-react'
import FileUpload from '../../../components/Interactions/FileUpload'
import FormControl from '@mui/material/FormControl/FormControl'
import InputLabel from '@mui/material/InputLabel/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select/Select'
import MenuItem from '@mui/material/MenuItem/MenuItem'
import Button from '@mui/material/Button/Button'
import theme from '../../../styles/theme'
import { useSuppliersForUpload } from '../../../hooks/useSuppliers'
import { PlatformApiPaths } from '../../../PlatformApiPaths'
import {
  CreateBookInResponse,
  ProcessInvoicesResponse,
  RequestInvoiceUploadResponse,
} from '../entities/BookIn'
import {
  executeInOtlpSpan,
  getChildSpan,
  setSpanAttributes,
  setSpanStatus,
} from '../../../otlp/otlp-provider'
import { MimeTypes, WebSocketActions } from '../../../constants'
import { useRecoilState } from 'recoil'
import { webSocketLastMessageState } from '../../../state/WebSocketState'

const translation = locale.translation.GoodsInPage.UploadInvoice

const processingStates = {
  processing: 'PROCESSING',
  success: 'SUCCESS',
  error: 'ERROR',
  warning: 'WARNING',
  idle: 'IDLE',
  accepted: 'ACCEPTED',
}

interface AsyncOcrWebSocketMessage {
  bookInId: string
  odsCode: string
  subAction: string
  archiveFileName: string
  archiveTotalFilesCount: number
  archiveCurrentFileNumber: number
  currentFileName: string
  ocrSuccessData: {
    invoiceDate: string
    invoiceNumber: string
    orderNumber: string | null
    status: string
    supplierId: string
    supplierName: string
    supplierDisplayName: string
    totalPrice: number
    creationDate: string
  }
  action: string
  timeStamp: string
}

const acceptedFileTypes: Accept = {
  'application/pdf': ['.pdf'],
  'application/zip': ['.zip'],
  'application/x-zip-compressed': ['.zip'],
}

interface UploadInvoicesState {
  error: string
  fileAcceptError: boolean
  acceptedFiles:
    | {
        file: File
        fileName: string
        state?: string
        bookInId?: string
      }[]
    | []
  isUploading: boolean
  supplierDetectionError: boolean
  supplier: SupplierForUpload | null
  zipProcessingState: {
    zipFileName: string | null
    processedFiles: number
    totalFiles: number
  }
  currentFile: File | null
  currentFileIndex: number
  finishedProcessingPdf: boolean
}

type SetIsUploadingAction = {
  type: 'SET_IS_UPLOADING'
  payload: boolean
}

type SetSupplierDetectionErrorAction = {
  type: 'SET_SUPPLIER_DETECTION_ERROR'
  payload: boolean
}

type SetSupplierAction = {
  type: 'SET_SUPPLIER'
  payload: SupplierForUpload | null
}

type SetZipProcessingStateAction = {
  type: 'SET_ZIP_PROCESSING_STATE'
  payload: Partial<{
    zipFileName: string | null
    processedFiles: number
    totalFiles: number
  }>
}

type RemoveAcceptedFileAction = {
  type: 'REMOVE_ACCEPTED_FILE'
  payload: number
}
type SetStartAddingFilesAction = {
  type: 'SET_START_ADDING_FILES'
  payload: {
    files: File[]
    suppliers: SupplierForUpload[]
  }
}

type SetUploadErrorAction = {
  type: 'SET_UPLOAD_ERROR'
  payload: {
    error: string
    acceptedFiles?: {
      file: File
      fileName: string
      state?: string
    }[]
  }
}

type AdvanceToNextFileAction = {
  type: 'ADVANCE_TO_NEXT_FILE'
}

type CompleteFileProcessingAction = {
  type: 'COMPLETE_FILE_PROCESSING'
}

type SetSupplierDetectionFailedAction = {
  type: 'SET_SUPPLIER_DETECTION_FAILED_ACTION'
}

type SetProcessingFailedStateAction = {
  type: 'SET_PROCESSING_FAILED_STATE'
  payload: {
    bookInId: string
    errorMessage: string
  }
}

type SetGlobalProcessingFailedAction = {
  type: 'SET_GLOBAL_PROCESSING_FAILED'
  payload: string
}

type UpdateProcessingFileAndZipStateAction = {
  type: 'UPDATE_PROCESSING_FILE_AND_ZIP_STATE'
  payload: {
    fileName: string
    fileState: string
  }
}
type SetAcceptedFilesStateAction = {
  type: 'SET_ACCEPTED_FILES_STATE'
  payload: {
    file: File
    fileName: string
    state?: string
    bookInId?: string
  }[]
}

const reducer = (
  state: UploadInvoicesState,
  action:
    | SetIsUploadingAction
    | SetSupplierDetectionErrorAction
    | SetSupplierAction
    | SetZipProcessingStateAction
    | RemoveAcceptedFileAction
    | SetStartAddingFilesAction
    | SetUploadErrorAction
    | AdvanceToNextFileAction
    | CompleteFileProcessingAction
    | SetSupplierDetectionFailedAction
    | SetProcessingFailedStateAction
    | SetGlobalProcessingFailedAction
    | UpdateProcessingFileAndZipStateAction
    | SetAcceptedFilesStateAction
): UploadInvoicesState => {
  let nextState: UploadInvoicesState = state
  switch (action.type) {
    case 'SET_IS_UPLOADING':
      nextState = { ...state, isUploading: action.payload }
      break

    case 'SET_SUPPLIER_DETECTION_ERROR':
      nextState = { ...state, supplierDetectionError: action.payload }
      break

    case 'SET_SUPPLIER':
      nextState = { ...state, supplier: action.payload }
      break

    case 'SET_ZIP_PROCESSING_STATE':
      nextState = {
        ...state,
        zipProcessingState: { ...state.zipProcessingState, ...action.payload },
      }
      break

    case 'SET_ACCEPTED_FILES_STATE':
      nextState = {
        ...state,
        acceptedFiles: action.payload,
      }
      break

    case 'REMOVE_ACCEPTED_FILE':
      const newAcceptedFiles = state.acceptedFiles.filter(
        (_, index) => index !== action.payload
      )
      nextState = { ...state, acceptedFiles: newAcceptedFiles }
      break

    case 'SET_START_ADDING_FILES':
      const { files, suppliers } = action.payload
      if (!suppliers) return state
      if (!files || files.length === 0) {
        return {
          ...state,
          error: translation.ErrorCanOnlyAcceptPdfOrZip,
          fileAcceptError: true,
        }
      }

      const existingFiles = state.acceptedFiles ?? []
      const newFiles = files.map((file) => ({
        file,
        fileName: file.name,
        state: processingStates.accepted,
      }))

      let combinedFiles = [...existingFiles]

      const existingAllPdfs = existingFiles.every(
        (f) => f.file.type === 'application/pdf'
      )
      const existingSingleZip =
        existingFiles.length === 1 && existingFiles[0].file.type.includes('zip')

      const newAllPdfs = files.every((f) => f.type === 'application/pdf')
      const newSingleZip = files.length === 1 && files[0].type.includes('zip')

      const filesAreValid = files.every((f) => acceptedFileTypes[f.type])

      const canAddNewFiles =
        (filesAreValid && existingAllPdfs && newAllPdfs) ||
        (existingFiles.length === 0 && newSingleZip)

      if (canAddNewFiles) combinedFiles = combinedFiles.concat(newFiles)

      nextState = canAddNewFiles
        ? {
            ...state,
            error: '',
            fileAcceptError: false,
            acceptedFiles: combinedFiles,
            currentFile: combinedFiles[0].file,
          }
        : {
            ...state,
            error: translation.ErrorCanOnlyAcceptPdfOrZip,
            fileAcceptError: true,
            acceptedFiles: existingSingleZip ? [] : existingFiles,
          }
      break

    case 'SET_UPLOAD_ERROR':
      const { error, acceptedFiles } = action.payload

      nextState = {
        ...state,
        isUploading: false,
        error: translation.ErrorCreatingBookIn(error),
        acceptedFiles: acceptedFiles ?? state.acceptedFiles,
      }
      break

    case 'ADVANCE_TO_NEXT_FILE':
      const nextFileIndex = state.currentFileIndex + 1
      if (nextFileIndex < state.acceptedFiles.length) {
        const nextFile = state.acceptedFiles[nextFileIndex].file
        nextState = {
          ...state,
          currentFileIndex: nextFileIndex,
          currentFile: nextFile,
        }
      } else {
        nextState = {
          ...state,
          isUploading: false,
          finishedProcessingPdf: true,
        }
      }
      break

    case 'COMPLETE_FILE_PROCESSING':
      nextState = {
        ...state,
        isUploading: false,
        finishedProcessingPdf: true,
      }
      break

    case 'SET_SUPPLIER_DETECTION_FAILED_ACTION':
      nextState = {
        ...state,
        acceptedFiles: state.acceptedFiles!.map((f) =>
          f.state === processingStates.error
            ? { ...f, state: processingStates.error }
            : f
        ),
        isUploading: false,
        supplierDetectionError: true,
      }
      break

    case 'SET_PROCESSING_FAILED_STATE':
      const { bookInId, errorMessage } = action.payload

      nextState = {
        ...state,
        acceptedFiles: state.acceptedFiles!.map((f) =>
          f.bookInId === bookInId ? { ...f, state: processingStates.error } : f
        ),
      }

      if (state.acceptedFiles?.length === 1 || state.finishedProcessingPdf) {
        nextState = {
          ...nextState,
          isUploading: false,
          acceptedFiles: [],
        }
        if (state.acceptedFiles?.length === 1) {
          nextState = {
            ...nextState,
            error: errorMessage,
          }
        }
      }

      break

    case 'SET_GLOBAL_PROCESSING_FAILED':
      const globalErrorMessage = action.payload
      nextState = {
        ...state,
        error: globalErrorMessage,
        isUploading: false,
        zipProcessingState: {
          zipFileName: null,
          totalFiles: 0,
          processedFiles: 0,
        },
        acceptedFiles: [],
      }
      break

    case 'UPDATE_PROCESSING_FILE_AND_ZIP_STATE':
      const { fileName, fileState } = action.payload

      nextState = {
        ...state,
        acceptedFiles: state.acceptedFiles!.map((f) =>
          f.fileName === fileName ? { ...f, state: fileState } : f
        ),
        zipProcessingState: {
          ...state.zipProcessingState,
          processedFiles: state.zipProcessingState.processedFiles + 1,
        },
      }
      break
  }
  return { ...nextState }
}

const MultiInvoiceUpload: FC<{
  serviceContext: IServiceContext
  onInvoiceProcessedCallback?: (
    processInvoicesResponse: ProcessInvoicesResponse | null
  ) => void
  selectedClient: ClientSelection
  onClose: (shouldRefresh?: boolean) => void
}> = ({
  serviceContext,
  onInvoiceProcessedCallback,
  selectedClient,
  onClose,
}) => {
  const initialState: UploadInvoicesState = {
    error: '',
    fileAcceptError: false,
    acceptedFiles: [],
    isUploading: false,
    supplierDetectionError: false,
    supplier: null,
    zipProcessingState: {
      zipFileName: null,
      processedFiles: 0,
      totalFiles: 0,
    },
    currentFile: null,
    currentFileIndex: 0,
    finishedProcessingPdf: false,
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  const platformHttpService = useRef(serviceContext.platformHttpService).current
  const suppliers = useSuppliersForUpload()

  const [webSocketLastMessage, setWebSocketLastMessage] = useRecoilState(
    webSocketLastMessageState
  )

  const finishedProcessingZip =
    state.zipProcessingState.totalFiles > 0 &&
    state.zipProcessingState.processedFiles ===
      state.zipProcessingState.totalFiles

  const showFileUpload =
    !finishedProcessingZip &&
    !state.finishedProcessingPdf &&
    !state.isUploading &&
    !state.supplierDetectionError &&
    (!state.error || state.fileAcceptError)

  const showSupplierSelection =
    suppliers &&
    state.supplierDetectionError &&
    state.acceptedFiles?.length === 1

  const showFiles =
    !state.error &&
    !state.isUploading &&
    state.acceptedFiles.length !== 0 &&
    !state.supplierDetectionError &&
    !state.zipProcessingState.zipFileName &&
    !state.finishedProcessingPdf

  const showRefreshWarningMessage =
    !state.finishedProcessingPdf &&
    !finishedProcessingZip &&
    !state.error &&
    !state.supplierDetectionError &&
    state.isUploading

  const showProcessingFiles =
    state.acceptedFiles.every((f) => f.state !== processingStates.accepted) &&
    !state.error &&
    (!state.supplierDetectionError || state.acceptedFiles.length !== 1)

  const isStartBookInEnabled =
    (state.supplierDetectionError && !state.supplier?.supplierId) ||
    !!state.error ||
    state.acceptedFiles.length === 0 ||
    state.isUploading

  const showUploadInvoicesTitle =
    state.acceptedFiles.some(
      (f) =>
        f.state === processingStates.processing ||
        f.state === processingStates.success ||
        f.state === processingStates.error
    ) || state.error

  const handleAcceptedFiles = (files: File[]) => {
    dispatch({ type: 'SET_START_ADDING_FILES', payload: { files, suppliers } })
  }

  const handleFailedInvoice = useCallback(
    async (
      odsCode: string,
      bookInId: string,
      deleteImagesAsMove: boolean = true
    ) => {
      await platformHttpService.deleteAsync(
        PlatformApiPaths.DeleteBookIn(odsCode, bookInId, deleteImagesAsMove),
        null,
        'StockBaseUrl'
      )
    },
    [platformHttpService]
  )

  const handleUpload = async () => {
    switch (state.acceptedFiles[0].file.type) {
      case 'application/zip':
      case 'application/x-zip-compressed':
        handleZipUpload()
        break
      default:
        dispatch({
          type: 'SET_ACCEPTED_FILES_STATE',
          payload: state.acceptedFiles.map((file) => ({
            file: file.file,
            fileName: file.fileName,
            state: processingStates.idle,
          })),
        })
        await handlePdfUpload(state.acceptedFiles[0].file)
        break
    }
  }

  const handleZipUpload = async () => {
    try {
      dispatch({ type: 'SET_IS_UPLOADING', payload: true })
      if (state.acceptedFiles[0]) {
        dispatch({
          type: 'SET_ACCEPTED_FILES_STATE',
          payload: [
            {
              file: state.acceptedFiles[0].file,
              fileName: state.acceptedFiles[0].file.name,
              state: processingStates.processing,
            },
          ],
        })
        const requestInvoiceUploadResponse =
          await platformHttpService.postAsync<RequestInvoiceUploadResponse>(
            PlatformApiPaths.RequestInvoiceUpload(selectedClient.clientId),
            {
              mimeType: state.acceptedFiles[0].file.type,
              originalFileName: state.acceptedFiles[0].file.name,
            },
            'StockBaseUrl'
          )
        if (requestInvoiceUploadResponse.hasErrors) {
          throw new Error('REQUEST_INVOICE_UPLOAD')
        }

        const s3Response =
          await serviceContext.baseHttpService.performRequestAsync(
            requestInvoiceUploadResponse.data?.methodToUse as 'PUT' | 'POST',
            requestInvoiceUploadResponse.data?.preSignedUrl as string,
            new Uint8Array(await state.acceptedFiles[0].file.arrayBuffer()),
            requestInvoiceUploadResponse.data?.headersToUse,
            undefined,
            false
          )
        if (s3Response.hasErrors) {
          throw new Error('S3_UPLOAD')
        }
        dispatch({
          type: 'SET_ZIP_PROCESSING_STATE',
          payload: {
            zipFileName: state.acceptedFiles[0].file.name,
            totalFiles: 0,
            processedFiles: 0,
          },
        })
      }
    } catch (error) {
      dispatch({
        type: 'SET_UPLOAD_ERROR',
        payload: {
          error: error as string,
          acceptedFiles: [
            {
              file: state.acceptedFiles[0].file,
              fileName: state.acceptedFiles[0]?.file.name ?? '',
              state: processingStates.error,
            },
          ],
        },
      })
    }
  }

  const handlePdfUpload = useCallback(
    async (file: File) => {
      dispatch({ type: 'SET_SUPPLIER_DETECTION_ERROR', payload: false })
      let bookInId: string | undefined = undefined
      executeInOtlpSpan('Create-BookIn', async (rootSpan) => {
        try {
          if (
            (!state.supplierDetectionError ||
              (state.supplierDetectionError && state.supplier?.supplierId)) &&
            state.acceptedFiles
          ) {
            dispatch({ type: 'SET_IS_UPLOADING', payload: true })

            const bookInResponse =
              await platformHttpService.postAsync<CreateBookInResponse>(
                PlatformApiPaths.CreateBookIn(selectedClient.clientId),
                { creationMedium: 'WebApp' },
                'StockBaseUrl',
                undefined,
                rootSpan
              )
            if (bookInResponse.hasErrors || !bookInResponse.data?.bookInId) {
              throw new Error('CREATE_BOOKIN')
            }

            bookInId = bookInResponse.data?.bookInId

            const requestInvoiceUploadResponse =
              await platformHttpService.postAsync<RequestInvoiceUploadResponse>(
                PlatformApiPaths.RequestInvoiceUpload(selectedClient.clientId),
                {
                  bookInId: bookInResponse.data?.bookInId,
                  mimeType: MimeTypes.PDF,
                },
                'StockBaseUrl',
                undefined,
                rootSpan
              )
            if (requestInvoiceUploadResponse.hasErrors) {
              await handleFailedInvoice(selectedClient.clientId, bookInId)
              throw new Error('REQUEST_INVOICE_UPLOAD')
            }

            const s3ChildSpan = getChildSpan('S3-Upload', rootSpan)
            const s3Response =
              await serviceContext.baseHttpService.performRequestAsync(
                requestInvoiceUploadResponse.data?.methodToUse as
                  | 'PUT'
                  | 'POST',
                requestInvoiceUploadResponse.data?.preSignedUrl as string,
                new Uint8Array(await file.arrayBuffer()),
                { 'Content-Type': MimeTypes.PDF },
                undefined,
                false
              )
            if (s3Response.hasErrors) {
              setSpanStatus(s3ChildSpan, true)
              await handleFailedInvoice(selectedClient.clientId, bookInId)
              throw new Error('S3_UPLOAD')
            }
            setSpanStatus(s3ChildSpan, false)
            s3ChildSpan.end()

            const processInvoicesResponse =
              await platformHttpService.postAsync<ProcessInvoicesResponse>(
                PlatformApiPaths.TriggerAsyncProcessInvoices(
                  selectedClient.clientId
                ),
                {
                  bookInId: bookInResponse.data?.bookInId,
                  invoiceMimeType: MimeTypes.PDF,
                  invoiceUploadIds: [
                    requestInvoiceUploadResponse.data?.uploadId,
                  ],
                  supplierId: state.supplier?.supplierId,
                  supplierSortKey: state.supplier?.supplierComposedSortKey,
                },
                'StockBaseUrl',
                undefined,
                rootSpan
              )

            if (processInvoicesResponse.hasErrors) {
              await handleFailedInvoice(selectedClient.clientId, bookInId)
              throw new Error('PROCESS_INVOICE')
            }
          }
        } catch (error) {
          setSpanStatus(rootSpan, true, (error as Error)?.message)
          setSpanAttributes(rootSpan, {
            'stockrx.error.bookInId': bookInId,
          })
          dispatch({
            type: 'SET_UPLOAD_ERROR',
            payload: {
              error: error as string,
            },
          })
          if (onInvoiceProcessedCallback) {
            onInvoiceProcessedCallback(null)
          }
        }
      })
    },
    [
      handleFailedInvoice,
      onInvoiceProcessedCallback,
      platformHttpService,
      selectedClient.clientId,
      serviceContext.baseHttpService,
      state.acceptedFiles,
      state.supplier?.supplierComposedSortKey,
      state.supplier?.supplierId,
      state.supplierDetectionError,
    ]
  )

  const handleNextFile = useCallback(() => {
    const nextFileIndex = state.currentFileIndex + 1

    if (nextFileIndex < state.acceptedFiles.length) {
      dispatch({ type: 'ADVANCE_TO_NEXT_FILE' })
      setTimeout(() => {
        handlePdfUpload(state.acceptedFiles[nextFileIndex].file)
      }, 0)
    } else {
      dispatch({ type: 'COMPLETE_FILE_PROCESSING' })
    }
  }, [handlePdfUpload, state.acceptedFiles, state.currentFileIndex])

  useEffect(() => {
    if (
      webSocketLastMessage &&
      webSocketLastMessage.action === WebSocketActions.AsyncOcrProgress
    ) {
      const message = { ...webSocketLastMessage } as AsyncOcrWebSocketMessage
      setWebSocketLastMessage(null)

      // PDF upload
      if (!state.zipProcessingState.zipFileName) {
        if (message.subAction === 'PROCESSING_STARTED') {
          dispatch({
            type: 'SET_ACCEPTED_FILES_STATE',
            payload: state.acceptedFiles!.map((f, i) =>
              i === state.currentFileIndex
                ? {
                    ...f,
                    bookInId: message.bookInId,
                    state: processingStates.processing,
                  }
                : f
            ),
          })
        } else if (message.subAction === 'SUPPLIER_DETECTION_FAILED') {
          setTimeout(() => {
            handleFailedInvoice(
              selectedClient.clientId,
              message.bookInId,
              false
            )
          }, 0)
          if (state.acceptedFiles?.length === 1) {
            dispatch({
              type: 'SET_SUPPLIER_DETECTION_FAILED_ACTION',
            })
          } else {
            dispatch({
              type: 'SET_ACCEPTED_FILES_STATE',
              payload: state.acceptedFiles!.map((f) =>
                f.bookInId === message.bookInId
                  ? { ...f, state: processingStates.error }
                  : f
              ),
            })
            handleNextFile()
          }
        } else if (message.subAction === 'PROCESSING_FAILED') {
          setTimeout(() => {
            handleFailedInvoice(selectedClient.clientId, message.bookInId)
          }, 0)
          dispatch({
            type: 'SET_PROCESSING_FAILED_STATE',
            payload: {
              bookInId: message.bookInId,
              errorMessage: translation.ErrorCreatingBookIn('PROCESS_INVOICE'),
            },
          })
          if (
            state.acceptedFiles?.length !== 1 &&
            !state.finishedProcessingPdf
          ) {
            handleNextFile()
          }
        } else if (message.subAction === 'PROCESSING_SUCCEEDED') {
          dispatch({
            type: 'SET_ACCEPTED_FILES_STATE',
            payload: state.acceptedFiles!.map((f) =>
              f.bookInId === message.bookInId
                ? { ...f, state: processingStates.success }
                : f
            ),
          })

          handleNextFile()

          if (
            onInvoiceProcessedCallback &&
            message.ocrSuccessData &&
            state.acceptedFiles?.length === 1
          ) {
            onInvoiceProcessedCallback({
              bookInId: message.bookInId,
              creationDate: message.ocrSuccessData.creationDate,
              invoiceDate: message.ocrSuccessData.invoiceDate,
              invoiceNumber: message.ocrSuccessData.invoiceNumber,
              orderNumber: message.ocrSuccessData.orderNumber,
              status: message.ocrSuccessData.status,
              supplierDisplayName: message.ocrSuccessData.supplierName,
              supplierId: message.ocrSuccessData.supplierId,
              supplierName: message.ocrSuccessData.supplierName,
              totalPrice: message.ocrSuccessData.totalPrice,
            })
          }
        }
      }
      // Zip upload
      else {
        if (!state.zipProcessingState.totalFiles) {
          dispatch({
            type: 'SET_ZIP_PROCESSING_STATE',
            payload: {
              ...state.zipProcessingState,
              totalFiles: message.archiveTotalFilesCount,
            },
          })
        }
        if (message.subAction === 'PROCESSING_STARTED') {
          dispatch({
            type: 'SET_ACCEPTED_FILES_STATE',
            payload: [
              ...state.acceptedFiles!,
              {
                file: state.acceptedFiles![0].file,
                fileName: message.currentFileName,
                state: processingStates.processing,
              },
            ],
          })
        } else if (message.subAction === 'PROCESSING_SUCCEEDED') {
          dispatch({
            type: 'UPDATE_PROCESSING_FILE_AND_ZIP_STATE',
            payload: {
              fileName: message.currentFileName,
              fileState: processingStates.success,
            },
          })
        } else if (
          message.subAction === 'PROCESSING_FAILED' ||
          message.subAction === 'SUPPLIER_DETECTION_FAILED'
        ) {
          dispatch({
            type: 'UPDATE_PROCESSING_FILE_AND_ZIP_STATE',
            payload: {
              fileName: message.currentFileName,
              fileState: processingStates.error,
            },
          })
        } else if (message.subAction === 'PROCESSING_FAILED_GLOBAL') {
          dispatch({
            type: 'SET_GLOBAL_PROCESSING_FAILED',
            payload: translation.ErrorCreatingBookIn('PROCESS_FILE'),
          })
        }
      }
    }
    if (finishedProcessingZip && state.isUploading) {
      dispatch({ type: 'SET_IS_UPLOADING', payload: false })

      const allInvoicesProcessingState = state.acceptedFiles!.slice(1)

      dispatch({
        type: 'SET_ACCEPTED_FILES_STATE',
        payload: state.acceptedFiles!.map((f, i) =>
          i === 0
            ? {
                ...f,
                state: allInvoicesProcessingState!.every(
                  (f) => f.state === processingStates.success
                )
                  ? processingStates.success
                  : allInvoicesProcessingState!.every(
                      (f) => f.state === processingStates.error
                    )
                  ? processingStates.error
                  : processingStates.warning,
              }
            : f
        ),
      })
    }
  }, [
    finishedProcessingZip,
    handleFailedInvoice,
    state.isUploading,
    onInvoiceProcessedCallback,
    state.acceptedFiles,
    selectedClient.clientId,
    setWebSocketLastMessage,
    webSocketLastMessage,
    state.zipProcessingState,
    state.currentFileIndex,
    handlePdfUpload,
    state.finishedProcessingPdf,
    handleNextFile,
  ])

  const handleSupplierChange = (event: SelectChangeEvent) => {
    dispatch({
      type: 'SET_SUPPLIER',
      payload: getSupplierFromSupplierSelectValue(event.target.value),
    })
  }

  const getSupplierSelectValue = (s: SupplierForUpload | null) =>
    s?.supplierId ? `${s.supplierId}____${s.supplierComposedSortKey}` : ''

  const getSupplierFromSupplierSelectValue = (s: string) => {
    if (!s) {
      return null
    }
    const split = s.split('____')
    return (
      suppliers?.find(
        (s) =>
          s.supplierId === split[0] && s.supplierComposedSortKey === split[1]
      ) ?? null
    )
  }

  return (
    <ModalDialog
      closeDisabled={state.isUploading}
      onClosed={() => {
        onClose()
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(1),
        }}
      >
        {showUploadInvoicesTitle && (
          <Box sx={{ gap: theme.spacing(0.5) }}>
            <Typography variant="h6">
              {translation.ProcessingInvoices}
            </Typography>
            {showRefreshWarningMessage && (
              <Typography
                variant="subtitle1"
                sx={{ marginBottom: theme.spacing(1) }}
              >
                {translation.RefreshWarning}
              </Typography>
            )}
          </Box>
        )}

        {!showUploadInvoicesTitle && (
          <Typography variant="h6">{translation.UploadInvoices}</Typography>
        )}
        {showFiles && (
          <Box
            sx={{
              maxHeight: '200px',
              overflowY: 'auto',
            }}
          >
            {state.acceptedFiles.map((file, index) => (
              <Box
                sx={{ marginBottom: theme.spacing(1) }}
                key={`${file.file.name}-${index}`}
              >
                <Alert
                  variant="filled"
                  severity="info"
                  sx={{ wordWrap: 'break-word' }}
                  action={
                    <Button
                      onClick={() =>
                        dispatch({
                          type: 'REMOVE_ACCEPTED_FILE',
                          payload: index,
                        })
                      }
                      sx={{
                        color: 'inherit',
                        minWidth: 'auto',
                        marginY: '-2px',
                      }}
                    >
                      <CloseIcon />
                    </Button>
                  }
                >
                  {translation.SuccessValidFile(file.file.name ?? '')}
                </Alert>
              </Box>
            ))}
          </Box>
        )}
        {showProcessingFiles && (
          <Box
            sx={{
              maxHeight: '500px',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
              gap: theme.spacing(1),
            }}
          >
            {state.acceptedFiles!.map((s, i) => (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  wordWrap: 'break-word',
                  gap: theme.spacing(0.5),
                  padding: theme.spacing(1),
                  color: theme.palette.common.white,
                  borderRadius: '5px',
                  backgroundColor:
                    s.state === processingStates.error
                      ? theme.palette.error.main
                      : s.state === processingStates.success
                      ? theme.palette.success.main
                      : s.state === processingStates.warning
                      ? theme.palette.warning.main
                      : s.state === processingStates.processing
                      ? theme.palette.info.main
                      : theme.palette.grey[400],
                  marginLeft:
                    state.zipProcessingState.zipFileName && i > 0
                      ? theme.spacing(1)
                      : '0',
                }}
                key={s.fileName}
              >
                {s.state !== processingStates.idle && (
                  <Box
                    sx={{
                      width: '24px',
                      height: '24px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {s.state === processingStates.processing && (
                      <CircularProgress size={20} sx={{ color: 'inherit' }} />
                    )}
                    {(s.state === processingStates.error ||
                      s.state === processingStates.warning) && (
                      <ErrorIcon sx={{ fontSize: '16pt' }} />
                    )}
                    {s.state === processingStates.success && (
                      <CheckCircleIcon sx={{ fontSize: '16pt' }} />
                    )}
                  </Box>
                )}
                <Typography
                  variant="body1"
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    whiteSpace: 'normal',
                    wordBreak: 'break-all',
                  }}
                >
                  <span
                    style={{
                      marginRight: theme.spacing(0.5),
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {s.state === processingStates.error
                      ? translation.Failed
                      : s.state === processingStates.processing
                      ? translation.Processing
                      : null}
                  </span>
                  <span style={{ fontWeight: 600 }}>{s.fileName}</span>
                  <span
                    style={{
                      fontWeight: 600,
                      marginLeft: 'auto',
                      paddingRight: theme.spacing(1),
                      paddingLeft: theme.spacing(1),
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {s.state === processingStates.idle && translation.Idle}
                  </span>
                </Typography>
              </Box>
            ))}
          </Box>
        )}
        {state.error && (
          <Alert variant="filled" severity="error">
            {state.error}
          </Alert>
        )}
        {state.supplierDetectionError && state.acceptedFiles?.length === 1 && (
          <Alert
            severity="warning"
            variant="filled"
            sx={{ marginBottom: theme.spacing(1) }}
          >
            <Typography variant="body1" component="span">
              <Markdown
                value={translation.ErrorCouldNotDetectSupplier}
              ></Markdown>
            </Typography>
          </Alert>
        )}
        {showFileUpload && (
          <FileUpload
            accept={acceptedFileTypes}
            dropzoneText={translation.DropZoneTitle}
            dragRejectText={translation.ErrorCanOnlyAcceptPdfOrZip}
            onAcceptedFiles={handleAcceptedFiles}
            disabled={state.isUploading}
            isUploading={state.isUploading}
            multiple={true}
          ></FileUpload>
        )}
        {showSupplierSelection && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(1),
            }}
          >
            {
              <FormControl sx={{}}>
                <InputLabel id="supplier-select-label">
                  {translation.SelectSupplier}
                </InputLabel>
                <Select
                  labelId="supplier-select-label"
                  value={getSupplierSelectValue(state.supplier)}
                  label={translation.SelectSupplier}
                  onChange={handleSupplierChange}
                  disabled={state.isUploading}
                >
                  {suppliers.map((s) => (
                    <MenuItem
                      key={getSupplierSelectValue(s)}
                      value={getSupplierSelectValue(s)}
                    >
                      {s.supplierDisplayText}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            }
          </Box>
        )}
        {!finishedProcessingZip && !state.finishedProcessingPdf && (
          <Button
            fullWidth
            sx={{ alignSelf: 'center', marginTop: theme.spacing(1) }}
            variant="contained"
            disabled={isStartBookInEnabled}
            onClick={() => handleUpload()}
            data-testid="submit-invoice-button"
          >
            {translation.SubmitButton(
              `${selectedClient?.name} [${selectedClient?.clientId}]`
            )}
          </Button>
        )}
        {(finishedProcessingZip || state.finishedProcessingPdf) && (
          <Button
            fullWidth
            sx={{ alignSelf: 'center', marginTop: theme.spacing(1) }}
            variant="contained"
            onClick={() => onClose(true)}
            data-testid="finished-zip-invoice-button"
          >
            {translation.RefreshAndClose}
          </Button>
        )}
      </Box>
    </ModalDialog>
  )
}

export default MultiInvoiceUpload
