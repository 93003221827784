import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { FC, useContext, useEffect, useState } from 'react'
import { locale } from '../../locales'
import theme from '../../styles/theme'
import {
  ClientSelection,
  ClientTypes,
} from '../../types/entities/ClientPermission'
import Alert from '@mui/material/Alert'
import Markdown from 'marked-react'
import { MonthEndReconciliationSummary } from './entities/mer-entities'
import MonthEndReconciliationUploadPopup from './components/MonthEndReconciliationUploadPopup'
import MonthEndReconciliationAnalysisForm from './components/MonthEndReconciliationAnalysisForm'
import { ServiceContext } from '../../providers/ServicesProvider'
import { PlatformApiPaths } from '../../PlatformApiPaths'
import {
  addOdsCodeColumn,
  getLocaleDateMedWithoutWeekDay,
  toCurrencyString,
} from '../../utils/Helpers'
import MultiSelection from '../../components/Forms/MultiSelection'
import { GetErrorMessage } from '../../utils/ErrorHandling'
import VirtuosoMuiTable, {
  VirtuosoColumn,
} from '../../components/Data/VirtuosoMuiTable'
import { PageReducer, createRecoilPageState, usePageState } from '../PageState'
import { RecoilPageIds } from '../../constants'
import { useGlobalIsLoading } from '../../hooks/useIsLoading'
import { stringFromStatementDate } from './helpers'
import { useAbortableRequest } from '../../hooks/useAbortableRequest'

const translation = locale.translation.MerPage.MonthEndReconciliationTab

// Create static objects for the recoil state and reducer
const { recoilPageState, recoilTableState, recoilLoadingAbortedState } =
  createRecoilPageState<MonthEndReconciliationSummary>(RecoilPageIds.TabMer)
const pageReducer = new PageReducer<MonthEndReconciliationSummary>()

export const creditValueTransformer = (v: number | null | undefined) => {
  if (v != null) {
    return toCurrencyString(Math.abs(v))
  }
  return ''
}

const getTableColumns = (
  selectedClient: ClientSelection | null,
  odsToPharmacyMapping: { [key: string]: string } | null
): VirtuosoColumn<MonthEndReconciliationSummary>[] => {
  const result = [
    {
      label: translation.HistoryTableColumns.Supplier,
      dataKey: 'supplierDisplayName',
    },
    {
      label: translation.HistoryTableColumns.StatementDate,
      dataKey: 'statementDate',
      valueTransformer: (row) => {
        return stringFromStatementDate(row.statementDateObj, row.statementDate)
      },
    },
    {
      label: 'Statement Documents date range',
      dataKey: 'dateRange',
      valueTransformer: (row) => {
        return `${
          getLocaleDateMedWithoutWeekDay(row.documentsStartDate) ?? ''
        } - ${getLocaleDateMedWithoutWeekDay(row.documentsEndDate) ?? ''}`
      },
      widthCss: '240px',
    },
    {
      label: translation.HistoryTableColumns.StatementAmount,
      dataKey: 'calculatedStatementTotalValue',
      currency: true,
    },
    {
      label: translation.HistoryTableColumns.BookInAmmount,
      dataKey: 'calculatedSystemTotalValue',
      currency: true,
    },
    {
      label: translation.HistoryTableColumns.TotalDiscrepancy,
      dataKey: 'totalDiscrepancy',
      currency: true,
      calculatedCellSx: (value: MonthEndReconciliationSummaryRow) => {
        const bold = { fontWeight: 600 }
        if (value?.totalDiscrepancy != null && value.totalDiscrepancy !== 0) {
          return {
            ...bold,
            color: theme.palette.warning.main,
          }
        }
        return bold
      },
    },
    {
      label: translation.HistoryTableColumns.MatchedInvoicesValue,
      dataKey: 'calculatedMatchedInvoicesValue',
      currency: true,
    },
    {
      label: translation.HistoryTableColumns.UnmatchedInvoicesValue,
      dataKey: 'calculatedUnmatchedInvoicesValue',
      currency: true,
    },
    {
      label: translation.HistoryTableColumns.MatchedCreditsValue,
      dataKey: 'calculatedMatchedCreditsValue',
      valueTransformer: (row) =>
        creditValueTransformer(row.calculatedMatchedCreditsValue),
    },
    {
      label: translation.HistoryTableColumns.UnmatchedCreditsValue,
      dataKey: 'calculatedUnmatchedCreditsValue',
      valueTransformer: (row) =>
        creditValueTransformer(row.calculatedUnmatchedCreditsValue),
    },
  ] as VirtuosoColumn<MonthEndReconciliationSummary>[]

  return addOdsCodeColumn<MonthEndReconciliationSummary>(
    selectedClient?.clientType ?? null,
    odsToPharmacyMapping,
    result,
    0
  )
}

interface MonthEndReconciliationSummaryRow
  extends MonthEndReconciliationSummary {
  key: string
}

const TabMer: FC = () => {
  const { platformHttpService } = useContext(ServiceContext)
  const [showUploadDialog, setShowUploadDialog] = useState(false)
  const [openMerId, setOpenMerId] = useState<{
    merId: string
    odsCode: string
  } | null>(null)
  const { setIsLoading } = useGlobalIsLoading()

  // Handle page state for this page
  const {
    state,
    dispatch,
    virtuosoTableState,
    shouldRunLoadingEffect,
    odsToPharmacyNameMappings,
    virtuosoTableHelpers,
    setLoadingAbortedState,
  } = usePageState<MonthEndReconciliationSummary>(
    pageReducer,
    recoilPageState,
    recoilTableState,
    recoilLoadingAbortedState,
    (item) => item.monthEndReconciliationId,
    {
      dataKey: '',
      sortingType: 'NONE',
    }
  )
  const canUploadStatement =
    state.selectedClient?.clientType === ClientTypes.Pharmacy

  const handleUploadSuccess = (merId: string, odsCode: string) => {
    setShowUploadDialog(false)
    handleItemUpdated(merId, odsCode)
    setOpenMerId({ merId, odsCode })
  }

  const onClickedViewCallback = (row: MonthEndReconciliationSummary) => {
    setOpenMerId({
      merId: row.monthEndReconciliationId!,
      odsCode: row.odsCode!,
    })
  }

  const { abortControllerRef, startAbortableRequest, finishAbortableRequest } =
    useAbortableRequest(setLoadingAbortedState)

  useEffect(() => {
    const fetchMerHistory = async () => {
      return platformHttpService.postAsync<MonthEndReconciliationSummary[]>(
        PlatformApiPaths.MerHistory(state.selectedClient!),
        {
          dateFrom: state.dates[0],
          dateTo: state.dates[1],
          supplierIds: state.selectedSupplierIds,
        },
        'MerBaseUri',
        abortControllerRef.current?.signal
      )
    }

    if (shouldRunLoadingEffect) {
      // every time a new request comes in, cancel the previous one
      startAbortableRequest()
      dispatch({ type: 'setError', payload: null })

      fetchMerHistory().then((response) => {
        // Check if the request was cancelled
        if (finishAbortableRequest(response.wasCancelled)) {
          return
        }
        // Process response
        if (response?.data && !response.hasErrors) {
          dispatch({
            type: 'addItems',
            payload: { items: response.data, hasMorePages: false },
          })
        } else {
          dispatch({
            type: 'setError',
            payload: GetErrorMessage(response?.statusCode),
          })
        }
      })
    }
  }, [
    abortControllerRef,
    dispatch,
    finishAbortableRequest,
    platformHttpService,
    setIsLoading,
    shouldRunLoadingEffect,
    startAbortableRequest,
    state.dates,
    state.selectedClient,
    state.selectedSupplierIds,
  ])

  const handleItemUpdated = async (merId: string, odsCode: string) => {
    const response =
      await platformHttpService.getAsync<MonthEndReconciliationSummary>(
        PlatformApiPaths.GetMerHistoryItem(odsCode, merId),
        'MerBaseUri'
      )
    if (response.hasErrors) {
      dispatch({
        type: 'setError',
        payload: GetErrorMessage(response?.statusCode),
      })
    } else {
      const updatedItem = response.data!
      const existingItem = state.items?.find(
        (i) =>
          i.monthEndReconciliationId === updatedItem.monthEndReconciliationId
      )
      if (existingItem) {
        dispatch({ type: 'replaceItems', payload: [updatedItem] })
      } else {
        dispatch({ type: 'addItem', payload: updatedItem })
      }
    }
  }

  const handleItemDeleted = (merId: string) => {
    dispatch({
      type: 'deleteItem',
      payload: merId,
    })
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          gap: theme.spacing(2),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: theme.spacing(2),
            justifyContent: 'flex-start',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => setShowUploadDialog(true)}
            disabled={!canUploadStatement}
            data-testid="show-mer-upload-button"
          >
            {translation.UploadButton}
          </Button>
          <Box sx={{ flexGrow: 1, marginX: theme.spacing(1) }}>
            {!canUploadStatement && (
              <Alert variant="filled" severity="info" sx={{ height: '100%' }}>
                <Markdown value={translation.SelectPharmacyToStart} />
              </Alert>
            )}
          </Box>
          <MultiSelection
            id={'multi-supplier'}
            onSelectionApplied={(s) => {
              dispatch({
                type: 'setSelectedSupplierIds',
                payload: s,
              })
            }}
            options={state.availableSuppliers}
            selectedOptionKeys={state.selectedSupplierIds}
            title={
              state.selectedSupplierIds.length > 0 &&
              state.selectedSupplierIds.length ===
                Object.entries(state.availableSuppliers).length
                ? locale.translation.MultiSupplierSelection.AllSuppliers
                : locale.translation.MultiSupplierSelection.SpecificSuppliers
            }
            isDirty={
              state.selectedSupplierIds.length > 0 &&
              state.selectedSupplierIds.length !==
                Object.entries(state.availableSuppliers).length
            }
          />
        </Box>
        {!state.error && state.items && (
          <VirtuosoMuiTable
            rows={state.items}
            columns={getTableColumns(
              state.selectedClient,
              odsToPharmacyNameMappings
            )}
            onRowClick={onClickedViewCallback}
            initialFirstItemIndex={virtuosoTableState.firstItemIndex}
            onRangeChanged={virtuosoTableHelpers.handleRangeChanged}
          />
        )}
        {state.error && (
          <Alert variant="filled" severity="error">
            {state.error}
          </Alert>
        )}
      </Box>
      {showUploadDialog && (
        <MonthEndReconciliationUploadPopup
          selectedClient={state.selectedClient}
          onClose={() => setShowUploadDialog(false)}
          onProcessedSuccess={handleUploadSuccess}
        />
      )}
      {Boolean(openMerId) && (
        <MonthEndReconciliationAnalysisForm
          open={Boolean(openMerId)}
          merId={openMerId!.merId!}
          odsCode={openMerId!.odsCode!}
          processOnOpen={false}
          onClosed={(merId, odsCode, wasDeleted) => {
            if (merId) {
              if (!wasDeleted) {
                handleItemUpdated(merId, odsCode)
              } else {
                handleItemDeleted(merId)
              }
            }
            setOpenMerId(null)
          }}
        />
      )}
    </>
  )
}
export default TabMer
