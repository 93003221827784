import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import Box from '@mui/material/Box'
import theme from '../../styles/theme'
import { BookInSummaryWithOdsCode } from './entities/BookInSummary'
import { ServiceContext } from '../../providers/ServicesProvider'
import Alert from '@mui/material/Alert'
import { locale } from '../../locales'
import Button from '@mui/material/Button'
import {
  addOdsCodeColumn,
  getLocaleDateMedWithWeekDay,
  getLocaleDateMedWithWeekDayAndHour,
  getDateOnlyIsoString,
  hoursDistanceFromNow,
  packSizeConverter,
} from '../../utils/Helpers'
import { sortingTypeToDeliverySearchQueryParams } from './helpers'
import { PlatformApiPaths } from '../../PlatformApiPaths'
import {
  ClientSelection,
  ClientTypes,
} from '../../types/entities/ClientPermission'
import { ProcessInvoicesResponse } from './entities/BookIn'
import { GetErrorMessage } from '../../utils/ErrorHandling'
import Markdown from 'marked-react'
import {
  BookInStatuses,
  DatePresetsLocalStorageKeys,
  EditDeliveryModes,
  RecoilPageIds,
} from '../../constants'
import VirtuosoMuiTable, {
  VirtuosoColumn,
} from '../../components/Data/VirtuosoMuiTable'
import InfoTooltip from '../../components/Interactions/InfoTooltip'
import {
  createRecoilPageState,
  usePageState,
  useResetRecoilPageState,
} from '../PageState'
import FilterBar from '../../components/Interactions/FilterBar'
import { useGlobalIsLoading } from '../../hooks/useIsLoading'
import SelectProductModal from './components/SelectProductModal'
import {
  BookInSummaryWithProductSelectionPageAction,
  BookInSummaryWithProductSelectionPageReducer,
  BookInSummaryWithProductSelectionPageState,
} from './productSelectionState'
import ButtonPopOver, {
  ButtonPopOverHandle,
} from '../../components/Interactions/ButtonPopOver'
import Checkbox from '@mui/material/Checkbox'
import MultiDeliveryForm, {
  MultiDeliveryFormState,
} from './components/MultiDeliveryForm'
import MultiInvoiceUpload from './components/MultiInvoiceUpload'
import { useAbortableRequest } from '../../hooks/useAbortableRequest'

const translation = locale.translation.GoodsInPage.TabNewAndPendingDeliveries

// Create static objects for the recoil state and reducer
const { recoilPageState, recoilTableState, recoilLoadingAbortedState } =
  createRecoilPageState<BookInSummaryWithOdsCode>(
    RecoilPageIds.TabPendingDeliveries
  )
const pageReducer = new BookInSummaryWithProductSelectionPageReducer()

const getTableColumns = (
  selectedClient: ClientSelection | null,
  odsToPharmacyMapping: { [key: string]: string } | null
): VirtuosoColumn<BookInSummaryWithOdsCode>[] => {
  const result: VirtuosoColumn<BookInSummaryWithOdsCode>[] = [
    {
      label: '',
      dataKey: 'status',
      widthCss: '36px',
      customCellNode: (value, rowData) => {
        const isDuplicatedPending =
          rowData.duplicateInvoiceIdStatus === 'PENDING'
        const isDuplicatedCompleted =
          rowData.duplicateInvoiceIdStatus === 'COMPLETED'
        const isOld =
          (hoursDistanceFromNow(rowData.creationDate)?.hours ?? 0) < -24

        return (
          (isDuplicatedPending || isDuplicatedCompleted || isOld) && (
            <InfoTooltip
              mode={
                isDuplicatedCompleted
                  ? 'error'
                  : isDuplicatedPending
                  ? 'warning'
                  : 'info'
              }
              text={
                isDuplicatedCompleted
                  ? translation.DuplicatedCompleted
                  : isDuplicatedPending
                  ? translation.DuplicatedPending
                  : translation.OldInvoice
              }
              useColorWhenNotActive={true}
              tooltipSx={{ paddingX: theme.spacing(1) }}
            />
          )
        )
      },
    },
    {
      label: translation.Columns[1],
      dataKey: 'invoiceDate',
      valueTransformer: (rowData) => {
        return getLocaleDateMedWithWeekDay(rowData.invoiceDate)
      },
      sortable: true,
    },
    {
      label: translation.Columns[2],
      dataKey: 'invoiceNumber',
      valueTransformer: (rowData) =>
        rowData.orderNumber
          ? `${rowData.invoiceNumber} [${rowData.orderNumber}]`
          : rowData.invoiceNumber,
    },
    {
      label: translation.Columns[0],
      dataKey: 'supplierDisplayName',
    },
    {
      label: translation.Columns[3],
      dataKey: 'totalPrice',
      currency: true,
    },
    {
      label: translation.Columns[5],
      dataKey: 'creationDate',
      valueTransformer: (rowData) =>
        getLocaleDateMedWithWeekDayAndHour(rowData.creationDate),
      sortable: true,
      infoTooltip: translation.InfoDateReceived,
    },
  ]
  return addOdsCodeColumn<BookInSummaryWithOdsCode>(
    selectedClient?.clientType ?? null,
    odsToPharmacyMapping,
    result,
    1
  )
}

const TabNewAndPendingDeliveries: FC = () => {
  const serviceContext = useContext(ServiceContext)
  const { platformHttpService } = serviceContext

  const [openBookIn, setOpenBookIn] = useState<{
    odsCode: string
    bookInId: string
    isOpenAfterProcessing: boolean
  } | null>(null)
  const [openMultiBookIns, setOpenMultiBookIns] = useState<
    | {
        odsCode: string
        bookInId: string
      }[]
    | null
  >(null)

  const [showUpload, setShowUpload] = useState<boolean>(false)

  const [selectProductOpen, setSelectProductOpen] = useState<boolean>(false)
  const productOpenButtonPopOver = useRef<ButtonPopOverHandle>(null)

  const {
    resetPageStateCache: resetDeliveryHistoryPageCache,
    resetVirtuosoTableState: resetDeliveryHistoryTableState,
  } = useResetRecoilPageState(RecoilPageIds.TabDeliveryHistory)

  const { setIsLoading } = useGlobalIsLoading()

  // Handle page state for this page
  const {
    state: genericPageState,
    dispatch: genericDispatch,
    virtuosoTableState,
    shouldRunLoadingEffect,
    odsToPharmacyNameMappings,
    virtuosoTableHelpers,
    setLoadingAbortedState,
  } = usePageState<BookInSummaryWithOdsCode>(
    pageReducer,
    recoilPageState,
    recoilTableState,
    recoilLoadingAbortedState,
    (item) => item.bookInId,
    {
      dataKey: 'invoiceDate',
      sortingType: 'DESC',
    },
    undefined,
    {
      selectedProduct: null,
    },
    undefined,
    100
  )

  const state = genericPageState as BookInSummaryWithProductSelectionPageState
  const dispatch =
    genericDispatch as React.Dispatch<BookInSummaryWithProductSelectionPageAction>

  const canStartNewDelivery =
    state.selectedClient?.clientType === ClientTypes.Pharmacy

  const { abortControllerRef, startAbortableRequest, finishAbortableRequest } =
    useAbortableRequest(setLoadingAbortedState)

  useEffect(() => {
    const getDeliveries = async () => {
      const { sort, sortField } = sortingTypeToDeliverySearchQueryParams(
        state.sorting || null
      )
      return await platformHttpService.postAsync<BookInSummaryWithOdsCode[]>(
        PlatformApiPaths.GetPendingDeliveries(
          state.selectedClient!,
          `pageIndex=${state.pageIndex || 0}&pageSize=${state.pageSize!}`
        ),
        {
          invoiceDateFrom: getDateOnlyIsoString(state.dates[0]),
          invoiceDateTo: getDateOnlyIsoString(state.dates[1]),
          invoiceNumber: state.searchText,
          supplierIds:
            state.selectedSupplierIds.length ===
            Object.entries(state.availableSuppliers).length
              ? null
              : state.selectedSupplierIds,
          sort,
          sortField,
          productId: state.selectedProduct?.productId,
          productPackSize: state.selectedProduct?.packSize || null,
        },
        'StockBaseUrl',
        abortControllerRef.current?.signal
      )
    }
    if (shouldRunLoadingEffect) {
      // every time a new request comes in, cancel the previous one
      startAbortableRequest()
      dispatch({ type: 'setError', payload: null })

      const pageIndex = state.pageIndex
      getDeliveries().then((response) => {
        // Check if the request was cancelled
        if (finishAbortableRequest(response.wasCancelled)) {
          return
        }
        // Process response
        if (response?.data && !response.hasErrors) {
          const hasMorePages = response.data.length >= state.pageSize!
          dispatch({
            type: 'addItems',
            payload: { items: response.data, hasMorePages, pageIndex },
          })
        } else {
          dispatch({
            type: 'setError',
            payload: GetErrorMessage(response?.statusCode),
          })
        }
      })
    }
  }, [
    dispatch,
    platformHttpService,
    setIsLoading,
    shouldRunLoadingEffect,
    state.dates,
    state.pageIndex,
    state.searchText,
    state.selectedClient,
    state.selectedSupplierIds,
    state.sorting,
    state.refreshRandom,
    state.availableSuppliers,
    state.pageSize,
    state.selectedProduct?.productId,
    state.selectedProduct?.packSize,
    abortControllerRef,
    startAbortableRequest,
    finishAbortableRequest,
  ])

  const handleClickedRow = useCallback((item: BookInSummaryWithOdsCode) => {
    setOpenBookIn({
      odsCode: item.odsCode,
      bookInId: item.bookInId,
      isOpenAfterProcessing: false,
    })
  }, [])

  const handleProcessInvoice = useCallback(
    (response: ProcessInvoicesResponse | null) => {
      if (
        response &&
        state.selectedClient?.clientType === ClientTypes.Pharmacy
      ) {
        const bookInSummary: BookInSummaryWithOdsCode = {
          odsCode: state.selectedClient.clientId,
          bookInId: response.bookInId,
          invoiceDate: response.invoiceDate,
          invoiceNumber: response.invoiceNumber,
          orderNumber: response.orderNumber,
          status: response.status,
          supplierId: response.supplierId,
          supplierName: response.supplierName,
          supplierDisplayName: response.supplierDisplayName,
          totalPrice: response.totalPrice,
          creationDate: response.creationDate,
        }
        dispatch({
          type: 'addItem',
          payload: bookInSummary,
        })
        setOpenBookIn({
          odsCode: state.selectedClient.clientId,
          bookInId: response.bookInId,
          isOpenAfterProcessing: true,
        })
        setShowUpload(false)
      }
    },
    [dispatch, state.selectedClient?.clientId, state.selectedClient?.clientType]
  )

  const handleSelectedProduct = (
    productId: string,
    packSize: number,
    productName: string,
    unitOfMeasure: string | null,
    subPackDescription: string | null
  ) => {
    dispatch({
      type: 'setSelectedProduct',
      payload: Boolean(productId)
        ? {
            productId,
            packSize,
            productName,
            unitOfMeasure,
            subPackDescription,
          }
        : null,
    })
  }

  const handleRowSelection = useCallback(
    (row: BookInSummaryWithOdsCode) => {
      dispatch({
        type: 'replaceItems',
        payload: state.items.map((i) =>
          i.bookInId === row.bookInId ? { ...i, isSelected: !i.isSelected } : i
        ),
      })
    },
    [dispatch, state.items]
  )

  const handleMultiDeliveryFormClosed = useCallback(
    async (
      multiDeliveryFormState: MultiDeliveryFormState,
      wasOpenedAfterProcessing: boolean
    ) => {
      const getBookInsForInvoiceNumber = async (
        odsCode: string,
        supplierId: string,
        invoiceNumber: string
      ) => {
        return await platformHttpService.getAsync<{
          [key: string]: string
        }>(
          PlatformApiPaths.GetBookInsForInvoiceNumberAndSupplier(
            odsCode,
            supplierId,
            invoiceNumber
          ),
          'StockBaseUrl'
        )
      }

      const itemsToCheckForDuplicateInvoiceNumbers: {
        bookInId: string
        invoiceNumber: string
        previousInvoiceNumber: string | null
        odsCode: string
        supplierId: string
      }[] = []

      const completedBookIns = multiDeliveryFormState.bookIns.filter(
        (i) =>
          i.status === BookInStatuses.Completed ||
          i.status === BookInStatuses.ReEdit
      )
      if (completedBookIns.length > 0) {
        resetDeliveryHistoryPageCache()
        resetDeliveryHistoryTableState()
      }

      // remove completed and deleted book-ins from the list
      const bookInIdsToRemove = [
        ...completedBookIns.map((i) => i.bookInId),
        ...multiDeliveryFormState.deletedBookInIds,
      ]
      for (const bookInId of bookInIdsToRemove) {
        const existingItemToRemove = state.items.find(
          (i) => i.bookInId === bookInId
        )
        if (existingItemToRemove && existingItemToRemove.invoiceNumber) {
          itemsToCheckForDuplicateInvoiceNumbers.push({
            bookInId,
            odsCode: existingItemToRemove.odsCode,
            invoiceNumber: existingItemToRemove.invoiceNumber!,
            previousInvoiceNumber: existingItemToRemove.invoiceNumber!,
            supplierId: existingItemToRemove.supplierId!,
          })
        }
        dispatch({
          type: 'deleteItem',
          payload: bookInId,
        })
      }

      // update the rest
      const bookInsToUpdate = multiDeliveryFormState.bookIns.filter(
        (i) =>
          i.status !== BookInStatuses.Completed &&
          i.status !== BookInStatuses.ReEdit &&
          !multiDeliveryFormState.deletedBookInIds.includes(i.bookInId)
      )
      const itemsToUpdate: BookInSummaryWithOdsCode[] = []

      for (const bookIn of bookInsToUpdate) {
        const tabState = multiDeliveryFormState.tabStates.find(
          (ts) => ts.bookInId === bookIn.bookInId && Boolean(ts.header)
        )
        if (tabState) {
          const itemToUpdate = state.items.find(
            (i) => i.bookInId === bookIn.bookInId
          )
          if (itemToUpdate) {
            itemsToUpdate.push({
              ...itemToUpdate,
              invoiceDate: tabState.header!.initialInvoiceDate
                ? tabState.header!.initialInvoiceDate.toISO()
                : null,
              invoiceNumber: tabState.header!.initialInvoiceNumber,
              totalPrice: tabState.header!.initialTotalExVat,
            })

            if (
              Boolean(itemToUpdate.invoiceNumber) &&
              (itemToUpdate.invoiceNumber !==
                tabState.header!.initialInvoiceNumber ||
                wasOpenedAfterProcessing)
            ) {
              itemsToCheckForDuplicateInvoiceNumbers.push({
                bookInId: bookIn.bookInId,
                odsCode: bookIn.odsCode,
                invoiceNumber: tabState.header!.initialInvoiceNumber!,
                previousInvoiceNumber: itemToUpdate.invoiceNumber,
                supplierId: itemToUpdate.supplierId!,
              })
            }
          }
        }
      }

      // handle unique invoice numbers
      for (const item of itemsToCheckForDuplicateInvoiceNumbers) {
        const existingBookInsResponse = await getBookInsForInvoiceNumber(
          item.odsCode,
          item.supplierId,
          item.invoiceNumber
        )
        if (
          !existingBookInsResponse.hasErrors &&
          existingBookInsResponse.data
        ) {
          if (Object.keys(existingBookInsResponse.data).length > 1) {
            const hasCompleted = Object.values(
              existingBookInsResponse.data
            ).includes('COMPLETED')
            const bookInIds = Object.keys(existingBookInsResponse.data)
            for (const bookInId of bookInIds) {
              let existingItemToUpdate = itemsToUpdate.find(
                (i) => i.bookInId === bookInId
              )
              if (existingItemToUpdate) {
                existingItemToUpdate.duplicateInvoiceIdStatus = hasCompleted
                  ? 'COMPLETED'
                  : 'PENDING'
              } else {
                const existingItem = state.items.find(
                  (i) => i.bookInId === bookInId
                )
                if (existingItem) {
                  itemsToUpdate.push({
                    ...existingItem,
                    duplicateInvoiceIdStatus: hasCompleted
                      ? 'COMPLETED'
                      : 'PENDING',
                  })
                }
              }
            }
          } else if (item.previousInvoiceNumber) {
            const existingItems = state.items.filter(
              (i) => i.invoiceNumber === item.previousInvoiceNumber
            )
            for (const existingItem of existingItems) {
              const existingItemToUpdate = itemsToUpdate.find(
                (i) => i.bookInId === existingItem.bookInId
              )
              if (existingItemToUpdate) {
                existingItemToUpdate.duplicateInvoiceIdStatus = null
              } else {
                itemsToUpdate.push({
                  ...existingItem,
                  duplicateInvoiceIdStatus: null,
                })
              }
            }
          }
        }
      }
      if (itemsToUpdate.length > 0) {
        dispatch({
          type: 'replaceItems',
          payload: itemsToUpdate,
        })
      }
    },
    [
      dispatch,
      platformHttpService,
      resetDeliveryHistoryPageCache,
      resetDeliveryHistoryTableState,
      state.items,
    ]
  )

  const renderButtonsFromRowSelections = () => {
    const selectedItems = state.items.filter((i) => i.isSelected)
    // no selected item buttons
    if (selectedItems.length === 0) {
      return (
        <>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => setShowUpload(!showUpload)}
            disabled={!canStartNewDelivery}
            data-testid="create-hide-new-delivery-button"
          >
            {translation.CreateNewDelivery}
          </Button>
          <Box sx={{ flexGrow: 1, marginLeft: theme.spacing(1) }}>
            {!canStartNewDelivery && (
              <Alert variant="filled" severity="info">
                <Markdown
                  value={translation.SelectPharmacyToStartANewDelivery}
                />
              </Alert>
            )}
          </Box>
        </>
      )
    }

    return (
      <>
        <Button
          variant="contained"
          color="primary"
          data-testid="start-multiple-deliveries-button"
          onClick={() => {
            if (state.selectedClient?.clientId) {
              setOpenMultiBookIns(
                selectedItems.map((i) => {
                  return { odsCode: i.odsCode, bookInId: i.bookInId }
                })
              )
            }
          }}
        >
          {translation.BookInMultipleInvoiceButton(
            state.items.filter((i) => i.isSelected).length
          )}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          data-testid="unselect-selected-deliveries-button"
          onClick={() => {
            dispatch({
              type: 'replaceItems',
              payload: state.items.map((i) => {
                return { ...i, isSelected: false }
              }),
            })
          }}
        >
          {translation.ClearSelectionsButton}
        </Button>
      </>
    )
  }

  const tableColumns: VirtuosoColumn<BookInSummaryWithOdsCode>[] =
    useMemo(() => {
      const result = getTableColumns(
        state.selectedClient,
        odsToPharmacyNameMappings
      )
      return state.selectedClient?.clientType === ClientTypes.Pharmacy
        ? [
            {
              label: '',
              dataKey: 'isSelected',
              widthCss: '40px',
              calculatedCellSx: (rowData) => {
                return {
                  padding: 0,
                }
              },
              customCellNode: (value, rowData) => {
                return (
                  <Box sx={{ backgroundColor: 'transparent' }}>
                    <Checkbox
                      checked={rowData.isSelected ?? false}
                      onClick={(e) => {
                        e.stopPropagation()
                        handleRowSelection(rowData)
                      }}
                    />
                  </Box>
                )
              },
            } as VirtuosoColumn<BookInSummaryWithOdsCode>,
            ...result,
          ]
        : [...result]
    }, [odsToPharmacyNameMappings, state.selectedClient, handleRowSelection])

  return (
    state.selectedClient && (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          marginTop: theme.spacing(2),
          gap: theme.spacing(2),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            gap: theme.spacing(1),
          }}
        >
          {renderButtonsFromRowSelections()}
        </Box>
        {showUpload &&
          state.selectedClient.clientType === ClientTypes.Pharmacy && (
            <MultiInvoiceUpload
              selectedClient={state.selectedClient}
              serviceContext={serviceContext}
              onInvoiceProcessedCallback={handleProcessInvoice}
              onClose={(shouldRefresh) => {
                setShowUpload(false)
                if (shouldRefresh) {
                  dispatch({ type: 'setForceRefresh', payload: true })
                }
              }}
            />
          )}
        <FilterBar
          state={state}
          dispatch={dispatch}
          searchPlaceholder={
            locale.translation.GoodsInPage.DeliveriesSearch
              .SearchByInvoiceNumber
          }
          datePickerPresetKey={
            DatePresetsLocalStorageKeys.PENDING_DELIVERIES_DATE_RANGE_PRESET_KEY
          }
          searchFieldTestId={'invoice-search-field'}
          additionalSearchElement={
            <ButtonPopOver
              variant={state.selectedProduct ? 'contained' : 'outlined'}
              ref={productOpenButtonPopOver}
              placement="bottom"
              buttonContent={
                <>
                  {state.selectedProduct
                    ? `${state.selectedProduct.productName}
                  ${packSizeConverter(
                    state.selectedProduct.packSize || null,
                    state.selectedProduct.unitOfMeasure,
                    state.selectedProduct.subPackDescription
                  )}`
                    : locale.translation.GoodsInPage.Common.AnyProduct}
                </>
              }
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Button
                  variant="text"
                  onClick={() => {
                    productOpenButtonPopOver.current?.close()
                    setSelectProductOpen(true)
                  }}
                >
                  Select a product
                </Button>
                {state.selectedProduct && (
                  <Button
                    variant="text"
                    onClick={() => {
                      productOpenButtonPopOver.current?.close()
                      dispatch({ type: 'setSelectedProduct', payload: null })
                    }}
                    color="error"
                  >
                    {locale.translation.GoodsInPage.Common.Clear}
                  </Button>
                )}
              </Box>
            </ButtonPopOver>
          }
        />

        {!state.error && state.items && (
          <VirtuosoMuiTable
            noRowsMessage={translation.NoRecords}
            columns={tableColumns}
            rows={state.items}
            onRowClick={(row) => handleClickedRow(row)}
            onEndReached={virtuosoTableHelpers.handleInfiniteLoading}
            sorting={state.sorting}
            onSortingChanged={virtuosoTableHelpers.handleSortingChanged}
            initialFirstItemIndex={virtuosoTableState.firstItemIndex}
            onRangeChanged={virtuosoTableHelpers.handleRangeChanged}
          />
        )}
        {state.error && (
          <Alert variant="filled" severity="error">
            {state.error}
          </Alert>
        )}
        {openBookIn && (
          <MultiDeliveryForm
            bookInsToLoad={[openBookIn]}
            editDeliveryMode={EditDeliveryModes.PendingDeliveries}
            onClosed={(formState) => {
              const wasOpenAfterProcessing = openBookIn.isOpenAfterProcessing
              setOpenBookIn(null)
              handleMultiDeliveryFormClosed(formState, wasOpenAfterProcessing)
            }}
          />
        )}
        {openMultiBookIns && (
          <MultiDeliveryForm
            bookInsToLoad={openMultiBookIns}
            editDeliveryMode={EditDeliveryModes.PendingDeliveries}
            onClosed={(formState) => {
              setOpenMultiBookIns(null)
              handleMultiDeliveryFormClosed(formState, false)
            }}
          />
        )}
        {selectProductOpen && (
          <SelectProductModal
            product={{
              packSize: state.selectedProduct?.packSize || null,
              productId: state.selectedProduct?.productId || '',
              productName: state.selectedProduct?.productName || '',
              productRawOcrName: null,
            }}
            onClosedCallback={async (selectedProduct) => {
              setSelectProductOpen(false)
              if (selectedProduct) {
                await handleSelectedProduct(
                  selectedProduct.productId,
                  selectedProduct.packSize,
                  selectedProduct.productName,
                  selectedProduct.unitOfMeasure,
                  selectedProduct.subPackDescription
                )
              }
            }}
            ignoreSpecials={true}
            showUnlistedProduct={false}
            packSizeOptional={true}
          />
        )}
      </Box>
    )
  )
}
export default TabNewAndPendingDeliveries
