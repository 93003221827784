import {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  MonthEndReconciliation,
  MonthEndReconciliationDocument,
  MonthEndReconciliationFlattened,
  ToMonthEndReconciliationFlattened,
} from '../entities/mer-entities'
import ModalContainer from '../../../components/Interactions/ModalContainer'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import { locale } from '../../../locales'
import {
  EditDeliveryModes,
  MonthEndReconciliationDocumentTypes,
} from '../../../constants'

import TableMui, { MuiTableColumn } from '../../../components/Data/TableMui'
import theme from '../../../styles/theme'
import { ServiceContext } from '../../../providers/ServicesProvider'
import { PlatformApiPaths } from '../../../PlatformApiPaths'
import Checkbox from '@mui/material/Checkbox'
import { SxProps, Theme } from '@mui/material/styles'
import { GetErrorMessage } from '../../../utils/ErrorHandling'
import {
  getLocaleDateMedWithoutWeekDay,
  getLocaleDateMonthYear,
  toCurrencyString,
} from '../../../utils/Helpers'
import MonthEndReconciliationExcludePopup from './MonthEndReconciliationExcludePopup'
import FormControlLabel from '@mui/material/FormControlLabel'
import LoadingButton from '@mui/lab/LoadingButton'
import KeyValueTable from '../../../components/Data/KeyValueTable'
import { creditValueTransformer } from '../TabMer'
import ConfirmDialog from '../../../components/Interactions/ConfirmDialog'
import Link from '@mui/material/Link'
import Switch from '@mui/material/Switch'
import LinearProgress from '@mui/material/LinearProgress'
import { useSuppliersWithFinancials } from '../../../hooks/useSuppliers'
import { SupplierWithFinancials } from '../../../types/entities/Supplier'
import ButtonBase from '@mui/material/ButtonBase'
import Chip from '@mui/material/Chip'
import Markdown from 'marked-react'
import TextField from '@mui/material/TextField'
import AutoSizingBox from '../../../components/Util/AutoSizingBox'
import { stringFromStatementDate } from '../helpers'
import Button from '@mui/material/Button'
import { PresignedUrlResponse } from '../../GoodsIn/entities/BookIn'
import MultiDeliveryForm from '../../GoodsIn/components/MultiDeliveryForm'

interface MonthEndReconciliationDocumentRow
  extends MonthEndReconciliationDocument {
  documentAmount: number | null
  onTappedExclude?: (row: MonthEndReconciliationDocument) => void
  onTappedInvoiceNumber?: (row: MonthEndReconciliationDocument) => void
  onTappedNote?: (row: MonthEndReconciliationDocument) => void
}

const translation =
  locale.translation.MerPage.MonthEndReconciliationAnalysisForm

const columnDefinitionsInvoices: Array<MuiTableColumn> = [
  {
    propertyName: 'documentId',
    label: translation.InvoiceAnalysisTable.StatementInvoiceNumber,
    width: 180,
    columnType: 'component',
    customComponent: (row: MonthEndReconciliationDocumentRow) => {
      return <StatementInvoiceId row={row} />
    },
  },
  {
    propertyName: 'documentAmount',
    label: translation.InvoiceAnalysisTable.StatementInvoiceAmount,
    width: 180,
    columnType: 'currency',
  },
  {
    propertyName: 'documentDate',
    label: translation.InvoiceAnalysisTable.StatementInvoiceDate,
    width: 180,
    valueTransformer: (value) =>
      getLocaleDateMedWithoutWeekDay(value as string),
  },
  {
    propertyName: 'systemDocumentId',
    label: translation.InvoiceAnalysisTable.MatchedBookedInInvoiceNumber,
    width: 180,
    columnType: 'component',
    customComponent: (row: MonthEndReconciliationDocumentRow) => {
      return <InvoiceLink row={row} />
    },
  },
  {
    propertyName: 'systemDocumentTotalPrice',
    label: translation.InvoiceAnalysisTable.MatchedBookedInInvoiceAmount,
    width: 180,
    columnType: 'currency',
  },
  {
    propertyName: 'systemDocumentDate',
    label: translation.InvoiceAnalysisTable.MatchedBookedInInvoiceDate,
    width: 180,
    valueTransformer: (value) =>
      getLocaleDateMedWithoutWeekDay(value as string),
  },
  {
    propertyName: 'optionsIsExcluded',
    label: translation.InvoiceAnalysisTable.ExcludeFromAnalysis,
    width: 120,
    columnType: 'component',
    customComponent: (row: MonthEndReconciliationDocumentRow) => {
      return <ExcludedCheckbox row={row} />
    },
  },
  {
    propertyName: 'optionsNote',
    label: translation.SystemOnlyDocumentsTable.Note,
    columnType: 'component',
    customComponent: (row: MonthEndReconciliationDocumentRow) => {
      return <NotesClickableCell row={row} />
    },
  },
]

const columnDefinitionsCredits: Array<MuiTableColumn> = [
  {
    propertyName: 'documentId',
    label: translation.CreditAnalysisTable.StatementInvoiceNumber,
    width: 180,
  },
  {
    propertyName: 'documentAmount',
    label: translation.CreditAnalysisTable.StatementInvoiceAmount,
    width: 180,
    columnType: 'currency',
    valueTransformer: (value) => creditValueTransformer(value),
  },
  {
    propertyName: 'documentDate',
    label: translation.CreditAnalysisTable.StatementInvoiceDate,
    width: 180,
    valueTransformer: (value) =>
      getLocaleDateMedWithoutWeekDay(value as string),
  },
  {
    propertyName: 'systemDocumentId',
    label: translation.CreditAnalysisTable.MatchedBookedInInvoiceNumber,
    width: 180,
    columnType: 'component',
    customComponent: (row: MonthEndReconciliationDocumentRow) => {
      return <InvoiceLink row={row} />
    },
  },
  {
    propertyName: 'systemDocumentTotalPrice',
    label: translation.CreditAnalysisTable.MatchedBookedInInvoiceAmount,
    width: 180,
    columnType: 'currency',
  },
  {
    propertyName: 'optionsIsExcluded',
    label: translation.CreditAnalysisTable.ExcludeFromAnalysis,
    width: 120,
    columnType: 'component',
    customComponent: (row: MonthEndReconciliationDocumentRow) => {
      return <ExcludedCheckbox row={row} />
    },
  },
  {
    propertyName: 'optionsNote',
    label: translation.SystemOnlyDocumentsTable.Note,
    columnType: 'component',
    customComponent: (row: MonthEndReconciliationDocumentRow) => {
      return <NotesClickableCell row={row} />
    },
  },
]

const columnDefinitionsSystemRecords: Array<MuiTableColumn> = [
  {
    propertyName: 'documentType',
    label: translation.SystemOnlyDocumentsTable.Type,
    width: 180,
    valueTransformer: (value) =>
      value === MonthEndReconciliationDocumentTypes.Invoice
        ? translation.Invoice
        : translation.Credit,
  },
  {
    propertyName: 'systemDocumentId',
    label: translation.SystemOnlyDocumentsTable.InvoiceNumber,
    width: 180,
    columnType: 'component',
    customComponent: (row: MonthEndReconciliationDocumentRow) => {
      return <InvoiceLink row={row} />
    },
  },
  {
    propertyName: 'systemDocumentDate',
    label: translation.SystemOnlyDocumentsTable.InvoiceDate,
    width: 180,
    valueTransformer: (value) =>
      getLocaleDateMedWithoutWeekDay(value as string),
  },
  {
    propertyName: 'systemDocumentTotalPrice',
    label: translation.SystemOnlyDocumentsTable.InvoiceAmount,
    width: 180,
    columnType: 'currency',
  },
  {
    propertyName: 'optionsIsExcluded',
    label: translation.SystemOnlyDocumentsTable.ExcludeFromAnalysis,
    width: 120,
    columnType: 'component',
    customComponent: (row: MonthEndReconciliationDocumentRow) => {
      return <ExcludedCheckbox row={row} />
    },
  },
  {
    propertyName: 'optionsNote',
    label: translation.SystemOnlyDocumentsTable.Note,
    columnType: 'component',
    customComponent: (row: MonthEndReconciliationDocumentRow) => {
      return <NotesClickableCell row={row} />
    },
  },
]

const tableRowCss = (value: MonthEndReconciliationDocument): SxProps<Theme> => {
  let result: SxProps<Theme> = {}
  if (!value.optionsIsExcluded) {
    if (!value.matchedDocumentFound) {
      // Document not matched
      result = {
        ...result,
        backgroundColor: theme.palette.error.main,
        color: theme.palette.grey[50],
        '&:hover': {
          backgroundColor: theme.palette.error.light,
        },
      }
    } else if (!value.matchedIsExactPriceMatch) {
      // Price not matched
      result = {
        ...result,
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.grey[50],
        '&:hover': {
          backgroundColor: theme.palette.warning.light,
        },
      }
    } else {
      // Price matched
      result = {
        ...result,
        backgroundColor: theme.palette.success.light,
        color: theme.palette.grey[50],
        '&:hover': {
          backgroundColor: theme.palette.success.main,
        },
      }
    }
  } else {
    // Excluded
    result = {
      ...result,
      color: theme.palette.grey[400],
    }
  }

  return result
}

const tableRowTooltip = (
  value: MonthEndReconciliationDocument
): string | undefined => {
  if (!value.optionsIsExcluded) {
    if (!value.matchedDocumentFound) {
      // Document not matched
      return value.documentType === MonthEndReconciliationDocumentTypes.Invoice
        ? translation.NotFoundInvoiceMessage(value.documentId)
        : translation.NotFoundCreditMessage(value.documentNetAmount)
    } else if (!value.matchedIsExactPriceMatch) {
      // Price not matched
      let message = translation.MismatchedInvoiceAmountMessage(value.documentId)
      if (value.matchedIsAproximateDocumentMatch) {
        if (message) {
          message = `${message}\n`
        }
        message = `${message}${translation.MismatchedInvoiceNumberMessage}`
      }
      return message
    }
  }
  return undefined
}

const SectionHeader = (props: PropsWithChildren) => (
  <Typography
    variant="h6"
    sx={{
      fontWeight: 500,
      textAlign: 'center',
      padding: theme.spacing(2),
    }}
  >
    {props.children}
  </Typography>
)

const ExcludedCheckbox: FC<{
  row: MonthEndReconciliationDocumentRow
}> = ({ row }) => {
  return (
    <>
      <Box sx={{ width: '24px', height: '24px', marginX: 'auto', padding: 0 }}>
        <Checkbox
          sx={{
            padding: 0,
            '&.Mui-disabled': { color: 'inherit' },
            color: 'inherit',
          }}
          checked={row.optionsIsExcluded}
          onClick={(event) => {
            row.onTappedExclude?.(row)
          }}
        />
      </Box>
    </>
  )
}

const NotesClickableCell: FC<{
  row: MonthEndReconciliationDocumentRow
}> = ({ row }) => {
  return (
    <ButtonBase
      sx={{ width: '100%', height: '24px' }}
      onClick={() => row.onTappedNote?.(row)}
    >
      {row.optionsNote}
    </ButtonBase>
  )
}

const InvoiceLink: FC<{
  row: MonthEndReconciliationDocumentRow
}> = ({ row }) => {
  const isApproximateMatch =
    !row.optionsIsExcluded &&
    row.matchedDocumentFound &&
    row.matchedIsAproximateDocumentMatch
  return (
    <>
      {row.systemDocumentId && (
        <Link
          sx={{
            backgroundColor: isApproximateMatch
              ? theme.palette.common.white
              : 'inherit',
            padding: isApproximateMatch ? theme.spacing(0.5) : 'inherit',
            color: isApproximateMatch ? theme.palette.warning.main : 'inherit',
            fontWeight: isApproximateMatch ? 700 : 500,
            textDecoration: 'underline',
            textDecorationColor: 'inherit',
            textDecorationThickness: '2px',
            '&:hover': {
              color: isApproximateMatch
                ? theme.palette.warning.main
                : 'inherit',
              textDecoration: 'underline',
              textDecorationColor: 'inherit',
              textDecorationThickness: '2px',
            },
          }}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            row.onTappedInvoiceNumber?.(row)
          }}
        >
          {row.systemDocumentId}
        </Link>
      )}
      {!row.systemDocumentId && '-'}
    </>
  )
}

const StatementInvoiceId: FC<{
  row: MonthEndReconciliationDocumentRow
}> = ({ row }) => {
  const isApproximateMatch =
    !row.optionsIsExcluded &&
    row.matchedDocumentFound &&
    row.matchedIsAproximateDocumentMatch
  return (
    <>
      {row.documentId && (
        <Box
          component="span"
          sx={{
            backgroundColor: isApproximateMatch
              ? theme.palette.common.white
              : 'inherit',
            padding: isApproximateMatch ? theme.spacing(0.5) : 'inherit',
            color: isApproximateMatch ? theme.palette.warning.main : 'inherit',
            fontWeight: isApproximateMatch ? 700 : 500,
          }}
        >
          {row.documentId}
        </Box>
      )}
      {!row.documentId && '-'}
    </>
  )
}

const MonthEndReconciliationAnalysisForm: FC<{
  merId: string
  odsCode: string
  processOnOpen: boolean
  open: boolean
  onClosed: (merId: string | null, odsCode: string, wasDeleted: boolean) => void
}> = ({ merId, odsCode, processOnOpen, open, onClosed }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isInitialLoading, setIsInitialLoading] = useState(true)
  const [isDeleting, setIsDeleting] = useState(false)
  const [error, setError] = useState('')
  const [data, setData] = useState<MonthEndReconciliationFlattened | null>(null)
  const [openExcludeDialog, setOpenExcludeDialog] = useState<{
    document: MonthEndReconciliationDocument
    isChecked: boolean
  } | null>(null)
  const [showsOnlyDiscrepancies, setShowsOnlyDiscrepancies] = useState(false)
  const [showDeleteMer, setShowDeleteMer] = useState(false)
  const [merSupplier, setMerSupplier] = useState<SupplierWithFinancials | null>(
    null
  )

  const { platformHttpService } = useContext(ServiceContext)
  const [hasBeenUpdated, setHasBeenUpdated] = useState(false)
  const { merSuppliers } = useSuppliersWithFinancials()

  const [merNote, setMerNote] = useState<string>('')

  const [openInvoice, setOpenInvoice] = useState<string | null>(null)

  const invoiceRows = useMemo(
    () =>
      (
        data?.monthEndReconciliationDocuments.filter(
          (i) =>
            i.documentType === MonthEndReconciliationDocumentTypes.Invoice &&
            (showsOnlyDiscrepancies
              ? !i.matchedIsExactPriceMatch || !i.matchedDocumentFound
              : true)
        ) ?? []
      ).map((d) => {
        return {
          ...d,
          documentAmount: merSupplier?.requiresVatForMer
            ? d.documentGrossAmount
            : d.documentNetAmount,
          onTappedExclude: (row: MonthEndReconciliationDocument) => {
            setOpenExcludeDialog({
              document: row,
              isChecked: row.optionsIsExcluded,
            })
          },
          onTappedNote: (row: MonthEndReconciliationDocument) => {
            setOpenExcludeDialog({
              document: row,
              isChecked: row.optionsIsExcluded,
            })
          },
          onTappedInvoiceNumber: (row: MonthEndReconciliationDocument) => {
            setOpenInvoice(`${row.systemId}|${odsCode}`)
          },
        } as MonthEndReconciliationDocumentRow
      }),
    [data, odsCode, showsOnlyDiscrepancies, merSupplier]
  )

  const creditRows = useMemo(
    () =>
      (
        data?.monthEndReconciliationDocuments.filter(
          (i) =>
            i.documentType === MonthEndReconciliationDocumentTypes.Credit &&
            (showsOnlyDiscrepancies
              ? !i.matchedIsExactPriceMatch || !i.matchedDocumentFound
              : true)
        ) ?? []
      ).map((d) => {
        return {
          ...d,
          documentAmount: merSupplier?.requiresVatForMer
            ? d.documentGrossAmount
            : d.documentNetAmount,
          onTappedExclude: (row: MonthEndReconciliationDocument) => {
            setOpenExcludeDialog({
              document: row,
              isChecked: row.optionsIsExcluded,
            })
          },
          onTappedNote: (row: MonthEndReconciliationDocument) => {
            setOpenExcludeDialog({
              document: row,
              isChecked: row.optionsIsExcluded,
            })
          },
          onTappedInvoiceNumber: (row: MonthEndReconciliationDocument) => {
            setOpenInvoice(`${row.systemId}|${odsCode}`)
          },
        } as MonthEndReconciliationDocumentRow
      }),
    [data, odsCode, showsOnlyDiscrepancies, merSupplier]
  )

  const systemOnlyRows = useMemo(
    () =>
      (data?.systemOnlyDocuments ?? []).map((d) => {
        return {
          ...d,
          onTappedExclude: (row: MonthEndReconciliationDocument) => {
            setOpenExcludeDialog({
              document: row,
              isChecked: row.optionsIsExcluded,
            })
          },
          onTappedInvoiceNumber: (row: MonthEndReconciliationDocument) => {
            setOpenInvoice(`${row.systemId}|${odsCode}`)
          },
          onTappedNote: (row: MonthEndReconciliationDocument) => {
            setOpenExcludeDialog({
              document: row,
              isChecked: row.optionsIsExcluded,
            })
          },
        } as MonthEndReconciliationDocumentRow
      }),
    [data, odsCode]
  )

  const extractedDataSummary = useMemo(() => {
    const result = [
      {
        key: translation.StatementNumberOfInvoices,
        value: `${
          data?.summary?.calculatedStatementInvoicesCountWithExcluded ?? ''
        }`,
      },
      {
        key: translation.StatementValueOfInvoices,
        value: toCurrencyString(
          data?.summary?.calculatedStatementInvoicesValueWithExcluded
        ),
      },
      {
        key: translation.StatementNumberOfCredits,
        value: `${
          data?.summary?.calculatedStatementCreditsCountWithExcluded ?? ''
        }`,
      },
      {
        key: translation.StatementValueOfCredits,
        value: creditValueTransformer(
          data?.summary?.calculatedStatementCreditValueWithExcluded
        ),
      },
      {
        key: translation.StatementTotalAsPrinted,
        value: toCurrencyString(data?.summary?.statementEffectiveTotalAmount),
      },
      {
        key: translation.StatementTotalAsCalculated,
        value: toCurrencyString(
          data?.summary?.calculatedStatementTotalValueWithExcluded
        ),
      },
    ]

    if (merSupplier) {
      const startingIndex = 4
      if ((data?.monthTotals?.length ?? 0) > 0) {
        result.splice(startingIndex, 1)
        let allMonthsTotal = 0
        let totalIndex = startingIndex
        data?.monthTotals?.forEach((v, i) => {
          const amount =
            (merSupplier.requiresVatForMer ? v.totalAmountWithVat : v.amount) ??
            0
          allMonthsTotal += amount
          totalIndex++
          result.splice(startingIndex + i, 0, {
            key: translation.StatementTotalAsPrintedPerMonth(
              getLocaleDateMonthYear(v.date) ?? ''
            ),
            value: toCurrencyString(amount),
          })
        })
        result.splice(totalIndex, 0, {
          key: translation.StatementTotalAsPrintedAllMonths,
          value: toCurrencyString(allMonthsTotal),
        })
      }
    }

    return result
  }, [data, merSupplier])

  const handleClickedDownloadStatement = async () => {
    if (odsCode && data?.monthEndReconciliationId) {
      const response = await platformHttpService.getAsync<PresignedUrlResponse>(
        PlatformApiPaths.GetMerStatementPdfUrl(
          odsCode,
          data.monthEndReconciliationId
        ),
        'MerBaseUri'
      )
      if (!response.hasErrors && response.data?.presignedUrl) {
        window.open(response.data.presignedUrl, '_blank', 'noreferrer')
      }
    }
  }

  useEffect(() => {
    if (merSuppliers.length > 0 && merId && odsCode) {
      const processMer = async () => {
        return platformHttpService.postAsync<MonthEndReconciliation>(
          PlatformApiPaths.ProcessMerStatement(odsCode, merId),
          null,
          'MerBaseUri'
        )
      }
      const getMer = async () => {
        return platformHttpService.getAsync<MonthEndReconciliation>(
          PlatformApiPaths.GetMerStatement(odsCode, merId),
          'MerBaseUri'
        )
      }

      setIsLoading(true)
      const request = processOnOpen ? processMer : getMer
      request().then((response) => {
        setIsLoading(false)
        setIsInitialLoading(false)
        if (response.hasErrors) {
          setError(GetErrorMessage(response?.statusCode))
        } else {
          setData(ToMonthEndReconciliationFlattened(response.data))
          setMerNote(response.data?.note ?? '')
          if (processOnOpen) {
            setHasBeenUpdated(true)
          }
          setMerSupplier(
            merSuppliers?.find(
              (s) => s.supplierId === response.data?.supplierId
            ) ?? null
          )
        }
      })
    }
  }, [merId, odsCode, platformHttpService, processOnOpen, merSuppliers])

  const handleRecalculate = async () => {
    if (odsCode) {
      setError('')
      setIsLoading(true)
      const response =
        await platformHttpService.postAsync<MonthEndReconciliation>(
          PlatformApiPaths.ProcessMerStatement(odsCode, merId),
          null,
          'MerBaseUri'
        )
      setIsLoading(false)
      if (response.hasErrors) {
        setError(GetErrorMessage(response?.statusCode))
      } else {
        setData(ToMonthEndReconciliationFlattened(response.data))
        setHasBeenUpdated(true)
      }
    }
  }

  const handleDelete = async () => {
    if (odsCode) {
      setError('')
      setIsDeleting(true)
      const response = await platformHttpService.deleteAsync(
        PlatformApiPaths.UpdateDeleteMer(odsCode, merId),
        null,
        'MerBaseUri'
      )
      setIsDeleting(false)
      if (response.hasErrors) {
        setError(GetErrorMessage(response?.statusCode))
      } else {
        onClosed(merId, odsCode, true)
      }
    }
  }

  const handleUpdatedExclusion = async (
    shouldReProcess: boolean,
    documentLineNumber: number | null,
    isExcluded: boolean | null,
    note: string | null
  ) => {
    setOpenExcludeDialog(null)
    if (shouldReProcess && odsCode) {
      setIsLoading(true)
      setError('')
      const response =
        await platformHttpService.putAsync<MonthEndReconciliation>(
          PlatformApiPaths.UpdateDeleteMer(odsCode, merId),
          {
            documentLineNumber: documentLineNumber,
            isExcluded: isExcluded,
            note: note,
          },
          'MerBaseUri'
        )
      setIsLoading(false)
      if (response.hasErrors) {
        setError(GetErrorMessage(response.statusCode))
      } else {
        setData(ToMonthEndReconciliationFlattened(response.data))
        setHasBeenUpdated(true)
      }
    }
  }

  const handleNoteBlur = async () => {
    if (odsCode) {
      setIsLoading(true)
      await platformHttpService.postAsync(
        PlatformApiPaths.UpdateNote(odsCode, merId),
        {
          note: merNote,
        },
        'MerBaseUri'
      )
      setIsLoading(false)
    }
  }

  return (
    <>
      <ModalContainer
        open={open}
        onClickedClose={() =>
          onClosed(hasBeenUpdated ? merId : null, odsCode, false)
        }
        alignSelf={'stretch'}
        sx={{ minWidth: '1200px' }}
      >
        <>
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexGrow: 1,
                alignSelf: 'stretch',
                flexDirection: 'column',
              }}
            >
              {/* Header */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="h5" sx={{ fontWeight: 500 }}>
                  {data?.supplierDisplayName}
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: 500 }}>
                  {stringFromStatementDate(
                    data?.statementDateObj ?? null,
                    data?.statementDate ?? null
                  )}
                </Typography>
              </Box>
              {error && (
                <Box>
                  <Alert variant="filled" severity="error">
                    {error}
                  </Alert>
                </Box>
              )}
              {!error &&
                !isLoading &&
                (data?.uploadId || data?.documentsStartDate) && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginTop: theme.spacing(0.5),
                    }}
                  >
                    <Box>
                      {data?.uploadId && (
                        <Button
                          data-testid="statement-download-button"
                          sx={{ paddingX: 0 }}
                          onClick={() => {
                            handleClickedDownloadStatement()
                          }}
                        >
                          {translation.PdfStatement}
                        </Button>
                      )}
                    </Box>
                    {data?.documentsStartDate && (
                      <Typography variant="body1" sx={{ fontWeight: 400 }}>
                        {translation.DocumentsDateRange}
                        {': '}
                        <span style={{ fontWeight: 600 }}>
                          {getLocaleDateMedWithoutWeekDay(
                            data.documentsStartDate
                          )}
                          {' - '}
                          {getLocaleDateMedWithoutWeekDay(
                            data.documentsEndDate
                          )}
                        </span>
                      </Typography>
                    )}
                  </Box>
                )}
              {!error && !isLoading && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <FormControlLabel
                    id="exclude-checkbox"
                    control={
                      <Switch
                        checked={showsOnlyDiscrepancies}
                        onChange={() =>
                          setShowsOnlyDiscrepancies(!showsOnlyDiscrepancies)
                        }
                        name="excludedCheckbox"
                      />
                    }
                    label={translation.ShowOnlyDiscrepancies}
                  />
                  <Chip
                    size="small"
                    label={
                      <Markdown
                        value={
                          merSupplier?.requiresVatForMer
                            ? translation.IncludesVat
                            : translation.DoesNotIncludeVat
                        }
                      />
                    }
                    color={'info'}
                  />
                </Box>
              )}
              <Box sx={{ height: theme.spacing(1) }}>
                {isLoading && <LinearProgress />}
              </Box>
              {!isInitialLoading && !error && (
                <AutoSizingBox>
                  {/* Invoices */}
                  <SectionHeader>{translation.InvoiceAnalysis}</SectionHeader>
                  <TableMui
                    isLoading={isLoading}
                    noRowsMessage={translation.NoRecords}
                    headerHeight={48}
                    capitalisedHeader={true}
                    columns={columnDefinitionsInvoices}
                    data={invoiceRows.map((i) => {
                      const rowItem = {
                        ...i,

                        key: `${i.documentPageNumber}|${i.documentPageLine}`,
                      }
                      return rowItem
                    })}
                    rowCalculatedCssProperties={tableRowCss}
                    rowTooltip={tableRowTooltip}
                  />
                  {/* Credits */}
                  <SectionHeader>
                    {translation.CreditNoteAnalysis}
                  </SectionHeader>
                  <TableMui
                    isLoading={isLoading}
                    noRowsMessage={translation.NoRecords}
                    headerHeight={48}
                    capitalisedHeader={true}
                    columns={columnDefinitionsCredits}
                    data={creditRows.map((i) => {
                      const rowItem = {
                        ...i,
                        key: `${i.documentPageNumber}|${i.documentPageLine}`,
                      }
                      return rowItem
                    })}
                    rowCalculatedCssProperties={tableRowCss}
                    rowTooltip={tableRowTooltip}
                  />
                  {/* System only */}
                  <SectionHeader>{translation.MissingRecords}</SectionHeader>
                  <TableMui
                    isLoading={isLoading}
                    noRowsMessage={translation.NoRecords}
                    headerHeight={48}
                    capitalisedHeader={true}
                    columns={columnDefinitionsSystemRecords}
                    data={systemOnlyRows.map((i) => {
                      const rowItem = {
                        ...i,
                        key: `${i.documentLineNumber}`,
                      }
                      return rowItem
                    })}
                    rowCalculatedCssProperties={tableRowCss}
                    rowTooltip={tableRowTooltip}
                  />
                  {/* Extracted data summary */}
                  <SectionHeader>
                    {translation.ExtractedDataSummary}
                  </SectionHeader>
                  <KeyValueTable data={extractedDataSummary} />
                  {/* System data summary */}
                  <SectionHeader>{translation.SystemDataSummary}</SectionHeader>
                  <KeyValueTable
                    data={[
                      {
                        key: translation.SystemNumberOfInvoices,
                        value: `${
                          data?.summary?.calculatedSystemInvoicesCount ?? ''
                        }`,
                      },
                      {
                        key: translation.SystemValueOfInvoices,
                        value: toCurrencyString(
                          data?.summary?.calculatedSystemInvoicesValue
                        ),
                      },
                      {
                        key: translation.SystemNumberOfCredits,
                        value: `${
                          data?.summary?.calculatedSystemCreditsCount ?? ''
                        }`,
                      },
                      {
                        key: translation.SystemValueOfCredits,
                        value: creditValueTransformer(
                          data?.summary?.calculatedSystemCreditsValue
                        ),
                      },
                      {
                        key: translation.SystemTotal,
                        value: toCurrencyString(
                          data?.summary?.calculatedSystemTotalValue
                        ),
                      },
                    ]}
                  />
                  {/* Discrepancies summary */}
                  <SectionHeader>
                    {translation.DiscrepanciesSummary}
                  </SectionHeader>
                  <KeyValueTable
                    data={[
                      {
                        key: translation.StatementTotalValueWithExcluded,
                        value: toCurrencyString(
                          data?.summary?.calculatedStatementTotalValue
                        ),
                      },
                      {
                        key: translation.UnmatchedInvoicesCount,
                        value: `${
                          data?.summary?.calculatedUnmatchedInvoicesCount ?? ''
                        }`,
                      },
                      {
                        key: translation.UnmatchedInvoicesValue,
                        value: toCurrencyString(
                          data?.summary?.calculatedUnmatchedInvoicesValue
                        ),
                      },
                      {
                        key: translation.MismatchedInvoicesCount,
                        value: `${
                          data?.summary?.calculatedMismatchedInvoicesCount ?? ''
                        }`,
                      },
                      {
                        key: translation.MismatcheddInvoicesValue,
                        value: toCurrencyString(
                          data?.summary
                            ?.calculatedMismatchedInvoicesValueDifference
                        ),
                      },
                      {
                        key: translation.UnmatchedCreditsCount,
                        value: `${
                          data?.summary?.calculatedUnmatchedCreditsCount ?? ''
                        }`,
                      },
                      {
                        key: translation.UnmatchedCreditsValue,
                        value: creditValueTransformer(
                          data?.summary?.calculatedUnmatchedCreditsValue
                        ),
                      },
                      {
                        key: translation.TotalDiscrepancy,
                        value: toCurrencyString(
                          data?.summary?.totalDiscrepancy
                        ),
                      },
                    ]}
                  />
                  {/* Notes */}
                  <SectionHeader>{translation.Note}</SectionHeader>
                  <TextField
                    multiline={true}
                    rows={5}
                    fullWidth
                    value={merNote}
                    onChange={(e) =>
                      setMerNote(e.target.value.substring(0, 250))
                    }
                    onBlur={handleNoteBlur}
                  />
                </AutoSizingBox>
              )}
              {!isInitialLoading && !error && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingTop: theme.spacing(2),
                  }}
                >
                  <LoadingButton
                    data-testid="delete-mer-button"
                    disableElevation
                    variant="contained"
                    fullWidth
                    color="error"
                    sx={{
                      maxWidth: '200px',
                    }}
                    loading={isDeleting}
                    onClick={() => {
                      setShowDeleteMer(true)
                    }}
                  >
                    {translation.Delete}
                  </LoadingButton>
                  <LoadingButton
                    data-testid="recalculate-mer-button"
                    disableElevation
                    variant="contained"
                    fullWidth
                    color="secondary"
                    sx={{
                      maxWidth: '200px',
                    }}
                    loading={isLoading}
                    onClick={() => {
                      handleRecalculate()
                    }}
                  >
                    {translation.Recalculate}
                  </LoadingButton>
                </Box>
              )}
            </Box>
          </Box>
          {odsCode && merSupplier && data && openExcludeDialog && (
            <MonthEndReconciliationExcludePopup
              merId={data.monthEndReconciliationId!}
              odsCode={odsCode}
              merDocument={openExcludeDialog.document}
              isExcludedInitialState={openExcludeDialog.isChecked}
              merSupplier={merSupplier}
              onClose={handleUpdatedExclusion}
            />
          )}
          {showDeleteMer && (
            <ConfirmDialog
              okText={translation.DeleteConfirmationOk}
              cancelText={translation.DeleteConfirmationCancel}
              title={translation.DeleteConfirmationTitle}
              text={translation.DeleteConfirmationText}
              isCancelPrimary={true}
              onOk={async () => {
                setShowDeleteMer(false)
                await handleDelete()
              }}
              onCancel={() => {
                setShowDeleteMer(false)
              }}
            ></ConfirmDialog>
          )}
        </>
      </ModalContainer>
      {openInvoice && (
        <MultiDeliveryForm
          bookInsToLoad={[
            {
              bookInId: openInvoice.split('|')[0],
              odsCode: openInvoice.split('|')[1],
            },
          ]}
          editDeliveryMode={EditDeliveryModes.MonthEndReconciliation}
          onClosed={(formState) => {
            setOpenInvoice(null)
            if (
              formState.deletedBookInIds.length > 0 ||
              formState.hasModifiedStatus
            ) {
              handleRecalculate()
            }
          }}
        />
      )}
    </>
  )
}

export default MonthEndReconciliationAnalysisForm
