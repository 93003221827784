import { FC, useEffect, useReducer, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import theme from '../../../styles/theme'
import Typography from '@mui/material/Typography'
import { locale } from '../../../locales'
import TextField from '@mui/material/TextField/TextField'
import EditableSection from './EditableSection'
import SwitchWithInfo from '../../../components/Forms/SwitchWithInfo'
import { removeEmptyDictionaryValues } from '../../../utils/Helpers'
import MultiEmailInput, {
  MultiEmailInputHandle,
} from '../../../components/Forms/MultiEmailInput'
import SupplierAccounIdMappingSection from './SupplierAccountIdMapping'
import { ValidationErrorKeys } from '../../../constants'

const translations =
  locale.translation.SettingsPage.PharmacySettingsTab.PharmacyDetailsContainer
const commonTranslations = locale.translation.Common

const errorKeys = ValidationErrorKeys.PharmacyDetails

interface PharmacyDetailsContainerState {
  emails: string[]
  phoneNumber: string
  hasInvalidEmails: boolean
  emailsInputSectionWithTagsHandle: MultiEmailInputHandle
  isBarcodeScanningMandatory: boolean
  isScanOneEnterQuantityEnabled: boolean
  supplierAccountMaps: { [key: string]: string }
  validationErrors: { [key: string]: string[] }
}

type SetEmailsAction = {
  type: 'SET_EMAILS'
  payload: string[]
}
type ResetDailyComplianceSectionAction = {
  type: 'RESET_DAILY_COMPLIANCE_SECTION'
  payload: {
    emails: string[]
    phoneNumber: string
  }
}
type SetPhoneNumberAction = {
  type: 'SET_PHONE_NUMBER'
  payload: string
}
type SetHasInvalidEmailsAction = {
  type: 'SET_HAS_INVALID_EMAILS'
  payload: boolean
}
type SetBarcodeScanningSectionAction = {
  type: 'SET_BARCODE_SCANNING_SECTION'
  payload: {
    isBarcodeScanningMandatory?: boolean
    isScanOneEnterQuantityEnabled?: boolean
  }
}
type SetSupplierAccountMapsAction = {
  type: 'SET_SUPPLIER_ACCOUNT_MAPS'
  payload: { [key: string]: string }
}

type Actions =
  | SetEmailsAction
  | ResetDailyComplianceSectionAction
  | SetPhoneNumberAction
  | SetHasInvalidEmailsAction
  | SetBarcodeScanningSectionAction
  | SetSupplierAccountMapsAction

const reducer = (state: PharmacyDetailsContainerState, action: Actions) => {
  switch (action.type) {
    case 'SET_EMAILS':
      return {
        ...state,
        emails: action.payload,
      }
    case 'RESET_DAILY_COMPLIANCE_SECTION':
      return {
        ...state,
        emails: action.payload.emails,
        phoneNumber: action.payload.phoneNumber,
        hasInvalidEmails: false,
      }
    case 'SET_PHONE_NUMBER':
      return {
        ...state,
        phoneNumber: action.payload,
      }
    case 'SET_HAS_INVALID_EMAILS':
      return {
        ...state,
        hasInvalidEmails: action.payload,
      }
    case 'SET_BARCODE_SCANNING_SECTION':
      return {
        ...state,
        isBarcodeScanningMandatory:
          action.payload.isBarcodeScanningMandatory != null
            ? action.payload.isBarcodeScanningMandatory
            : state.isBarcodeScanningMandatory,
        isScanOneEnterQuantityEnabled:
          action.payload.isScanOneEnterQuantityEnabled != null
            ? action.payload.isScanOneEnterQuantityEnabled
            : state.isScanOneEnterQuantityEnabled,
      }
    case 'SET_SUPPLIER_ACCOUNT_MAPS':
      return {
        ...state,
        supplierAccountMaps: action.payload,
      }
    default:
      return state
  }
}

const PharmacyDetailsContainer: FC<{
  authId: string
  pharmacyName: string
  odsCode: string
  emails: string[]
  phoneNumber: string
  supplierAccountMaps?: { [key: string]: string }
  clientInternalSuppliers?: { [key: string]: string }
  autoEdit?: boolean
  isBarcodeScanningEnabled: boolean
  isBarcodeScanningMandatory: boolean
  isScanOneEnterQuantityEnabled: boolean
  onUpdatedPharmacyDetails: (
    odsCode: string,
    emails: string[],
    phoneNumber: string,
    isBarcodeScanningMandatory: boolean,
    isScanOneEnterQuantityEnabled: boolean,
    supplierAccountMaps: { [key: string]: string }
  ) => void
  validationErrors?: { [key: string]: string[] } | null
}> = (props) => {
  const [state, dispatch] = useReducer(reducer, {
    emails: props.emails,
    phoneNumber: props.phoneNumber,
    hasInvalidEmails: false,
    isBarcodeScanningMandatory: props.isBarcodeScanningMandatory,
    isScanOneEnterQuantityEnabled: props.isScanOneEnterQuantityEnabled,
    supplierAccountMaps: props.supplierAccountMaps ?? {},
  } as PharmacyDetailsContainerState)

  const [isEditingComplianceEmails, setIsEditingComplianceEmails] =
    useState(false)
  const emailsInputSectionWithTagsHandle = useRef<MultiEmailInputHandle>(null)
  const [
    isEditingBarcodeScanningSettings,
    setIsEditingBarcodeScanningSettings,
  ] = useState(false)

  const [isEditingSupplierAccountIds, setIsEditingSupplierAccountIds] =
    useState(false)

  const handleErrorsChanged = (hasError: boolean) => {
    dispatch({ type: 'SET_HAS_INVALID_EMAILS', payload: hasError })
  }
  const [hasAccountIdErrors, setHasAccountIdErrors] = useState(false)
  const [validationErrors, setValidationErrors] = useState(
    props.validationErrors
  )
  useEffect(() => {
    setIsEditingSupplierAccountIds(
      Boolean(props.validationErrors?.[errorKeys.SupplierAccountMaps])
    )
    setValidationErrors(props.validationErrors)
  }, [props.validationErrors])

  const renderSupplierAccountIdsSection = () => {
    return (
      <EditableSection
        sectionTitle={translations.SupplierAccountIdsTitle}
        onUpdate={() => {
          const updatedSupplierAccountMaps = {
            ...removeEmptyDictionaryValues(state.supplierAccountMaps),
          }
          props.onUpdatedPharmacyDetails(
            props.odsCode,
            state.emails,
            state.phoneNumber ?? '',
            props.isBarcodeScanningMandatory,
            props.isScanOneEnterQuantityEnabled,
            updatedSupplierAccountMaps
          )

          dispatch({
            type: 'SET_SUPPLIER_ACCOUNT_MAPS',
            payload: updatedSupplierAccountMaps,
          })
        }}
        updateDisabled={hasAccountIdErrors}
        onCancel={() => {
          setIsEditingSupplierAccountIds(false)
          dispatch({
            type: 'SET_SUPPLIER_ACCOUNT_MAPS',
            payload: { ...props.supplierAccountMaps },
          })
          setValidationErrors(null)
        }}
        onEditingChanged={setIsEditingSupplierAccountIds}
        titleSx={{ fontWeight: 600 }}
        autoEdit={Boolean(props.autoEdit)}
        isEditing={isEditingSupplierAccountIds}
        disableAutoClose={true}
      >
        <SupplierAccounIdMappingSection
          supplierAccountMaps={state.supplierAccountMaps}
          clientInternalSuppliers={props.clientInternalSuppliers}
          onSupplierAccountMapChange={(updatedSupplierAccountMaps) => {
            dispatch({
              type: 'SET_SUPPLIER_ACCOUNT_MAPS',
              payload: updatedSupplierAccountMaps,
            })
          }}
          isEditingSupplierAccountIds={isEditingSupplierAccountIds}
          supplierIdsWithValidationErrors={
            validationErrors?.[errorKeys.SupplierAccountMaps]
          }
          onValidationChanged={setHasAccountIdErrors}
        />
      </EditableSection>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        gap: theme.spacing(2),
        padding: theme.spacing(1),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(1),
        }}
      >
        <Typography
          sx={{
            paddingY: theme.spacing(1),
            margin: 0,
            color: theme.palette.grey[700],
          }}
          variant="button"
        >
          {translations.PharmacyDetails}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: theme.spacing(2),
            marginBottom: theme.spacing(2),
          }}
        >
          <TextField
            label={translations.PharmacyNameLabel}
            value={props.pharmacyName}
            variant="outlined"
            disabled
            fullWidth
          />
          <TextField
            label={translations.OdsCodeLabel}
            value={props.odsCode}
            variant="outlined"
            disabled
            fullWidth
          />
        </Box>
        <EditableSection
          sectionTitle={translations.DailyComplianceReportSectionTitel}
          onUpdate={() => {
            const emailTags =
              emailsInputSectionWithTagsHandle.current?.getTags() ?? []
            props.onUpdatedPharmacyDetails(
              props.odsCode,
              emailTags,
              state.phoneNumber,
              props.isBarcodeScanningMandatory,
              props.isScanOneEnterQuantityEnabled,
              state.supplierAccountMaps
            )
            dispatch({ type: 'SET_EMAILS', payload: emailTags })
          }}
          updateDisabled={!isEditingComplianceEmails || state.hasInvalidEmails}
          onCancel={() => {
            dispatch({
              type: 'RESET_DAILY_COMPLIANCE_SECTION',
              payload: {
                emails: props.emails,
                phoneNumber: props.phoneNumber ?? '',
              },
            })
            emailsInputSectionWithTagsHandle.current?.clearValue()
          }}
          onEditingChanged={setIsEditingComplianceEmails}
          titleSx={{ fontWeight: 600 }}
          autoEdit={Boolean(props.autoEdit)}
          isEditing={isEditingComplianceEmails}
        >
          <MultiEmailInput
            ref={emailsInputSectionWithTagsHandle}
            emails={state.emails}
            isDisabled={!isEditingComplianceEmails}
            onErrorsChanged={handleErrorsChanged}
            label={translations.RecipientsEmailsLabel}
          />
          <TextField
            label={translations.PhoneNumberLabel}
            value={state.phoneNumber}
            variant="outlined"
            name="phoneNumber"
            fullWidth
            onChange={(e) => {
              dispatch({ type: 'SET_PHONE_NUMBER', payload: e.target.value })
            }}
            inputProps={{ inputMode: 'numeric' }}
            disabled={!isEditingComplianceEmails}
            sx={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(1) }}
          />
        </EditableSection>
        <EditableSection
          sectionTitle={translations.BarcodeScanningSectionTitle}
          onUpdate={() =>
            props.onUpdatedPharmacyDetails(
              props.odsCode,
              state.emails,
              state.phoneNumber,
              state.isBarcodeScanningMandatory,
              state.isScanOneEnterQuantityEnabled,
              state.supplierAccountMaps
            )
          }
          updateDisabled={!isEditingBarcodeScanningSettings}
          onCancel={() => {
            setIsEditingBarcodeScanningSettings(false)
            dispatch({
              type: 'SET_BARCODE_SCANNING_SECTION',
              payload: {
                isBarcodeScanningMandatory: props.isBarcodeScanningMandatory,
                isScanOneEnterQuantityEnabled:
                  props.isScanOneEnterQuantityEnabled,
              },
            })
          }}
          onEditingChanged={setIsEditingBarcodeScanningSettings}
          titleSx={{ fontWeight: 600 }}
          autoEdit={Boolean(props.autoEdit)}
          isEditing={isEditingBarcodeScanningSettings}
        >
          <SwitchWithInfo
            checked={state.isBarcodeScanningMandatory}
            onChange={() => {
              dispatch({
                type: 'SET_BARCODE_SCANNING_SECTION',
                payload: {
                  isBarcodeScanningMandatory: !state.isBarcodeScanningMandatory,
                },
              })
            }}
            testId="mandatory-barcode-scanning-toggle"
            label={translations.MandatoryBarcodeScanningToggleLabel}
            infoTooltipText={
              isEditingBarcodeScanningSettings
                ? props.isBarcodeScanningEnabled
                  ? translations.BarcodeScanningInfo
                  : commonTranslations.FeatureNotEnabled(props.pharmacyName)
                : null
            }
            updateDisabled={
              !isEditingBarcodeScanningSettings ||
              !props.isBarcodeScanningEnabled
            }
          />
          <SwitchWithInfo
            checked={state.isScanOneEnterQuantityEnabled}
            onChange={() => {
              dispatch({
                type: 'SET_BARCODE_SCANNING_SECTION',
                payload: {
                  isScanOneEnterQuantityEnabled:
                    !state.isScanOneEnterQuantityEnabled,
                },
              })
            }}
            testId="allow-scan-one-enter-quantity-toggle"
            label={translations.AllowScanOneEnterQuantityToggleLabel}
            infoTooltipText={
              isEditingBarcodeScanningSettings
                ? props.isBarcodeScanningEnabled
                  ? translations.ScanOnEnterQuantityInfo
                  : commonTranslations.FeatureNotEnabled(props.pharmacyName)
                : null
            }
            updateDisabled={
              !isEditingBarcodeScanningSettings ||
              !props.isBarcodeScanningEnabled
            }
          />
        </EditableSection>
        {renderSupplierAccountIdsSection()}
      </Box>
    </Box>
  )
}

export default PharmacyDetailsContainer
