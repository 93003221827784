import { FC, useReducer } from 'react'
import { MapDictionaryOf } from '../../utils/UtilTypes'
import Box from '@mui/material/Box'
import theme from '../../styles/theme'
import MultiSelection from '../Forms/MultiSelection'
import { locale } from '../../locales'
import { SxProps } from '@mui/material'
import { Theme } from '@mui/system'
import { useStateUpdatedCallback } from '../../hooks/useStateUpdatedCallback'

export interface ProductFilterItem {
  isConcessionary: boolean
  isControlledDrug: boolean
  brandedOrGeneric: string
  legalCategory: string
  drugTariffType?: number | null
}

const OptionDataId = {
  concessionDataId: 'concession',
  controlledDrugsDataId: 'controlled-drugs',
  brandedDataId: 'branded',
  legalCategories: 'legal-categories',
  tariffCategories: 'tariff-categories',
}

const ConcessionFilterOptionsMap: MapDictionaryOf<boolean> = {
  PRODUCTS_WITH_A_CONCESSION: true,
  PRODUCTS_WITH_NO_CONCESSION: false,
}

const ControlledDrugsFilterOptionsMap: MapDictionaryOf<boolean> = {
  CD: true,
  NON_CD: false,
}

const BrandedFilterOptionsMap: MapDictionaryOf<string> = {
  BRANDED: 'Branded',
  GENERICS: 'Generics',
}

const LegalCategoriesFilterOptionsMap: MapDictionaryOf<string> = {
  POM: 'POM',
  APPLIANCES: 'Appliances',
  OTHER: 'Other',
  P_LINES: 'P Lines',
  OTC: 'OTC',
}

const TariffCategoriesFilterOptionsMap: MapDictionaryOf<number | null> = {
  NO_TARIFF: null,
  CATEGORY_1: 1,
  CATEGORY_2: 2,
  CATEGORY_3: 3,
  CATEGORY_4: 4,
  CATEGORY_5: 5,
  CATEGORY_6: 6,
  CATEGORY_7: 7,
  CATEGORY_8: 8,
  CATEGORY_9: 9,
  CATEGORY_10: 10,
  CATEGORY_11: 11,
  CATEGORY_12: 12,
  CATEGORY_13: 13,
  CATEGORY_14: 14,
}

type SetSelectedConcessionaryStatesAction = {
  type: 'setSelectedConcessionaryStates'
  payload: string[]
}

type SetSelectedControlledDrugsStatesAction = {
  type: 'setSelectedControlledDrugsStates'
  payload: string[]
}

type SetSelectedGenericsStatesAction = {
  type: 'setSelectedGenericsStates'
  payload: string[]
}

type SetSelectedLegalCategoriesStatesAction = {
  type: 'setSelectedLegalCategoriesStates'
  payload: string[]
}

type SetSelectedTariffCategoriesStatesAction = {
  type: 'setSelectedTariffCategoriesStates'
  payload: string[]
}

type ProductFilterBarAction =
  | SetSelectedConcessionaryStatesAction
  | SetSelectedControlledDrugsStatesAction
  | SetSelectedGenericsStatesAction
  | SetSelectedLegalCategoriesStatesAction
  | SetSelectedTariffCategoriesStatesAction

export interface ProductFilterBarState {
  selectedConcessionaryStates: string[]
  selectedControlledDrugsStates: string[]
  selectedGenericsStates: string[]
  selectedLegalCategoriesStates: string[]
  selectedTariffCategoriesStates: string[]
}

export const InitialProductFilterBarState: ProductFilterBarState = {
  selectedConcessionaryStates: Object.keys(ConcessionFilterOptionsMap),
  selectedControlledDrugsStates: Object.keys(ControlledDrugsFilterOptionsMap),
  selectedGenericsStates: Object.keys(BrandedFilterOptionsMap),
  selectedLegalCategoriesStates: Object.keys(LegalCategoriesFilterOptionsMap),
  selectedTariffCategoriesStates: Object.keys(TariffCategoriesFilterOptionsMap),
}

export const productFilterBarFilter = (
  state: ProductFilterBarState,
  inputItems: ProductFilterItem[]
) => {
  const filterItems = (
    items: ProductFilterItem[],
    getterProperty: (
      item: ProductFilterItem
    ) => ProductFilterItem[keyof ProductFilterItem],
    filterState: string[],
    filterMaps: MapDictionaryOf<string | boolean | number | null>
  ) => {
    const validValues = filterState.map((s) => filterMaps[s])
    return items.filter((i) =>
      validValues.includes(
        getterProperty(i) as keyof MapDictionaryOf<string | boolean | number>
      )
    )
  }
  let items = inputItems
  items = filterItems(
    items,
    (i) => i.isConcessionary,
    state.selectedConcessionaryStates,
    ConcessionFilterOptionsMap
  )
  items = filterItems(
    items,
    (i) => i.isControlledDrug,
    state.selectedControlledDrugsStates,
    ControlledDrugsFilterOptionsMap
  )
  items = filterItems(
    items,
    (i) => i.brandedOrGeneric,
    state.selectedGenericsStates,
    BrandedFilterOptionsMap
  )
  items = filterItems(
    items,
    (i) => i.legalCategory,
    state.selectedLegalCategoriesStates,
    LegalCategoriesFilterOptionsMap
  )
  items = filterItems(
    items,
    (i) => i.drugTariffType ?? null,
    state.selectedTariffCategoriesStates,
    TariffCategoriesFilterOptionsMap
  )
  return items
}

export const productFilterBarReducer = (
  state: ProductFilterBarState,
  action: ProductFilterBarAction
) => {
  switch (action.type) {
    case 'setSelectedConcessionaryStates':
      state = { ...state, selectedConcessionaryStates: action.payload }
      break
    case 'setSelectedControlledDrugsStates':
      state = { ...state, selectedControlledDrugsStates: action.payload }
      break
    case 'setSelectedGenericsStates':
      state = { ...state, selectedGenericsStates: action.payload }
      break
    case 'setSelectedLegalCategoriesStates':
      state = { ...state, selectedLegalCategoriesStates: action.payload }
      break
    case 'setSelectedTariffCategoriesStates':
      state = { ...state, selectedTariffCategoriesStates: action.payload }
      break
  }
  return state
}

const translation = locale.translation.AnalyticsPage.TabCommercials

const multiSelectSx: SxProps<Theme> = {
  flex: 1,
  minHeight: '6.6ch',
}

const ProductFilterBar: FC<{
  state: ProductFilterBarState
  hideConcessionaryStates?: boolean
  onProductFilterBarStateChange: (state: ProductFilterBarState) => void
}> = ({
  onProductFilterBarStateChange,
  hideConcessionaryStates,
  state: initialState,
}) => {
  const [state, dispatch] = useReducer(productFilterBarReducer, initialState)

  useStateUpdatedCallback(state, onProductFilterBarStateChange)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
      }}
    >
      {!hideConcessionaryStates && (
        <MultiSelection
          id={OptionDataId.concessionDataId}
          onSelectionApplied={(s) => {
            dispatch({ type: 'setSelectedConcessionaryStates', payload: s })
          }}
          options={translation.ConcessionOptions}
          selectedOptionKeys={state.selectedConcessionaryStates}
          title={
            state.selectedConcessionaryStates.length === 1
              ? translation.ConcessionsTitles[
                  state.selectedConcessionaryStates[0]
                ]
              : translation.ConcessionsTitles.ALL
          }
          isDirty={
            state.selectedConcessionaryStates.length !==
            Object.entries(translation.ConcessionOptions).length
          }
          sx={multiSelectSx}
        />
      )}

      <MultiSelection
        id={OptionDataId.controlledDrugsDataId}
        onSelectionApplied={(s) => {
          dispatch({ type: 'setSelectedControlledDrugsStates', payload: s })
        }}
        options={translation.CDOptions}
        selectedOptionKeys={state.selectedControlledDrugsStates}
        title={
          state.selectedControlledDrugsStates.length === 1
            ? translation.CDTitles[state.selectedControlledDrugsStates[0]]
            : translation.CDTitles.ALL
        }
        isDirty={
          state.selectedControlledDrugsStates.length !==
          Object.entries(translation.CDOptions).length
        }
        sx={multiSelectSx}
      />

      <MultiSelection
        id={OptionDataId.brandedDataId}
        onSelectionApplied={(s) => {
          dispatch({ type: 'setSelectedGenericsStates', payload: s })
        }}
        options={translation.BrandedOptions}
        selectedOptionKeys={state.selectedGenericsStates}
        title={
          state.selectedGenericsStates.length === 1
            ? translation.BrandedTitles[state.selectedGenericsStates[0]]
            : translation.BrandedTitles.ALL
        }
        isDirty={
          state.selectedGenericsStates.length !==
          Object.entries(translation.BrandedOptions).length
        }
        sx={multiSelectSx}
      />

      <MultiSelection
        id={OptionDataId.legalCategories}
        onSelectionApplied={(s) => {
          dispatch({ type: 'setSelectedLegalCategoriesStates', payload: s })
        }}
        options={translation.LegalCategoriesOptions}
        selectedOptionKeys={state.selectedLegalCategoriesStates}
        title={
          state.selectedLegalCategoriesStates.length === 1
            ? translation.LegalCategoriesTitles[
                state.selectedLegalCategoriesStates[0]
              ]
            : state.selectedLegalCategoriesStates.length ===
              Object.entries(translation.LegalCategoriesOptions).length
            ? translation.LegalCategoriesTitles.ALL
            : translation.LegalCategoriesTitles.MULTIPLE
        }
        isDirty={
          state.selectedLegalCategoriesStates.length !==
          Object.entries(translation.LegalCategoriesOptions).length
        }
        sx={multiSelectSx}
      />

      <MultiSelection
        id={OptionDataId.tariffCategories}
        onSelectionApplied={(s) => {
          dispatch({
            type: 'setSelectedTariffCategoriesStates',
            payload: s,
          })
        }}
        options={translation.TariffCategoriesOptions}
        selectedOptionKeys={state.selectedTariffCategoriesStates}
        title={
          state.selectedTariffCategoriesStates.length === 1
            ? translation.TariffCategoriesTitles[
                state.selectedTariffCategoriesStates[0]
              ]
            : state.selectedTariffCategoriesStates.length ===
              Object.entries(translation.TariffCategoriesOptions).length
            ? translation.TariffCategoriesTitles.ALL
            : translation.TariffCategoriesTitles.MULTIPLE
        }
        isDirty={
          state.selectedTariffCategoriesStates.length !==
          Object.entries(translation.TariffCategoriesOptions).length
        }
        sx={multiSelectSx}
      />
    </Box>
  )
}

export default ProductFilterBar
