import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Zoom from '@mui/material/Zoom'
import Markdown from 'marked-react'
import { FC, PropsWithChildren, useState } from 'react'

export interface ConfirmDialogAdditionalButton {
  label: string
  onClick: () => void
}

interface ConfirmDialogProps extends PropsWithChildren {
  title: string
  text?: string
  okText?: string
  cancelText: string
  onOk?: () => void
  onOkWithLoading?: () => void
  onCancel: () => void
  isCancelPrimary?: boolean
  isLoading?: boolean
  canCancel?: boolean
  disableBackdropClick?: boolean
}

const ConfirmDialog: FC<ConfirmDialogProps> = ({
  title,
  text,
  okText,
  cancelText,
  onOk,
  onOkWithLoading,
  onCancel,
  isCancelPrimary,
  isLoading,
  canCancel = true,
  children,
  disableBackdropClick = false,
}) => {
  const [open, setOpen] = useState<boolean>(true)

  return (
    <Dialog
      data-testid="confirm-dialog"
      open={open}
      onClose={() => {
        if (disableBackdropClick) return
        setOpen(false)
        onCancel()
      }}
      TransitionComponent={Zoom}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {text && (
          <DialogContentText component={'section'}>
            <Markdown value={text} />
          </DialogContentText>
        )}
        {children}
      </DialogContent>
      <DialogActions>
        {(onOk || onOkWithLoading) && okText && (
          <LoadingButton
            data-testid="confirm-dialog-ok-button"
            disableElevation={true}
            variant="contained"
            color={isCancelPrimary ? 'error' : 'primary'}
            loading={isLoading}
            onClick={() => {
              if (!onOkWithLoading) {
                setOpen(false)
                onOk?.()
              } else {
                onOkWithLoading?.()
              }
            }}
          >
            {okText}
          </LoadingButton>
        )}
        <Button
          data-testid="confirm-dialog-cancel-button"
          disableElevation={true}
          disabled={!canCancel}
          autoFocus
          variant="contained"
          color={isCancelPrimary ? 'primary' : 'error'}
          onClick={() => {
            setOpen(false)
            onCancel()
          }}
        >
          {cancelText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
