import { useCallback, useEffect, useRef } from 'react'
import { SetterOrUpdater } from 'recoil'
import { useGlobalIsLoading } from './useIsLoading'

export const useAbortableRequest = (
  recoilSetLoadingAbortedState?: SetterOrUpdater<boolean>
) => {
  const { setIsLoading } = useGlobalIsLoading()
  const abortControllerRef = useRef<AbortController | null>(null)
  const hasActiveRequestRef = useRef(false)
  const startAbortableRequest = useCallback(() => {
    hasActiveRequestRef.current = true
    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
    }
    setIsLoading(true)
    abortControllerRef.current = new AbortController()
  }, [setIsLoading])

  const finishAbortableRequest = useCallback(
    (wasCancelled: boolean) => {
      abortControllerRef.current = null
      hasActiveRequestRef.current = false
      setIsLoading(false)
      if (!wasCancelled) {
        recoilSetLoadingAbortedState?.(false)
      }
      return wasCancelled
    },
    [recoilSetLoadingAbortedState, setIsLoading]
  )

  useEffect(() => {
    return () => {
      if (hasActiveRequestRef.current) {
        recoilSetLoadingAbortedState?.(true)
        abortControllerRef.current?.abort()
      }
    }
  }, [abortControllerRef, recoilSetLoadingAbortedState])

  return { abortControllerRef, startAbortableRequest, finishAbortableRequest }
}
