import { DateTime } from 'luxon/src/datetime'
import { toCurrencyString } from '../utils/Helpers'

export const enGb = {
  Root: {
    WarningScreenTooSmall:
      "We have detected that this device's screen width is too small. This is app designed for use on a computer or a tablet.",
  },
  NotFoundPage: {
    Text: 'Oops! The page you are looking for does not exist or an error occured!',
    Please: 'Please',
    Here: ' click here ',
    ToGoBack: 'to go back to the home page.',
  },
  Common: {
    NoData: 'No records found',
    Errors: {
      ErrorExecutingRequest: (statusCode?: number) =>
        `Unfortunately, there has been an error while executing the request. Please make sure your internet connection is working and try again. [HTTP status code: ${
          statusCode ?? 'NO_RESPONSE'
        }]`,
      AuthorisationError:
        'Unfortunately, you are not authorised to execute this action. Please contact your administrator.',
    },
    Specials: 'Specials',
    Pharmacy: 'Pharmacy',
    Any: 'Any',
    FeatureNotEnabled: (clientName: string) =>
      `This feature is not currently enabled for ${clientName}. Please contact StockRx for further information.`,
    FeatureAvailableToPharmaciesOnly:
      'This feature is only available to pharmacies. Please select a pharmacy from the dropdown to access this feature.',
    FeatureAvailableToOrganisationsOnly:
      'This feature is only available to parent organisations. Please select a parent organisation from the dropdown to access this feature.',
    NoPermissions:
      'You do not have the required permissions to access this page. Please contact your administrator.',
    ErrorEmailRequired: 'One or more email addresses are invalid.',
    ErrorDuplicateEmail:
      'One or more duplicated email addresses were detected.',
  },
  Navigation: {
    SectionsPharmaciesSelect: 'Selected pharmacy',
    Links: {
      Analytics: 'Analytics',
      GoodsIn: 'Goods-in',
      MonthEndReconciliation: 'Month-end reconciliation',
      Credits: 'Credits for action',
      Settings: 'Settings',
      ChatWithUs: 'Chat with us',
      HelpCentre: 'Branch Training',
      ManagementTraining: 'Head Office Training',
      StockTracking: 'Stock tracking',
      PriceFiles: 'Price files',
    },
    Logout: 'Log out',
  },
  UserPopover: {
    Hello: (fullName: string) => `Hi ${fullName}`,
    AccountSettings: 'Account settings',
    AdminPharmacySettings: 'Settings',
  },
  ClientSelect: {
    Company: 'Parent Organisation',
    PharmacyGroup: 'Clusters',
    Pharmacy: 'Pharmacies',
  },
  SettingsPage: {
    Title: 'Settings',
    EditableSection: {
      Update: 'Apply',
      Cancel: 'Cancel',
    },
    PermissionsList: {
      Manage: 'Manage settings',
      Stock: 'Goods-in and stock track',
      // Dispenses: 'Access dispensing uploads',
      Commercials: 'Analytics',
      BHS: 'Book-in healthscore',
      MER: 'Month-end reconciliation',
      // Forecasts: 'Access forecasts',
      SelectAll: 'Select all',
    },
    UserManagementTab: {
      Title: 'User management',
      UserManagement: {
        MoreMenu: {
          AddUser: 'Add user',
          RemoveUser: 'Remove user',
          AssignAllPermissions: 'Assign all permissions',
          ForceChangePassword: 'Reset 1st sign in password',
          AddTitle: 'Add a new user',
        },
        RemoveUserDialog: {
          Title: 'Confirm user removal',
          Text: (fullName: string, userName: string, companyName: string) =>
            `Please confirm that you would like to remove the user **${fullName} [${userName}]** from **${companyName}** and all its pharmacies and clusters**.`,
          Yes: 'Yes',
          Cancel: 'Cancel',
        },
        AssignAllPermissionsDialog: {
          Title: 'Confirm permissions assignment',
          Yes: 'Yes',
          Cancel: 'Cancel',
          Text: (fullName: string, userName: string) =>
            `Please confirm that you would like to assign all permissions to the user **${fullName} [${userName}]**.  
            \\
            The user will be allowed to access all parts of the system as well as manage other users.`,
        },
        AssignGivenPermissionsDialog: {
          Title: 'Select permission propagation',
          Yes: 'Apply to selection only',
          Cancel: 'Cancel',
          Text: `Would you like to copy these permissions to **all child clusters/pharmacies** contained in this group, or apply them only to the **current selection**?`,
          Propagate: 'Copy to children & apply',
        },
        UserDetailsContainer: {
          CompanyPermissions: 'Parent organisation permissions',
          PharmacyGroupPermissions: 'Cluster permissions',
          PharmacyPermissions: 'Pharmacy permissions',
        },
        UserDetails: {
          UserDetails: 'User details',
          Email: 'Email',
          FullName: 'Full name',
          ErrorEmailRequired: 'Please enter a valid email address.',
          ErrorFullNameRequired: "Please enter the user's full name.",
          ErrorEmailNotUnique: 'A user with this email already exists.',
        },
        ResetPasswordError:
          "Password could not be reset. Please use the Forgot Password link, in the log-in page to reset the user's password.",
      },
    },
    CompanyManagementTab: {
      Title: 'Company settings',
      CompanyDetailsContainer: {
        CompanyDetails: 'Company Details',
        CompanyNameLabel: 'Company Name',
        CompanyIdLabel: 'Company Id',
        ProblemProductsReportSectionTitle: 'Weekly Problem Products Reports',
        ProblemProductsSettingsTitle: 'Problem Products Settings',
        ProblemProductsRecipientsEmailsLabel: 'Recipient emails',
        RollConcessionsOverToggleLabel: 'Roll concessions over',
        RollConcessionsOverToggleInfo:
          'Use this toggle to pull forward any concession prices from the previous month to the current month',
        SupplierNetPriceToggleLabel: 'Use supplier net prices',
        SupplierNetPriceToggleInfo:
          'Use this toggle to recalculate using supplier net prices (from uploaded price files)',
        TradePriceToggleLabel: 'Use trade price',
        TradePriceToggleInfo:
          'Use this toggle to have branded product margin calculated using Trade instead of Tariff/Concession pricing',
        TotalProfitableItemsAmountLabel: 'Most and Least Profitable Items',
        TotalProfitableItemsAmountInfo:
          'Set how many items to include in the Most Profitable and Least Profitable Items reports',
        MostExpensiveItemsAmountLabel: 'Most Expensive Items',
        MostExpensiveItemsAmountInfo: 'Set how many items to include in the "most expensive items"',
        ThresholdProfitableItemsNumberLabel:
          'Profit/Loss Threshold (GBP)',
        ThresholdProfitableItemsNumberInfo:
          'Set the GBP amount that defines "most profitable" (Gross Profit ≥ threshold) and "least profitable" (Loss ≥ threshold) items',
      },
    },
    GroupsManagementTab: {
      Title: 'Cluster settings',
      GroupsManagement: {
        MoreMenu: {
          AddGroup: 'Add cluster',
          RemoveGroup: 'Remove cluster',
          AddTitle: 'Add a new cluster',
        },
        Pharmacy: 'pharmacy',
        Pharmacies: 'pharmacies',
        GroupDetailsContainer: {
          AvailablePharmacies: (groupName: string) =>
            `Available pharmacies to add to ${groupName}`,
          PharmaciesInGroup: (groupName: string) =>
            `Pharmacies in ${groupName}`,
          Update: 'Update',
          Cancel: 'Cancel',
          GroupName: 'Cluster name',
          GroupPharmacies: 'Cluster pharmacies',
          ErrorGroupName: 'Please enter a name for the cluster.',
          ErrorGroupNameNotUnique:
            'A cluster with the same name already exists.',
          DailyComplianceReportSectionTitel: 'Daily Compliance reports',
          RecipientsEmailsLabel: 'Recipient emails',
        },
        RemoveGroupDialog: {
          Title: 'Confirm cluster removal',
          Text: (groupName: string, companyName: string) =>
            `Please confirm that you would like to remove the **${groupName}** cluster from **${companyName}**.`,
          Yes: 'Yes',
          Cancel: 'Cancel',
        },
      },
    },
    PharmacySettingsTab: {
      Title: 'Pharmacy settings',
      PharmacyDetailsContainer: {
        ErrorPhoneNumberRequired: 'Please enter a valid phone number.',
        PharmacyDetails: 'Pharmacy details',
        PharmacyNameLabel: 'Pharmacy Name',
        OdsCodeLabel: 'ODS code',
        DailyComplianceReportSectionTitel: 'Daily Compliance reports',
        RecipientsEmailsLabel: 'Recipient emails',
        PhoneNumberLabel: 'Pharmacy phone number',
        MandatoryBarcodeScanningToggleLabel: 'Mandatory barcode scanning',
        AllowScanOneEnterQuantityToggleLabel:
          'Allow scan one and enter quantity when scanning',
        BarcodeScanningSectionTitle: 'Barcode scanning',
        BarcodeScanningInfo:
          'Use this toggle to make barcode scanning mandatory on mobile devices',
        ScanOnEnterQuantityInfo:
          'Use this toggle to allow scanning one product and then entering quantity manually (hides the expected invoice quantity while scanning)',
        SupplierAccountIdsTitle: 'Supplier account IDs',
      },
      SupplierAccountMappingContainer: {
        Supplier: 'Supplier',
        AccountId: 'Account ID',
        SelectSupplier: 'Select supplier',
        AccountIdNotUnique: 'Account ID must be unique',
        AccountIdEmptyError: 'Account ID cannot be empty',
      },
    },
  },
  AnalyticsPage: {
    Title: 'Analytics',
    Tabs: {
      Commercials: 'Commercials',
    },
    TabCommercials: {
      ConcessionOptions: {
        PRODUCTS_WITH_A_CONCESSION: 'Concessionary products',
        PRODUCTS_WITH_NO_CONCESSION: 'Non-Concessionary products',
      },
      ConcessionsTitles: {
        ALL: 'Concessionary and non-concessionary products',
        PRODUCTS_WITH_A_CONCESSION: 'Concessionary products only',
        PRODUCTS_WITH_NO_CONCESSION: 'Non-Concessionary products only',
      },

      CDOptions: {
        CD: 'Controlled drugs',
        NON_CD: 'Non controlled drugs',
      },
      CDTitles: {
        ALL: 'Controlled and non-controlled drugs',
        CD: 'Controlled drugs only',
        NON_CD: 'Non-controlled drugs only',
      },

      BrandedOptions: {
        BRANDED: 'Branded products',
        GENERICS: 'Generics',
      },
      BrandedTitles: {
        ALL: 'Branded and generic',
        BRANDED: 'Branded products only',
        GENERICS: 'Generics only',
      },

      LegalCategoriesOptions: {
        POM: 'POM',
        APPLIANCES: 'Appliances',
        OTHER: 'Other',
        P_LINES: 'P Lines',
        OTC: 'OTC',
      },
      LegalCategoriesTitles: {
        ALL: 'All legal categories',
        MULTIPLE: 'Legal categories: Multiple selections',
        POM: 'Legal categories: POM only',
        APPLIANCES: 'Legal categories: Appliances only',
        OTHER: 'Legal categories: Other only',
        P_LINES: 'Legal categories: P Lines only',
        OTC: 'Legal categories: OTC only',
      },
      TariffCategoriesOptions: {
        NO_TARIFF: 'No Tariff',
        CATEGORY_1: 'Category A (Part VIIIA)',
        CATEGORY_2: 'Category B (Part VII)',
        CATEGORY_3: 'Category C (Part VIIIA)',
        CATEGORY_4: 'Category E (Part VII)',
        CATEGORY_5: 'Part IXa',
        CATEGORY_6: 'Part IXb',
        CATEGORY_7: 'Part IXc',
        CATEGORY_8: 'Part IXr',
        CATEGORY_9: 'Part X',
        CATEGORY_10: 'Parts IXb & IXc',
        CATEGORY_11: 'Category M (Part VIIIA)',
        CATEGORY_12: 'Part VIIIB',
        CATEGORY_13: 'Category 13',
        CATEGORY_14: 'Category 14',
      },
      TariffCategoriesTitles: {
        ALL: 'All tariff categories',
        MULTIPLE: 'Tariff categories: Multiple selections',
        NO_TARIFF: 'Tariff categories: No Tariff',
        CATEGORY_1: 'Tariff Category A (Part VIIIA) only',
        CATEGORY_2: 'Tariff Category B (Part VII) only',
        CATEGORY_3: 'Tariff Category C (Part VIIIA) only',
        CATEGORY_4: 'Tariff Category E (Part VII) only',
        CATEGORY_5: 'Tariff Part IXa only',
        CATEGORY_6: 'Tariff Part IXb only',
        CATEGORY_7: 'Tariff Part IXc only',
        CATEGORY_8: 'Tariff Part IXr only',
        CATEGORY_9: 'Tariff Part X only',
        CATEGORY_10: 'Tariff Parts IXb & IXc only',
        CATEGORY_11: 'Tariff Category M (Part VIIIA) only',
        CATEGORY_12: 'Tariff Part VIIIB only',
        CATEGORY_13: 'Tariff Category 13 only',
        CATEGORY_14: 'Tariff Category 14 only',
      },

      PreviousConcessionPriceToggle: 'Roll concessions over',
      GroupByGenericToggle: 'Group by generic',
      UseSupplierNetPricesToggle: 'Use supplier net prices',
      UseTradePriceToggle: 'Use trade price',
      ConcessionaryProducts: 'Concessions',
      ControlledDrugsProducts: 'CD or non-CD',
      BrandedProducts: 'Brand or Generic',
      MultipleBrandedGenerics: 'Multiple Brand/Generics',
      LegalCategoriesProducts: 'Legal Category',
      MultipleLegalCategories: 'Multiple Legal Categories',
      SearchPlaceholder: 'Search for a product',

      TableColumnTitles: [
        'Product name',
        'Pack size',
        'Average cost price',
        'Gross profit',
        'Average reimbursement',
        'Margin',
        'Cheapest supplier',
        'Total volume',
        'Spend (net)',
        'Number of AMPs',
      ],
      InfoUseTradePriceToggle:
        'Use this toggle to have branded product margin calculated using Trade instead of Tariff/Concession pricing',
      InfoGroupingToggle:
        'Use this toggle to group branded and generic medicines together',
      InfoUseSupplierNetPricesToggle:
        'Use this toggle to recalculate using supplier net prices (from uploaded price files)',
      InfoRollConcessionsOverToggle:
        'Use this toggle to pull forward any concession prices from the previous month to the current month',
      InfoAverageCostPrice: 'The average price per unit you have paid.',
      InfoGrossProfit:
        'Difference between Average Cost Price and Average Reimbursement',
      InfoAverageRebate:
        'The average daily NHS reimbursement per unit, across the selected time period. Accounts for Tariff (inc. discount deduction), Trade, and Concession pricing.',
      InfoRebateMargin:
        '% difference between Average Cost Price and Average Reimbursement.',
      Supplier: 'Supplier',
      AllSuppliers: 'All suppliers',
      AllProducts: 'All products',
      Total: 'Total spent (net):',
      TotalVolumeForProducts: 'Total volume:',
      AverageRebateMargin: 'Average margin:',
      SupplierDistribution: 'Supplier distribution',
      TotalVolume: 'Total volume',
      TotalSpend: 'Total spend',
      ConcessionProducts: 'Show only concessionary products',
      CommercialsProductDetailsTabs: {
        ProductHeader: (productName: string, packSize: number | string) =>
          `${productName} (pack size: ${packSize})`,
        Tabs: {
          InvoiceDetails: 'Invoice details',
          PricingDetails: 'Pricing details',
          SupplierDistribution: 'Supplier distribution',
        },
        PreviousConcessionPriceToggle: 'Roll concessions over',
        GenericGroup: 'Generic group',
      },
      CommercialsProductInvoiceDetails: {
        ColumnsOdsCode: 'Pharmacy',
        ColumnsInvoiceNumber: 'Invoice number',
        ColumnsInvoiceDate: 'Invoice date',
        ColumnsProductName: 'Product',
        ColumnsSupplier: 'Supplier',
        ColumnsQuantity: 'Quantity',
        ColumnsPricePerUnit: 'Invoice price per unit',
        ColumnSupplierPrice: 'Supplier price per unit',
        ColumnsTotalPrice: 'Invoice net value',
        ColumnsTariff: 'Tariff',
        ColumnsConcession: 'Concession',
        ColumnsIndicative: 'Trade',
        InfoTariff: 'Drug Tariff on this day',
        InfoConcession: 'Concession price on this day',
        InfoIndicative: 'Trade price on this day',
      },
      CommercialsProductPriceDetails: {
        LabelTariff: 'Tariff',
        LabelConcession: 'Concession',
        LabelIndicative: 'Trade',
        LabelIndicativeAverage: 'Trade Price (Avg.)',
        LabelPricePerUnit: 'Price per unit',
        Hide: 'Hide',
        InfoIndicativePriceIsAverage:
          'This product has multiple active Trade prices; here we show the average of those prices.',
      },
      RebateNotForEveryDay:
        'This figure excludes dates when neither a tariff nor a concession price was available',
      ExportToCSVModal: {
        AnalyticsCSVExport: 'Analytics CSV export',
        DetailedCSVExport: 'Detailed CSV export',
        AnalyticsTitle: 'Export products in view to CSV',
        DetailedTitle: 'Export detailed product data to CSV',

        AnalyticsText: (
          dateFrom: DateTime | null,
          dateTo: DateTime | null,
          supplierText: string,
          numberOfProducts: number
        ) =>
          `This will download a total of **${numberOfProducts}** items ${
            dateFrom === dateTo && dateFrom != null
              ? `from **${dateFrom}**`
              : `from **${dateFrom ?? 'the first recorded date'}** to **${
                  dateTo ?? 'the last recorded date'
                }**`
          }, for **${supplierText}**.`,
        DetailedExportText: (
          dateFrom: DateTime | null,
          dateTo: DateTime | null,
          tradePrice: boolean,
          rollConcessionsOver: boolean,
          supplierNetPrices: boolean,
          supplierText: string
        ) =>
          `This will download a detailed report from  ${
            dateFrom === dateTo && dateFrom != null
              ? `from **${dateFrom}**`
              : `from **${dateFrom ?? 'the first recorded date'}** to **${
                  dateTo ?? 'the last recorded date'
                }**`
          }, for  items **${
            tradePrice ? 'with' : 'without'
          }** trade prices, **${
            rollConcessionsOver ? 'with' : 'without'
          }** concessions rolled over, **${
            supplierNetPrices ? 'with' : 'without'
          }** supplier net prices, for **${supplierText}**.`,
        ConfirmText: 'Export',
        CancelText: 'Cancel',
        AllSuppliersText: 'all the suppliers',
        MultipleSuppliersText: 'multiple suppliers',
      },
    },
  },
  GoodsInPage: {
    Tabs: {
      Pending: 'New and pending deliveries',
      History: 'Delivery History',
      CreditsForAction: 'Credits for action',
      BookInHealthscore: 'Invoice Healthscore',
    },
    Common: {
      AnyProduct: 'Any product',
      Clear: 'Clear',
    },
    TabNewAndPendingDeliveries: {
      CreateNewDelivery: 'Start a new delivery',
      HideNewDelivery: 'Hide new delivery',
      SelectPharmacyToStartANewDelivery:
        'Please select a **single pharmacy site** from the drop-down menu top-right if you wish to start a new delivery. Invoices may only be uploaded for one pharmacy at a time.',
      Columns: [
        'Supplier',
        'Invoice date',
        'Invoice number',
        'Total ex. VAT',
        'Pharmacy',
        'Date received',
      ],
      NoRecords: 'No deliveries found',
      InfoDateReceived: 'The date on which the invoice was loaded into StockRx',
      DuplicatedPending:
        'Another delivery with the same invoice number from the same supplier already exists in your list of new and pending deliveries.',
      DuplicatedCompleted:
        'Completing this delivery is not possible: a delivery with the same invoice number from the same supplier already exists in your delivery history.',
      OldInvoice:
        'This delivery was created more than 24 hours ago but has not yet been completed.',
      BookInMultipleInvoiceButton: (numberOfInvoices: number) =>
        `Book in ${numberOfInvoices} selected deliveries`,
      BookInMultipleInvoiceButtonSameSupplierMessage:
        'Only single supplier selection allowed',
      ClearSelectionsButton: 'Clear selections',
    },
    TabDeliveryHistory: {
      Columns: [
        'Supplier',
        'Invoice date',
        'Invoice number',
        'Number of products',
        'Number of adjustments',
        'Total ex. VAT',
        'Pharmacy',
        'Date received',
      ],
      ReEdit:
        'This delivery has been edited after completion, but not been finalised.',
      NoRecords: 'No deliveries found',
      InfoDateReceived: 'The date on which the invoice was loaded into StockRx',
      ReEditsToggle: 'Non-finalised deliveries only',
      ReEditsToggleTooltip:
        'Show only deliveries that have been edited but not completed.',
    },
    TabCreditsForAction: {
      Title: 'Credits for action',
      SearchPlaceholder: 'Search for a product or invoice number',
      TableColumnTitles: [
        'Last updated',
        'Invoice date',
        'Invoice number',
        'Supplier',
        'Credit reason',
        'Product code',
        'Product name',
        'Pack size',
        'Price per unit',
        'Credit packs',
        'Total credit value',
        'Actioned',
        'Pharmacy',
        'Received',
        'Rejected',
        'Notes',
      ],
      CreditForActionForm: {
        ProductName: 'Product name: ',
        PackSize: 'Pack size: ',
        LabelAdjustmentDate: 'Record last updated: ',
        LabelInvoiceDate: 'Invoice date: ',
        LabelInvoiceNumber: 'Invoice number: ',
        LabelSupplier: 'Supplier: ',
        LabelProductCode: 'Product code: ',
        LabelCreditReason: 'Credit reason: ',
        LabelCreditPacks: 'Credit packs: ',
        LabelPricePerUnit: 'Price per unit: ',
        LabelTotalCredit: 'Total credit value: ',
        LabelActionedBasedOnStatus: 'Actioned ',
        LabelReceived: 'Received ',
        LabelRejected: 'Rejected ',
        LabelNotes: 'Enter any notes ',
        TitleModal: 'Edit credit for action',
        SaveButton: 'Save',
        Notifications: {
          ErrorAlertIfStatusNotValid:
            'Status that you want to set is not valid',
          ErrorAlertUserEnrichesMaximumCharacters:
            'Notes cannot be longer than 500 characters',
          NotificationCreditUpdatedsuccessfully: 'Credit updated successfully',
        },
      },
      CreditsExportModal: {
        Title: 'Export credits in view to CSV',
        Text: (
          dateFrom: DateTime | null,
          dateTo: DateTime | null,
          supplierText: string,
          numberOfCredits: number
        ) =>
          `This will download a total of **${numberOfCredits}** credits ${
            dateFrom === dateTo && dateFrom != null
              ? `from **${dateFrom}**`
              : `from **${dateFrom ?? 'the first recorded date'}** to **${
                  dateTo ?? 'the last recorded date'
                }**`
          }, for **${supplierText}**.`,
        ConfirmText: 'Export',
        CancelText: 'Cancel',
        AllSuppliersText: 'all the suppliers',
        MultipleSuppliersText: 'multiple suppliers',
      },
      CreditsFilter: {
        MultiSelection: 'Multiple selections',
        All: 'All credits',
        ActionedOptions: {
          UNACTIONED: 'Unactioned credits',
          ACTIONED: 'Actioned credits',
          RECEIVED: 'Received credits',
          REJECTED: 'Rejected credits',
        },
      },
    },
    TabBookInHealthscore: {
      Columns: [
        'Supplier',
        'Invoice date',
        'Invoice number',
        'Number of products',
        'Number of adjustments',
        'Total ex. VAT',
        'Healthscore',
        'Reviewed on',
        'Pharmacy',
        'Date received',
      ],
      ReviewedByManager: 'This delivery has been reviewed',
      NoRecords: 'No invoices found',
      ReEdit: 'This invoice has an non-finalised review.',
      InfoDateReceived: 'The date on which the invoice was loaded into StockRx',
      ShowPerfectScoreInvoicesToggle: 'Show invoices with perfect score',
      ShowReviewedInvoicesToggle: 'Show reviewed invoices',
    },
    UploadInvoice: {
      DropZoneTitle:
        'Drag and drop PDFs or a ZIP file here, or click to upload.',
      DragRejectText: 'Only one file at a time can be uploaded',
      SubmitButton: (pharmacyName: string) =>
        `Start invoice processing for ${pharmacyName} `,
      SelectSupplier: 'Please select a supplier',
      SuccessValidFile: (fileName: string) => `Selected file: ${fileName}.`,
      ErrorNotAValidFile: (fileName: string, fileType: string) =>
        `The file ${fileName} does not seem to be a valid ${fileType} file.`,
      ErrorCannotAcceptMultipleFiles: 'Please upload one file at a time.',
      ErrorCanOnlyAcceptPdfOrZip:
        'Only multiple PDFs or a single ZIP file are accepted.',
      ErrorCreatingBookIn: (errorCode: string) =>
        `Unfortunately, we could not process any of the selected files. Please make sure that your internet connection works, the uploaded files are valid, and don't hesitate to contact us via chat support. (ERRORCODE: ${errorCode})`,
      ErrorCouldNotDetectSupplier: `Please select the supplier from the dropdown and re - submit the invoice for processing.
  Note: ***'supplier PDF'*** means PDF documents downloaded from the supplier's portal or sent to you from the supplier by email; ***'scanned invoice'*** means a paper invoice you have digitised using an office scanner.`,
      Processing: `Processing file: `,
      Idle: ' Please wait...',
      Failed: 'Failed: ',
      ProcessingInvoices: 'Processing invoices',
      UploadInvoices: 'Upload invoices',
      RefreshAndClose: 'Close and Refresh',
      RefreshWarning:
        'Please do not close or refresh this browser tab while this process is taking place.',
    },
    PendingDeliveryListItem: {
      Supplier: 'Supplier',
      InvoiceDate: 'Invoice date',
      InvoiceNumber: 'Invoice number',
      TotalExVat: 'Total ex. VAT',
      ViewDelivery: 'View delivery',
    },
    MultiDeliveryForm: {
      Unspecified: 'Unspecified',
      InvoicePage: 'Invoice page',
      ManuallyAddedProducts: 'Manually added products',
      AlphaBeticalOrder: 'AZ',
      AlphaBetaHeader: 'Showing all products sorted alphabetically',
      MultipleSuppliers: 'Multiple suppliers',
      PdfInvoice: 'PDF invoice',
      InvoiceNumber: 'Invoice number',
      InvoiceDate: 'Invoice date',
      TotalExVat: 'Invoice total (ex. VAT)',
      TotalWithVat: 'Invoice total (with VAT)',
      IngestionDate: 'Ingestion date:',
      Supplier: 'Supplier:',
      SaveChanges: 'Save changes',
      NInvoicePages: (n: number, manuallyAddedProducts: boolean) =>
        `${n} invoice page${n !== 1 ? 's' : ''}${
          manuallyAddedProducts ? '. Manually added products' : ''
        }`,
      AggregatedProducts: 'All invoices',
      AddProduct: 'Add product',
      Complete: 'Complete delivery',
      ReEdit: 'Edit delivery',
      CompleteAllDeliveries: 'Complete all deliveries',
      FinaliseEdits: 'Finalise changes',
      FinaliseEditsHealthscore: 'Finalise review',
      ReEditHealthscore: 'Review delivery',
      DeleteDelivery: 'Delete delivery',
      DeleteDeliveryDialogTitle: 'Are you sure?',
      DeleteDeliveryDialogText: (supplier: string) => {
        let s = supplier ? `**${supplier}**` : ''
        return `Please confirm that you would like to delete this ${s} delivery. This action cannot be undone.`
      },
      DeleteDeliveryOkText: 'Delete',
      DeleteDeliveryCancelText: 'Keep',
      ErrorDuplicateInvoiceNumber:
        'Completing this delivery is not possible: a delivery with the same invoice number from the same supplier already exists in your **delivery history**.',
      WarningDuplicateInvoiceNumber:
        'Another delivery with the same invoice number from the same supplier already exists in your list of **new and pending deliveries**.',
      ActionedOrReceivedCreditAlert:
        'This delivery cannot be deleted because it contains actioned credits.',
      HealthscorePenalties: {
        FUZZY_ORIGIN:
          'Unrecognised product(s): certain products have not been previously seen by StockRx (e.g. due to suppliers changing invoice wording)',
        QTY_TIMES_UNIT_PRICES_NOT_EQ_TOTAL_PRICE:
          'Product(s) with pricing error: one or more products have an invoiced price that does not match quantity and price per unit',
        NET_PLUS_VAT_NOT_EQ_TOTAL:
          'Product(s) with pricing error: one or more products have a total price with tax that does not match net price and vat amount',
        LOTS_ADJUSTMENTS: 'Staff have added credits to the invoice',
        LARGE_INV_AMOUNT: 'Invoice value is higher than £500',
        MANY_PRODUCTS: 'Invoice contains a high number of products',
        NET_PLUS_TOTAL_TAX_NOT_EQ_TOTAL:
          'Net total + VAT does not match invoice gross total',
        TOTALS_MISSING:
          'Invoice missing one or more of: (i) gross total incl. VAT, (ii) net total excl. VAT, (iii) VAT',
        PACKING_SLIP:
          'Invoice is a packing slip, which can reduce accuracy of our algorithms',
        NET_AMOUNT_PRODS_NOT_EQ_NET_AMOUNT_OF_INV:
          'Net price of the products booked in do not match the invoice net total',
        TOTAL_AMOUNT_PRODS_NOT_EQ_TOTAL_AMOUNT_OF_INV:
          'Total price of the products booked in do not match the invoice total with tax',
      },
      DisableEditAlertHealthscore:
        'The invoice cannot be edited from this screen. If you need to make any changes, contact a manager and navigate to **Invoice Healthscore**.',
      DisableEditAlertMandatoryScanning:
        'This delivery **can only be edited and completed via the StockRx mobile app**, because mandatory scanning is turned on for the selected pharmacy.',
      BatchInternal:
        'Internal supplier delivery - No downloadable invoice available',
      ErrorEmptyInvoice:
        'The delivery does not contain any products. Please add at least one product in order to complete the delivery.',
      ErrorAggregatedProducts:
        'One or more invoices have validation errors. Please review the highlighted invoices and correct the errors.',
      WarningAnalyticsEdit:
        "You modified a confirmed invoice. Click 'Finalise Changes' to save, or this invoice and its contents will not take part in calculations in all other parts of StockRx.",
      WarningMultipleSuppliersBookIn:
        "You're booking in multiple invoices from multiple suppliers.",
      MultiDeliveryFormProduct: {
        HealthscorePenaltyList: {
          FUZZY_ORIGIN: 'Unrecognised product',
          QTY_TIMES_UNIT_PRICES_NOT_EQ_TOTAL_PRICE: 'Product pricing error',
          NET_PLUS_VAT_NOT_EQ_TOTAL: 'Product gross/net/vat price error',
        },
        ProductName: 'Product name',
        ProductCode: 'Product code',
        PackSize: 'Pack size',
        InvoicePacks: 'Invoice packs',
        Quantity: 'Quantity',
        CreditPacks: 'Credit packs',
        CreditPacksTooltip:
          'The number of packs for which a credit is required.',
        PricePerUnit: 'Price per unit',
        NetPrice: 'Total ex. VAT',
        PriceWithTax: 'Price with VAT',
        Price: 'Price',
        VatRate: 'VAT rate',
        VatCode: 'VAT code',
        CreditReason: 'Credit reason',
        Edit: 'Edit',
        Confirm: 'Confirm',
        Delete: 'Remove',
        DeleteDialogTitle: 'Are you sure?',
        DeleteDialogText: (productPackSize: string) =>
          `Are you sure you would like to remove **${productPackSize}** from this invoice? This action cannot be undone.`,
        DeleteOk: 'Remove',
        DeleteCancel: 'Keep',
        Unlock: 'Unlock',
        UnspecifiedProductName: 'Please select a product',
        DeleteUnspecifiedProductName: 'this product',
        ActionedOrReceivedCredit: 'Actioned credits',
        ActionedOrReceivedCreditTooltip:
          "No further changes can be made because this product's credit has been actioned.",
        SelectProductModal: {
          SearchProductPlaceholder: 'Search for a product',
          Close: 'Close',
          NotListed: 'The product I am looking for is not listed',
          SelectAProduct: 'Select a product',
          SelectAPacksize: (productName: string) =>
            `Select a pack size for ${productName}`,
          SelectButtonPackSize: (packSize: number) =>
            `[Pack size: ${packSize}]`,
          PleaseSelectAProductAndAPackSize: (packSizeOptional: boolean) =>
            `Please select a product and ${
              packSizeOptional ? 'optionally a' : ''
            } pack size`,
          UnlistedProductName: 'Unlisted Product',
          ProductNotFound: 'Product not found',
        },
      },
      MultiDeliveryFormCloseConfirmaionModal: {
        Title: 'You are about to close without finalising changes',
        TextMER:
          'This invoice and all associated credits will be removed from Month End Reconciliation calculations until the book-in is finalised and the Month End Reconciliation recalculated.',
        TextAnalytics:
          'All products on this invoice will be removed from Analytics calculations until the book-in is finalised.',
        ConfirmText: 'Return to book-in',
        CancelText: 'Close anyway',
      },
    },
    DeliveriesSearch: {
      SearchByInvoiceNumber: 'Search for an invoice number',
    },
  },
  StockTrackingPage: {
    Tabs: {
      Inventory: 'Inventory',
    },
    NotEnabled: (clientName: string) =>
      `Stock Tracking is not enabled for ${clientName}. Please contact StockRx if you would like to enable it.`,
    InfoSelectSinglePharmacy:
      'Please select a single pharmacy site from the drop-down menu top-right. Stock tracking across organisations and clusters is coming soon.',

    TabLiveStock: {
      TableColumnTitles: [
        'Product name',
        'Pack size',
        'Current stock (packs)',
        'Split pack units',
        'Last updated',
      ],
      InfoCurrentStockPacks:
        'Your current stock of full packs. If you have split 2 packs in half, these will be counted as a single full pack.',
      InfoSplitPackUnits:
        'Any leftover units from split packs in your current stock.',
      TrackNewProduct: 'Start tracking new product',
      ProductAlreadyTracked: 'This product is already being tracked.',
      SearchPlaceholder: 'Search for a product',
      WarningTrackingNeverReset: `Stock level may be inaccurate due to missing opening balance. \nReset the current stock to ensure accuracy.`,
      ShowUninitialisedProductsToggle: 'Show products without opening balance',
      RollConcessionsOverToggle: 'Roll concessions over',
      InputStockForm: {
        Fields: {
          StockPacks: {
            Label: 'How many packs are currently in stock?',
          },
          StockUnits: {
            Label: 'How many split pack units are currently in stock?',
          },
        },
        Buttons: {
          Submit: (stockPacks: number, stockUnits: number, isReset: boolean) =>
            `${
              isReset ? 'Reset' : 'Set'
            } stock to ${stockPacks} packs, ${stockUnits} units`,
        },
        Validation: {
          CurrentStockPacksOrUnitsValidationInteger:
            'Only whole numbers are allowed',
          CurrentStockPacksValidationRequired:
            'Please enter the current packs in stock',
          CurrentStockUnitsValidationRequired:
            'Please enter the current units in stock',
          CurrentStockPacksValidationMin:
            'Number of packs cannot be less than 0',
          CurrentStockUnitsValidationMin:
            'Number of units cannot be less than 0',
        },
        Notifications: {
          ErrorCouldNotStartTrackingProduct:
            'Could not add product to stock tracking',
          SuccessStartedTrackingProduct: 'Product added to stock tracking',
          NotificationAlreadyCalculatedSplit: (
            originalUnits: number,
            calculatedPacks: number,
            calculatedUnits: number
          ) =>
            `The ${originalUnits} split pack units will be distributed into ${calculatedPacks} pack(s) and ${calculatedUnits} unit(s)`,
        },
        ProductTitle: (
          titleProduct: string,
          packSize: number,
          unitOfMeasure?: string,
          subpackDescription?: string
        ) =>
          `${titleProduct} [${packSize} ${unitOfMeasure}${
            subpackDescription ? ' - ' + subpackDescription : ''
          }]`,
      },
      AdjustmentForm: {
        ProductTitle: (
          titleProduct: string,
          packSize: number,
          unitOfMeasure?: string,
          subpackDescription?: string
        ) =>
          `${titleProduct} [${packSize} ${unitOfMeasure}${
            subpackDescription ? ' - ' + subpackDescription : ''
          }]`,
        ProductDescription: (packs: number, splitPacks: number) =>
          `Packs in stock: **${packs}**, Split packs in stock: **${splitPacks}**`,
        Fields: {
          AdjustedPacks: {
            Label: (isRemoved: boolean) =>
              `How many packs need to be ${isRemoved ? 'removed' : 'added'}?`,
          },
          AdjustedUnits: {
            Label: (isRemoved: boolean) =>
              `How many split pack units need to be ${
                isRemoved ? 'removed' : 'added'
              }?`,
          },
          SelectAdjustmentReason: {
            Label: 'Select adjustment reason',
          },
        },
        Buttons: {
          Submit: (stockPacks: number, stockUnits: number) =>
            `Adjust stock by ${stockPacks} packs, ${stockUnits} units`,
        },
        AccordionAdjust: 'ADJUST CURRENT STOCK',
        AccordionReset: 'RESET CURRENT STOCK',
        AccordionDescription:
          'Record any disposals or inter-branch transfer stock adjustments',
        AccordionDescriptionReset: 'Reset stock to a specific quantity',
        Validation: {
          AdjustedPacksOrUnitsValidationInteger:
            'Only whole numbers are allowed',
          AdjustmentReasonValidationRequired:
            'Please select a reason for adjusting the stock level',
          AdjustedPacksOrUnitsValidationRequired:
            'Please provide a value for this field',
          AdjustedPacksOrUnitsValidationMin: 'Value must be greater than 0',
          AdjustedPacksValidationMax:
            "An adjustment cannot be higher or equal to the current stock. Please use 'Reset Current Stock' to adjust upwards if needed.",
          AdjustedPacksOrUnitsValidationBiggerThanTotalNumber:
            'Please, select smaller number of packs or units',
          AdjustedPacksOrUnitsValidationBiggerThanTotalNumberAlert: (
            totalNumberofUnits: number
          ) =>
            `Please insert smaller number of units or packs.Total number of units from storage: ${totalNumberofUnits}`,
          AdjustedUnitsValidationMax:
            'The number of adjusted units should be lower than the pack size of the product, otherwise please adjust the number of packs.',
        },
        Notifications: {
          NotificationAlreadyCalculatedSplit: (
            originalUnits: number,
            calculatedPacks: number,
            calculatedUnits: number
          ) =>
            `The ${originalUnits} split pack units will be distributed into ${calculatedPacks} pack(s) and ${calculatedUnits} unit(s)`,
        },
      },
    },
  },
  MerPage: {
    Tabs: {
      MonthEndReconciliation: 'Month-end reconciliation',
    },
    MonthEndReconciliationTab: {
      UploadButton: 'Upload statement',
      SelectPharmacyToStart:
        'Please select a **single pharmacy site** from the drop-down menu top-right. Statements may only be uploaded for one pharmacy at a time.',
      HistoryTableColumns: {
        Pharmacy: 'Pharmacy',
        Supplier: 'Supplier',
        StatementDate: 'Statement date',
        StatementAmount: 'Statement amount',
        BookInAmmount: 'Amount in StockRx',
        TotalDiscrepancy: 'Total discrepancy',
        MatchedInvoicesValue: 'Matched invoices value',
        UnmatchedInvoicesValue: 'Unmatched invoices value',
        MatchedCreditsValue: 'Matched credits value',
        UnmatchedCreditsValue: 'Unmatched credits value',
      },
    },
    MonthEndReconciliationUploadPopup: {
      DropZoneTitle:
        'Drag and drop a PDF statement file here or click to select one',
      DragRejectText: 'Only single PDF files are accepted',
      ErrorNotAValidFile: (fileName: string, fileType: string) =>
        `The file ${fileName} does not seem to be a valid ${fileType} file.`,
      ErrorCannotAcceptMultipleFiles: 'Please upload one file at a time.',
      SelectedFile: (fileName: string) => `Selected file: ${fileName}`,
      SelectASupplier: 'Please select a supplier',
      UploadAndProcess: (supplierName: string, fileName: string) => {
        if (!supplierName || !fileName) {
          return 'Please select a statement file and supplier'
        }
        return `Upload and process the ${supplierName} statement ${fileName}`
      },
      ErrorCreatingMer: (errorCode: string) =>
        `Unfortunately, there was an error while uploading and processing the statement. (ERRORCODE: ${errorCode})`,
    },
    MonthEndReconciliationAnalysisForm: {
      InvoiceAnalysis:
        'Comparing invoices on statement to invoices in your delivery history',
      InvoiceAnalysisTable: {
        StatementInvoiceNumber: 'Invoice number (from statement)',
        StatementInvoiceAmount: 'Invoice amount (from statement)',
        StatementInvoiceDate: 'Invoice date (from statement)',
        MatchedBookedInInvoiceNumber: 'Invoice number (from delivery history)',
        MatchedBookedInInvoiceAmount: 'Invoice amount (from delivery history)',
        MatchedBookedInInvoiceDate: 'Invoice date (from delivery history)',
        ExcludeFromAnalysis: 'Exclude from discrepancy calculation',
        Note: 'Note',
      },
      CreditNoteAnalysis:
        'Comparing credit notes on statement to credits in your credits-for-action',
      CreditAnalysisTable: {
        StatementInvoiceNumber: 'Credit note number (from statement)',
        StatementInvoiceAmount: 'Credit note amount (from statement)',
        StatementInvoiceDate: 'Credit note date (from statement)',
        MatchedBookedInInvoiceNumber:
          'Credit note number (from delivery history)',
        MatchedBookedInInvoiceAmount:
          'Credit note amount (from delivery history)',
        MatchedBookedInInvoiceDate: 'Credit note date (from delivery history)',
        ExcludeFromAnalysis: 'Exclude from discrepancy calculation',
        Note: 'Note',
      },
      MissingRecords:
        'Invoices and credit notes in your goods-in but missing from statement',
      SystemOnlyDocumentsTable: {
        Type: 'Type',
        InvoiceNumber: 'Invoice/credit number',
        InvoiceDate: 'Date',
        InvoiceAmount: 'Amount',
        ExcludeFromAnalysis: 'Exclude from discrepancy calculation',
        Note: 'Note',
      },
      Credit: 'Credit',
      Invoice: 'Invoice',
      EditDocumentExclusion: 'Edit document exclusion',
      MatchedDetails: 'Matched details:',
      ExcludeThisDocument: 'Exclude this document from analysis',
      LabelNotes: 'Notes',
      ApplyButton: 'Apply',
      DocumentsDateRange: 'Documents date range',
      ShowOnlyDiscrepancies: 'Show only discrepancies',
      PdfStatement: 'View original statement',
      NotFoundInvoiceMessage: (invoiceNo: string) =>
        `An invoice with number ${invoiceNo} was not found in the system`,
      MismatchedInvoiceAmountMessage: (invoiceNo: string) =>
        `The invoice with number ${invoiceNo} in the system has a different total amount than the month-end statement.`,
      MismatchedInvoiceNumberMessage: `The invoice numbers do not exactly match.`,
      NotFoundCreditMessage: (creditAmount: number) =>
        `A credit for the amount of ${toCurrencyString(
          creditAmount
        )} was not found in the system`,
      Recalculate: 'Recalculate',
      Delete: 'Delete',
      DataSummaries: 'Extracted and goods-in data summaries',
      ExtractedDataSummary: 'Summary of data from statement',
      SystemDataSummary: 'Summary of data from your goods-in ',
      DiscrepanciesSummary: 'Summary of discrepancies found',
      Note: 'Note',
      StatementNumberOfInvoices: 'Number of invoices in statement',
      StatementValueOfInvoices: 'Value of invoices in statement',
      StatementNumberOfCredits: 'Number of credits in statement',
      StatementValueOfCredits: 'Value of credits in statement',
      StatementTotalAsPrinted: 'Printed statement total',
      StatementTotalAsPrintedPerMonth: (monthFormatted: string) =>
        `Printed statement total (${monthFormatted})`,
      StatementTotalAsPrintedAllMonths: 'Printed statement total (all months)',
      StatementTotalAsCalculated:
        'Calculated statement total (invoices value - credits value, exclusions do not apply)',

      SystemNumberOfInvoices: 'Number of invoices in goods-in',
      SystemValueOfInvoices: 'Value of invoices in goods-in',
      SystemNumberOfCredits: 'Number of invoices with credits in goods-in',
      SystemValueOfCredits: 'Value of credits in goods-in',
      SystemTotal: 'Goods-in total (invoices value - credits value)',

      NumberOfInvoicesInStockRx: 'Number of invoices in StockRx goods-in',
      TotalValueOfInvoicesInStockRx:
        'Total value of invoices in StockRx goods-in',
      TotalValueOfCreditsInStockRx:
        'Total value of credits in StockRx goods-in',
      TotalOverallValueInStockRx: 'Total overall value in StockRx goods-in',

      StatementTotalValueWithExcluded:
        'Calculated statement total (invoices value - credits value, exclusions apply)',
      UnmatchedInvoicesCount: 'Number of unmatched invoices',
      UnmatchedInvoicesValue: 'Value of unmatched invoices',
      MismatchedInvoicesCount:
        'Number of matched invoices with value discrepancies',
      MismatcheddInvoicesValue: 'Total discrepancies value of matched invoices',

      UnmatchedCreditsCount: 'Number of unmatched credits',
      UnmatchedCreditsValue: 'Value of unmatched credits',
      TotalDiscrepancy: `Total discrepancy (goods-in total - calculated statement total)`,
      DeleteConfirmationTitle: `Delete statement`,
      DeleteConfirmationText: `Are you want to delete this month-end reconciliation statement?`,
      DeleteConfirmationOk: `Delete it`,
      DeleteConfirmationCancel: `Keep it`,
      IncludesVat:
        'All the amounts shown on this page are **inclusive of VAT**',
      DoesNotIncludeVat:
        'All the amounts shown on this page are **exclusive of VAT**',
    },
  },
  LogInForm: {
    Title: 'Log in',
    Fields: {
      Email: {
        Label: 'Email',
        Placeholder: 'Enter your email',
        Validation: {
          Required: 'You need to enter your email',
        },
      },
      Password: {
        Label: 'Password',
        Placeholder: 'Enter your password',
        Validation: {
          Required: 'You need to enter your password',
        },
      },
    },
    Buttons: {
      Submit: 'Log in',
      ForgotPassword: 'I forgot my password',
    },
  },
  ChangePasswordForm: {
    Title: 'Reset your password',
    Fields: {
      FullName: {
        Label: 'Full name',
        Placeholder: 'Enter your full name',
        Validation: {
          Required: 'Your full name is required',
          Minimum: (m: Number) => `You need to enter at least ${m} characters`,
        },
      },
      NewPassword: {
        Label: 'New password',
        Placeholder: 'Enter your new password',
        Validation: {
          Required: 'A new password is required',
          Minimum: (m: Number) => `You need to enter at least ${m} characters`,
        },
      },
      ConfirmNewPassword: {
        Label: 'Confirm password',
        Placeholder: 'Confirm your new password',
        Validation: {
          Required: 'A new password is required',
          Minimum: (m: Number) => `You need to enter at least ${m} characters`,
          PasswordsDoNotMatch: 'The passwords do not match',
        },
      },
    },
    Buttons: {
      Submit: 'Submit',
    },
  },
  ForgotPasswordForm: {
    Title: 'Forgot password',
    CodeDescription: 'Please check your email for a validation code',
    Fields: {
      Email: {
        Label: 'Email',
        Placeholder: 'Enter your email',
        Validation: {
          Required: 'You need to enter your email',
        },
      },
      Code: {
        Label: 'Validation code',

        Placeholder: 'Validation code',
        Validation: {
          Required: 'Please enter your validation code',
        },
      },
    },
    Buttons: {
      Submit: 'Submit',
    },
  },
  DateRangePickers: {
    To: '-',
    From: 'From',
    Until: 'Until',
    LastThirtyDays: 'Last 30 days',
    MonthToDate: 'Month to date',
    Yesterday: 'Yesterday',
    Today: 'Today',
    NullDate: 'Any date',
    ClearSelection: 'Any date',
  },
  AdjusmentTypes: {
    CREDIT: 'Credit',
    DISPOSAL: 'Disposal',
  },
  CreditReasons: {
    DAMAGED_PRODUCT: 'Damaged product',
    MISSING_PRODUCT: 'Missing product',
    INCORRECT_PRODUCT: 'Incorrect product',
    ORDERING_ERROR: 'Ordered in error',
    PRICING_ERROR: 'Pricing error',
    SHORT_DATED: 'Short dated',
  },
  DisposalReasons: {
    EXPIRED_PRODUCT: 'Expired product',
    DAMAGED_PRODUCT: 'Damaged product',
  },
  InterBranchTransfer: {
    STOCK_COMING_IN: 'IBT: Stock coming in',
    SENDING_STOCK_OUT: 'IBT: Sending stock out',
  },
  MultiSupplierSelection: {
    SelectAll: 'Select all',
    ClearSelection: 'Clear selections',
    Apply: 'Apply',
    AllSuppliers: 'All suppliers',
    SpecificSuppliers: 'Specific suppliers',
  },

  PriceFilesPage: {
    Tabs: {
      Upload: 'Upload',
    },
    CompanyOnly:
      'Uploading supplier price files is enabled only at company level.',
    NotEnabled: (clientName: string) =>
      `Uploading price files is not enabled for ${clientName}. Please contact StockRx if you would like to enable it.`,
    TabUploads: {
      PriceFileUploadPopup: {
        ErrorNotAValidFile: (fileName: string, fileType: string) =>
          `The file ${fileName} does not seem to be a valid ${fileType} file.`,
        ErrorUploadingPriceFile: (errorCode: string) =>
          `Unfortunately, there was an error while uploading. Please try again later. (ERRORCODE: ${errorCode})`,
        SelectedFile: (fileName: string) => `Selected file: ${fileName}`,
        UploadAndProcess: (supplierName: string, fileName: string) => {
          if (!supplierName || !fileName) {
            return 'Please select a price file supplier, supplier and date range.'
          }
          return `Upload and process the ${supplierName} price file ${fileName}`
        },
        DropZoneTitle:
          'Please drop an EXCEL or CSV supplier price file here or click to select one',
        ErrorCannotAcceptMultipleFiles: 'Please upload one file at a time.',
        SelectASupplier: 'Please select a supplier',
      },
      PriceFileUploadDetailsPopup: {
        SearchPlaceholder: 'Search for a product',
        ProductUpdatedSuccessText: 'The product was updated successfully',
        DeletePriceFile: 'Delete price file',
        DeletePriceFileDialogTitle: 'Are you sure?',
        DeletePriceFileDialogText:
          'Please confirm that you would like to delete this price file. This action cannot be undone.',
        DeletePriceFileOkText: 'Delete',
        DeletePriceFileCancelText: 'Keep',
        DeletePriceFileSuccessText: 'The price file is being deleted',
        TableColumnTitles: [
          'Source code',
          'Source name',
          'Source pack size',
          'Source strength',
          'Product name',
          'Pack size',
          'Price',
        ],
      },

      UploadButton: 'Upload price file',
      TableColumnTitles: [
        'File name',
        'Supplier',
        'Uploaded on',
        'Period covered',
        'Status',
      ],
      // File processing statuses
      ItemProcessedSuccessfully: 'Processed successfully',
      ItemProcessing: 'Currently processing',
      ItemError: (failureReason: string) =>
        `Processing failed.Error message: ${failureReason} `,
    },
  },
}
