import { FC, useMemo, useState } from 'react'
import { MonthEndReconciliationDocument } from '../entities/mer-entities'
import ModalContainer from '../../../components/Interactions/ModalContainer'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { locale } from '../../../locales'
import { SxProps, Theme } from '@mui/material/styles'
import { MonthEndReconciliationDocumentTypes } from '../../../constants'
import {
  getLocaleDateMedWithoutWeekDay,
  toCurrencyString,
} from '../../../utils/Helpers'
import theme from '../../../styles/theme'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import { SupplierWithFinancials } from '../../../types/entities/Supplier'
import TableMui, { MuiTableColumn } from '../../../components/Data/TableMui'

const translation =
  locale.translation.MerPage.MonthEndReconciliationAnalysisForm

const rowStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}

const MonthEndReconciliationExcludePopup: FC<{
  odsCode: string
  merId: string
  merDocument: MonthEndReconciliationDocument
  merSupplier: SupplierWithFinancials
  isExcludedInitialState: boolean
  onClose: (
    shouldReProcess: boolean,
    documentLineNumber: number | null,
    isExcluded: boolean | null,
    note: string | null
  ) => void
}> = ({
  odsCode,
  merId,
  merDocument,
  merSupplier,
  isExcludedInitialState,
  onClose,
}) => {
  const translationTable =
    merDocument.documentType === MonthEndReconciliationDocumentTypes.Invoice
      ? translation.InvoiceAnalysisTable
      : translation.CreditAnalysisTable

  const [excluded, setExcluded] = useState<boolean>(isExcludedInitialState)
  const [note, setNote] = useState<string | null>(merDocument.optionsNote)

  const isApplyEnabled = useMemo(() => {
    const hasChanges =
      excluded !== isExcludedInitialState || note !== merDocument.optionsNote
    const isExclusionValid = !excluded || (note && note.trim().length > 0)
    return hasChanges && isExclusionValid
  }, [excluded, note, isExcludedInitialState, merDocument.optionsNote])

  const tableData = useMemo(() => {
    return [
      {
        key: 'row-1',
        statementKey: translationTable.StatementInvoiceNumber.toUpperCase(),
        statementValue: merDocument.documentId ?? '',
        systemKey: translationTable.MatchedBookedInInvoiceNumber.toUpperCase(),
        systemValue: merDocument.systemDocumentId ?? '',
      },
      {
        key: 'row-2',
        statementKey: translationTable.StatementInvoiceAmount.toUpperCase(),
        statementValue:
          toCurrencyString(
            merSupplier?.requiresVatForMer
              ? merDocument.documentGrossAmount
              : merDocument.documentNetAmount
          ) ?? '',
        systemKey: translationTable.MatchedBookedInInvoiceAmount.toUpperCase(),
        systemValue:
          toCurrencyString(merDocument.systemDocumentTotalPrice) ?? '',
      },
      {
        key: 'row-3',
        statementKey: translationTable.StatementInvoiceDate.toUpperCase(),
        statementValue:
          getLocaleDateMedWithoutWeekDay(merDocument.documentDate) ?? '',
        systemKey: translationTable.MatchedBookedInInvoiceDate.toUpperCase(),
        systemValue:
          getLocaleDateMedWithoutWeekDay(merDocument.systemDocumentDate) ?? '',
      },
    ]
  }, [merDocument, merSupplier, translationTable])

  const columns: MuiTableColumn[] = [
    { propertyName: 'statementKey', label: '', width: '25%' },
    { propertyName: 'statementValue', label: '', width: '25%' },
    { propertyName: 'systemKey', label: '', width: '25%' },
    { propertyName: 'systemValue', label: '', width: '25%' },
  ]

  return (
    <ModalContainer
      open={Boolean(merDocument)}
      onClickedClose={() => onClose(false, null, null, null)}
      centerContent={true}
      alignSelf={'center'}
      sx={{ width: '700px', flexGrow: 0 }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
          {translation.EditDocumentExclusion}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 500,
            textAlign: 'center',
            padding: theme.spacing(2),
          }}
        >
          {translation.MatchedDetails}
        </Typography>
        <Box sx={{ ...rowStyle }}>
          <TableMui
            columns={columns}
            data={tableData}
            isLoading={false}
            capitalisedHeader={false}
            hideHeader={true}
          />
          <FormControlLabel
            id="exclude-checkbox"
            sx={{ marginTop: theme.spacing(2) }}
            control={
              <Checkbox
                checked={excluded}
                onChange={() => setExcluded(!excluded)}
                name="excludedCheckbox"
              />
            }
            label={translation.ExcludeThisDocument}
          />
          <TextField
            data-testid="exlusion-notes-text"
            label={translation.LabelNotes}
            multiline
            fullWidth
            rows={5}
            value={note ?? ''}
            name="notes"
            onChange={(e) => setNote(e.target.value.substring(0, 250))}
          />
          <LoadingButton
            data-testid="apply-exclusion-button"
            disableElevation
            variant="contained"
            color="primary"
            disabled={!isApplyEnabled}
            sx={{ marginTop: theme.spacing(4) }}
            onClick={() =>
              onClose(true, merDocument.documentLineNumber, excluded, note)
            }
          >
            {translation.ApplyButton}
          </LoadingButton>
        </Box>
      </Box>
    </ModalContainer>
  )
}

export default MonthEndReconciliationExcludePopup
