import { FC, useContext, useEffect, useState } from 'react'
import {
  SuppliersPricesWithSourceDetails,
  UploadedPriceFile,
} from '../price-file-entities'
import { ServiceContext } from '../../../providers/ServicesProvider'
import { PlatformApiPaths } from '../../../PlatformApiPaths'
import {
  ClientSelection,
  ClientTypes,
} from '../../../types/entities/ClientPermission'
import { GetErrorMessage } from '../../../utils/ErrorHandling'
import {
  Alert,
  Box,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
} from '@mui/material'
import VirtuosoMuiTable, {
  VirtuosoColumn,
} from '../../../components/Data/VirtuosoMuiTable'
import { locale } from '../../../locales'
import ModalContainer from '../../../components/Interactions/ModalContainer'
import theme from '../../../styles/theme'
import ClearIcon from '@mui/icons-material/Clear'
import { textFiltering } from '../../../utils/FilteringUtls'
import {
  SortArrayByPropertyKeyArgs,
  sortArrayByPropertyKey,
} from '../../../utils/SortingUtils'
import LoadingButton from '@mui/lab/LoadingButton'
import ConfirmDialog from '../../../components/Interactions/ConfirmDialog'
import SelectProductModal from '../../GoodsIn/components/SelectProductModal'
import { SelectProductModalProduct } from '../../GoodsIn/entities/Product'
import { HttpResponse } from '../../../types/http/HttpResponse'

const translation =
  locale.translation.PriceFilesPage.TabUploads.PriceFileUploadDetailsPopup
const columnNames = translation.TableColumnTitles

const getColumnDefinitions =
  (): VirtuosoColumn<SuppliersPricesWithSourceDetails>[] => {
    const columnDefinitions = [
      {
        label: columnNames[0],
        dataKey: 'productCode',
        widthCss: '8%',
        sortable: true,
      },
      {
        label: columnNames[1],
        dataKey: 'sourceProductName',
        widthCss: '15%',
        sortable: true,
      },
      {
        label: columnNames[2],
        dataKey: 'sourcePackSize',
        widthCss: '5%',
      },
      {
        label: columnNames[3],
        dataKey: 'sourceProductStrength',
        widthCss: '5%',
      },
      {
        label: columnNames[4],
        dataKey: 'productName',
        widthCss: '15%',
        sortable: true,
      },
      {
        label: columnNames[5],
        dataKey: 'productPackSize',
        widthCss: '5%',
      },
      {
        label: columnNames[6],
        dataKey: 'price',
        widthCss: '5%',
        sortable: true,
        currency: true,
      },
    ] as VirtuosoColumn<SuppliersPricesWithSourceDetails>[]
    return columnDefinitions
  }

const PriceFileDetailsPopup: FC<{
  selectedPriceFile: UploadedPriceFile
  selectedClient: ClientSelection | null
  onClosed: (wasDeleted: boolean) => void
  open: boolean
}> = ({ selectedPriceFile, selectedClient, onClosed, open }) => {
  const { platformHttpService, notificationService } =
    useContext(ServiceContext)

  const [dataState, setDataState] = useState<
    SuppliersPricesWithSourceDetails[] | null
  >(null)
  const [filteredDataState, setFilteredDataState] = useState<
    SuppliersPricesWithSourceDetails[]
  >([])
  const [sortingState, setSortingState] = useState<SortArrayByPropertyKeyArgs>({
    sortingType: 'ASC',
    sortingPropertyKey: 'sourceProductName',
  })
  const [error, setError] = useState<string>('')
  const [errorButtonBar, setErrorButtonBar] = useState<string | null>(null)
  const [filterText, setFilterText] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [isUpdatingProduct, setIsUpdatingProduct] = useState<boolean>(false)
  const [showDeletePriceFile, setShowDeletePriceFile] = useState<boolean>(false)
  const [selectedProduct, setSelectedProduct] =
    useState<SuppliersPricesWithSourceDetails | null>(null)

  const columnDefinitions = getColumnDefinitions()
  const isTextInSearchField = filterText.length > 0

  // Get data
  useEffect(() => {
    const pageSize = 4000
    const getSuppliersPricesByUploadId = async (pageIndex: number) => {
      return await platformHttpService.getAsync<
        Array<SuppliersPricesWithSourceDetails>
      >(
        PlatformApiPaths.GetSuppliersPricesWithSourceDetails(
          selectedClient!.clientId,
          selectedPriceFile.uploadId,
          pageIndex,
          pageSize
        ),
        'SuppliersPricesBaseUrl'
      )
    }
    const getAllData = async () => {
      let resultResponse: HttpResponse<
        Array<SuppliersPricesWithSourceDetails>
      > = {
        data: [],
        errors: [],
        hasErrors: false,
        rawResponse: null,
        wasCancelled: false,
      }
      let pageIndex = 0
      while (true) {
        const response = await getSuppliersPricesByUploadId(pageIndex)
        if (response.hasErrors || !response.data) {
          resultResponse.errors = response.errors
          return resultResponse
        }
        resultResponse.data = [...resultResponse.data!, ...response.data]
        if (response.data.length < pageSize) {
          break
        }
        pageIndex++
      }
      return resultResponse
    }
    if (selectedClient && selectedClient.clientType === ClientTypes.Company) {
      setIsLoading(true)
      setError('')
      getAllData().then((response) => {
        setDataState(response?.data)
        setError(response.hasErrors ? GetErrorMessage(response.statusCode) : '')
        setIsLoading(false)
      })
    }
  }, [
    platformHttpService,
    selectedClient,
    selectedPriceFile.uploadId,
    setDataState,
    setIsLoading,
  ])

  // Filter & sort
  useEffect(() => {
    if (dataState && dataState.length > 0) {
      let items = [...dataState]
      if (filterText !== '') {
        items = [
          ...dataState.filter((i) => {
            return textFiltering(
              filterText,
              `${i.sourceProductName} ${i.productName}`
            )
          }),
        ]
      }
      sortArrayByPropertyKey(items, sortingState)
      setFilteredDataState(items)
    }
  }, [dataState, filterText, sortingState])

  // Delete a price file
  const handleDeletePriceFile = async () => {
    setIsDeleting(true)
    setErrorButtonBar(null)
    const deletePriceFileResponse = await platformHttpService.deleteAsync(
      PlatformApiPaths.DeleteUploadedPriceFile(
        selectedClient!.clientId,
        selectedPriceFile.uploadId
      ),
      null,
      'SuppliersPricesBaseUrl'
    )
    setIsDeleting(false)
    if (!deletePriceFileResponse.hasErrors) {
      notificationService.showNotification(
        translation.DeletePriceFileSuccessText,
        'warning',
        5000
      )
      onClosed(true)
    } else {
      setErrorButtonBar(GetErrorMessage(deletePriceFileResponse?.statusCode))
    }
  }

  // Edit a product match
  const handleEditProduct = async (
    productId: string,
    productPackSize: number,
    productName: string
  ) => {
    if (selectedProduct) {
      setIsUpdatingProduct(true)
      const payload = {
        productId: productId,
        packSize: productPackSize,
        supplierId: selectedPriceFile.supplierId,
        productCode: selectedProduct.productCode,
        uploadId: selectedPriceFile.uploadId,
      }
      setErrorButtonBar(null)
      const response = await platformHttpService.postAsync<void>(
        PlatformApiPaths.UpdateProductMatch(selectedClient!.clientId),
        payload,
        'SuppliersPricesBaseUrl'
      )
      if (!response.hasErrors && dataState) {
        const itemsClone = [...dataState]
        if (dataState.length > 0) {
          itemsClone.forEach((v, i) => {
            if (v.productCode === selectedProduct.productCode) {
              itemsClone[i] = { ...v, productId, productName, productPackSize }
            }
          })
        }
        setDataState(itemsClone)
        notificationService.showNotification(
          translation.ProductUpdatedSuccessText,
          'success',
          5000
        )
      } else {
        setErrorButtonBar(GetErrorMessage(response?.statusCode))
      }
      setIsUpdatingProduct(false)
    }
  }

  return (
    <ModalContainer
      open={open}
      onClickedClose={() => {
        onClosed(false)
      }}
      alignSelf="center"
      sx={{
        flexGrow: 1,
        display: 'flex',
        width: '80%',
      }}
      centerContent={true}
    >
      {(isLoading || isUpdatingProduct) && (
        <LinearProgress sx={{ marginTop: theme.spacing(1) }} />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          paddingTop: theme.spacing(1.5),
          paddingBottom: theme.spacing(2),
          justifyContent: 'flex-start',
        }}
      >
        <TextField
          sx={{ flexGrow: 1 }}
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          placeholder={translation.SearchPlaceholder}
          data-testid="search-text-field"
          InputProps={{
            endAdornment: isTextInSearchField && (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setFilterText('')
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        ></TextField>
      </Box>
      {!isLoading && filteredDataState != null && (
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <VirtuosoMuiTable
            rows={filteredDataState}
            onRowClick={(row) => {
              setSelectedProduct(row)
            }}
            columns={columnDefinitions}
            sorting={{
              dataKey: sortingState.sortingPropertyKey,
              sortingType: sortingState.sortingType,
            }}
            onSortingChanged={(sorting) => {
              setSortingState({
                sortingType: sorting?.sortingType ?? 'NONE',
                sortingPropertyKey: sorting?.dataKey,
              })
            }}
          />
        </Box>
      )}
      {error && (
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          paddingTop: theme.spacing(1.5),
          justifyContent: 'flex-end',
          alignSelf: 'flex-end',
        }}
      >
        {!error && !isLoading && dataState && (
          <LoadingButton
            disableElevation
            variant="contained"
            color="error"
            loading={isDeleting}
            onClick={() => setShowDeletePriceFile(true)}
            sx={{
              minWidth: '160px',
            }}
          >
            {translation.DeletePriceFile}
          </LoadingButton>
        )}
      </Box>
      {selectedProduct && (
        <SelectProductModal
          showUnlistedProduct={false}
          ignoreSpecials={true}
          onClosedCallback={async (product) => {
            if (product) {
              await handleEditProduct(
                product?.productId,
                product?.packSize,
                product?.productName
              )
            }
            setSelectedProduct(null)
          }}
          product={
            {
              productId: selectedProduct.productId,
              packSize: selectedProduct.productPackSize,
              productName: selectedProduct.productName,
              productRawOcrName: [
                selectedProduct.sourceProductName,
                selectedProduct.sourcePackSize ?? '',
                selectedProduct.sourceStrength ?? '',
              ]
                .join(' ')
                .trim(),
            } as SelectProductModalProduct
          }
        />
      )}
      {showDeletePriceFile && (
        <ConfirmDialog
          cancelText={translation.DeletePriceFileCancelText}
          okText={translation.DeletePriceFileOkText}
          title={translation.DeletePriceFileDialogTitle}
          text={translation.DeletePriceFileDialogText}
          isCancelPrimary={true}
          onOk={async () => {
            setShowDeletePriceFile(false)
            await handleDeletePriceFile()
          }}
          onCancel={() => {
            setShowDeletePriceFile(false)
          }}
        ></ConfirmDialog>
      )}
      {errorButtonBar && (
        <Alert
          variant="filled"
          severity="error"
          sx={{
            flexGrow: 1,
            maxHeight: theme.spacing(4),
            marginTop: theme.spacing(1),
          }}
        >
          {errorButtonBar}
        </Alert>
      )}
    </ModalContainer>
  )
}

export default PriceFileDetailsPopup
