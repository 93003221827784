import Box from '@mui/material/Box'
import {
  FC,
  useCallback,
  useContext,
  useReducer,
  useRef,
  useState,
} from 'react'
import theme from '../../styles/theme'
import AutoSizingBox from '../../components/Util/AutoSizingBox'
import { NumericFormat } from 'react-number-format'
import { ClientCompanyConfig, ProblemProductsConfig } from './entities/Entities'
import { SxProps, Theme } from '@mui/material/styles'
import { locale } from '../../locales'
import { useRecoilValue } from 'recoil'
import { ServiceContext } from '../../providers/ServicesProvider'
import { useGlobalIsLoading } from '../../hooks/useIsLoading'
import MultiEmailInput, {
  MultiEmailInputHandle,
} from '../../components/Forms/MultiEmailInput'
import { PlatformApiPaths } from '../../PlatformApiPaths'
import { GetErrorMessage } from '../../utils/ErrorHandling'
import { selectedClientState } from '../../state/SelectedPharmacyState'
import EditableSection from './components/EditableSection'
import InfoTooltip from '../../components/Interactions/InfoTooltip'
import TextField from '@mui/material/TextField'
import SwitchWithInfo from '../../components/Forms/SwitchWithInfo'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'

const translations =
  locale.translation.SettingsPage.CompanyManagementTab.CompanyDetailsContainer

const editFieldStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  minWidth: 'max-content',
  alignItems: 'center',
  '& label': {
    marginLeft: theme.spacing(1),
    fontSize: '.9em',
  },
}

const editFieldTextFieldStyle: SxProps<Theme> = {
  maxWidth: '7ch',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  '& .MuiInputBase-input.Mui-disabled': {
    backgroundColor: theme.palette.grey[50],
    WebkitTextFillColor: theme.palette.grey[800],
  },
}

interface State {
  problemProductsConfig: ProblemProductsConfig
  validationErrors: { [key: string]: string[] }
  resetConfig?: ClientCompanyConfig
  hasInvalidEmails: boolean
}

const configEmptyInitialState: State = {
  problemProductsConfig: {
    recipientEmails: [],
    useTradePrices: false,
    rollConcessionsOver: false,
    useSupplierPrices: false,
    profitableProductsLength: 0,
    mostExpensiveProductsLength: 0,
    profitThreshold: 0,
  },
  validationErrors: {},
  hasInvalidEmails: false,
}

type SetEmailsAction = {
  type: 'SET_EMAILS'
  payload: string[]
}

type SetHasInvalidEmailsAction = {
  type: 'SET_HAS_INVALID_EMAILS'
  payload: boolean
}

type SetProblemProductsSectionAction = {
  type: 'SET_PROBLEM_PRODUCTS_SETTINGS_SECTION'
  payload: {
    rollConcessionsOver: boolean
    useSupplierPrices: boolean
    useTradePrices: boolean
    profitableProductsLength: number
    mostExpensiveProductsLength: number
    profitThreshold: number
  }
}

type SetAppliedConfigurationAction = {
  type: 'SET_APPLIED_CONFIGURATION'
}

type ResetEmailsAction = {
  type: 'RESET_EMAILS'
}

type ResetProblemProductsSectionAction = {
  type: 'RESET_PROBLEM_PRODUCTS_SECTION'
}

type Actions =
  | SetEmailsAction
  | SetHasInvalidEmailsAction
  | SetProblemProductsSectionAction
  | SetAppliedConfigurationAction
  | ResetEmailsAction
  | ResetProblemProductsSectionAction

const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case 'SET_APPLIED_CONFIGURATION':
      return {
        ...state,
        resetConfig: { problemProducts: state.problemProductsConfig },
      }

    case 'RESET_EMAILS': {
      const stateToResetTo: ProblemProductsConfig =
        state.resetConfig?.problemProducts ??
        configEmptyInitialState.problemProductsConfig
      const result = {
        ...state,
        problemProductsConfig: {
          ...state.problemProductsConfig,
          recipientEmails: stateToResetTo.recipientEmails,
        },
      }
      return result
    }
    case 'RESET_PROBLEM_PRODUCTS_SECTION': {
      const stateToResetTo: ProblemProductsConfig =
        state.resetConfig?.problemProducts ??
        configEmptyInitialState.problemProductsConfig
      return {
        ...state,
        problemProductsConfig: {
          ...stateToResetTo,
          recipientEmails: state.problemProductsConfig.recipientEmails,
        },
      }
    }

    case 'SET_EMAILS':
      return {
        ...state,
        problemProductsConfig: {
          ...state.problemProductsConfig,
          recipientEmails: action.payload,
        },
      }

    case 'SET_PROBLEM_PRODUCTS_SETTINGS_SECTION':
      return {
        ...state,
        problemProductsConfig: {
          recipientEmails: state.problemProductsConfig.recipientEmails,
          ...action.payload,
        },
      }

    case 'SET_HAS_INVALID_EMAILS':
      return {
        ...state,
        hasInvalidEmails: action.payload,
      }

    default:
      return state
  }
}

const CompanySettings: FC<{
  clientName: string | null
  clientProblemProductsConfig: ProblemProductsConfig | null
  fetchCompanyDetails: () => void
}> = ({ clientName, clientProblemProductsConfig, fetchCompanyDetails }) => {
  const { platformHttpService } = useContext(ServiceContext)
  const selectedClient = useRecoilValue(selectedClientState)
  const { setIsLoading } = useGlobalIsLoading()
  const [error, setError] = useState<string | null>(null)

  const [state, dispatch] = useReducer(reducer, {
    ...configEmptyInitialState,
    problemProductsConfig:
      clientProblemProductsConfig ??
      configEmptyInitialState.problemProductsConfig,
    resetConfig: {
      problemProducts:
        clientProblemProductsConfig ??
        configEmptyInitialState.problemProductsConfig,
    },
  })
  const [isEditingProblemProductsEmails, setIsEditingProblemProductsEmails] =
    useState(false)
  const emailsInputSectionWithTagsHandle = useRef<MultiEmailInputHandle>(null)
  const [
    isEditingProblemProductsSettings,
    setIsEditingProblemProductsSettings,
  ] = useState(false)

  const handleErrorsChanged = (hasError: boolean) => {
    dispatch({ type: 'SET_HAS_INVALID_EMAILS', payload: hasError })
  }

  const handleCompanyConfigurationsUpdated = useCallback(async () => {
    if (selectedClient) {
      setError(null)
      setIsLoading(true)
      const emailTags =
        emailsInputSectionWithTagsHandle.current?.getTags() ?? []
      const {
        rollConcessionsOver,
        useSupplierPrices,
        useTradePrices,
        profitableProductsLength,
        mostExpensiveProductsLength,
        profitThreshold,
      } = state.problemProductsConfig
      const updatedConfig = {
        problemProducts: {
          recipientEmails: emailTags,
          rollConcessionsOver,
          useSupplierPrices,
          useTradePrices,
          profitableProductsLength,
          mostExpensiveProductsLength,
          profitThreshold,
        },
      }
      const response = await platformHttpService.postAsync<ClientCompanyConfig>(
        PlatformApiPaths.CompanyConfiguration(selectedClient),
        updatedConfig,
        'ClientsBaseUri'
      )
      setIsLoading(false)
      if (!response || response?.hasErrors) {
        setError(GetErrorMessage(response.statusCode))
        return
      }
      dispatch({
        type: 'SET_APPLIED_CONFIGURATION',
      })
      fetchCompanyDetails()
    }
  }, [
    fetchCompanyDetails,
    platformHttpService,
    selectedClient,
    setIsLoading,
    state.problemProductsConfig,
  ])
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          paddingLeft: theme.spacing(1),
        }}
      >
        <AutoSizingBox>
          <>
            {error && (
              <Alert variant="filled" severity="error">
                {error}
              </Alert>
            )}
            <Box
              sx={{
                display: 'flex',
                height: '100%',
                flexDirection: 'column',
                gap: theme.spacing(2),
                padding: theme.spacing(1),
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.spacing(1),
                }}
              >
                <Typography
                  sx={{
                    paddingY: theme.spacing(1),
                    margin: 0,
                    color: theme.palette.grey[700],
                  }}
                  variant="button"
                >
                  {translations.CompanyDetails}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: theme.spacing(2),
                    marginBottom: theme.spacing(2),
                  }}
                >
                  <TextField
                    label={translations.CompanyNameLabel}
                    value={selectedClient?.name}
                    variant="outlined"
                    disabled
                    fullWidth
                  />
                </Box>
                <EditableSection
                  sectionTitle={translations.ProblemProductsReportSectionTitle}
                  onUpdate={() => {
                    handleCompanyConfigurationsUpdated()
                    dispatch({
                      type: 'SET_EMAILS',
                      payload:
                        emailsInputSectionWithTagsHandle.current?.getTags() ??
                        [],
                    })
                  }}
                  updateDisabled={
                    !isEditingProblemProductsEmails || state.hasInvalidEmails
                  }
                  onCancel={() => {
                    dispatch({
                      type: 'RESET_EMAILS',
                    })
                    emailsInputSectionWithTagsHandle.current?.clearValue()
                  }}
                  onEditingChanged={setIsEditingProblemProductsEmails}
                  titleSx={{ fontWeight: 600 }}
                  isEditing={isEditingProblemProductsEmails}
                >
                  <MultiEmailInput
                    ref={emailsInputSectionWithTagsHandle}
                    emails={state.problemProductsConfig.recipientEmails}
                    isDisabled={!isEditingProblemProductsEmails}
                    onErrorsChanged={handleErrorsChanged}
                    label={translations.ProblemProductsRecipientsEmailsLabel}
                  />
                </EditableSection>
                <EditableSection
                  sectionTitle={translations.ProblemProductsSettingsTitle}
                  onUpdate={() => {
                    handleCompanyConfigurationsUpdated()
                  }}
                  updateDisabled={!isEditingProblemProductsSettings}
                  onCancel={() => {
                    setIsEditingProblemProductsSettings(false)
                    dispatch({
                      type: 'RESET_PROBLEM_PRODUCTS_SECTION',
                    })
                  }}
                  onEditingChanged={setIsEditingProblemProductsSettings}
                  titleSx={{ fontWeight: 600 }}
                  isEditing={isEditingProblemProductsSettings}
                >
                  <SwitchWithInfo
                    checked={state.problemProductsConfig.rollConcessionsOver}
                    onChange={() => {
                      dispatch({
                        type: 'SET_PROBLEM_PRODUCTS_SETTINGS_SECTION',
                        payload: {
                          ...state.problemProductsConfig,
                          rollConcessionsOver:
                            !state.problemProductsConfig.rollConcessionsOver,
                        },
                      })
                    }}
                    testId="company-problem-products-settings-roll-concessions-toggle"
                    label={translations.RollConcessionsOverToggleLabel}
                    infoTooltipText={translations.RollConcessionsOverToggleInfo}
                    updateDisabled={!isEditingProblemProductsSettings}
                  />
                  <SwitchWithInfo
                    checked={state.problemProductsConfig.useSupplierPrices}
                    onChange={() => {
                      dispatch({
                        type: 'SET_PROBLEM_PRODUCTS_SETTINGS_SECTION',
                        payload: {
                          ...state.problemProductsConfig,
                          useSupplierPrices:
                            !state.problemProductsConfig.useSupplierPrices,
                        },
                      })
                    }}
                    testId="company-problem-products-settings-supplier-price-toggle"
                    label={translations.SupplierNetPriceToggleLabel}
                    infoTooltipText={translations.SupplierNetPriceToggleInfo}
                    updateDisabled={!isEditingProblemProductsSettings}
                  />
                  <SwitchWithInfo
                    checked={state.problemProductsConfig.useTradePrices}
                    onChange={() => {
                      dispatch({
                        type: 'SET_PROBLEM_PRODUCTS_SETTINGS_SECTION',
                        payload: {
                          ...state.problemProductsConfig,
                          useTradePrices:
                            !state.problemProductsConfig.useTradePrices,
                        },
                      })
                    }}
                    testId="company-problem-products-settings-trade-price-toggle"
                    label={translations.TradePriceToggleLabel}
                    infoTooltipText={translations.TradePriceToggleInfo}
                    updateDisabled={!isEditingProblemProductsSettings}
                  />
                  <Box sx={editFieldStyle}>
                    <NumericFormat
                      data-testid="company-problem-products-profitable-items-field"
                      customInput={TextField}
                      thousandSeparator={locale.thousandSeparator}
                      decimalSeparator={locale.decimalSeparator}
                      decimalScale={0}
                      fixedDecimalScale
                      allowNegative={false}
                      onValueChange={async (v) => {
                        const value = parseInt(v.value)
                        dispatch({
                          ...state.problemProductsConfig,
                          type: 'SET_PROBLEM_PRODUCTS_SETTINGS_SECTION',
                          payload: {
                            ...state.problemProductsConfig,
                            profitableProductsLength: value,
                          },
                        })
                      }}
                      autoComplete="off"
                      value={
                        state.problemProductsConfig.profitableProductsLength
                      }
                      size="small"
                      InputProps={{
                        sx: { '& input': { textAlign: 'right' } },
                      }}
                      sx={{
                        ...editFieldTextFieldStyle,
                      }}
                      disabled={!isEditingProblemProductsSettings}
                    />
                    <Box component="label">
                      {translations.TotalProfitableItemsAmountLabel}
                    </Box>
                    <InfoTooltip
                      text={translations.TotalProfitableItemsAmountInfo}
                      sx={{ marginRight: theme.spacing(1) }}
                    />
                  </Box>
                  <Box sx={editFieldStyle}>
                    <NumericFormat
                      data-testid="company-problem-products-threshold-profitable-items-field"
                      customInput={TextField}
                      thousandSeparator={locale.thousandSeparator}
                      decimalSeparator={locale.decimalSeparator}
                      decimalScale={0}
                      fixedDecimalScale
                      allowNegative={false}
                      onValueChange={async (v) => {
                        const value = parseInt(v.value)
                        dispatch({
                          type: 'SET_PROBLEM_PRODUCTS_SETTINGS_SECTION',
                          payload: {
                            ...state.problemProductsConfig,
                            profitThreshold: value,
                          },
                        })
                      }}
                      autoComplete="off"
                      value={state.problemProductsConfig.profitThreshold}
                      size="small"
                      InputProps={{
                        sx: { '& input': { textAlign: 'right' } },
                      }}
                      sx={{
                        ...editFieldTextFieldStyle,
                      }}
                      disabled={!isEditingProblemProductsSettings}
                    />
                    <Box component="label">
                      {translations.ThresholdProfitableItemsNumberLabel}
                    </Box>
                    <InfoTooltip
                      text={translations.ThresholdProfitableItemsNumberInfo}
                      sx={{ marginRight: theme.spacing(1) }}
                    />
                  </Box>
                  <Box sx={editFieldStyle}>
                    <NumericFormat
                      data-testid="company-problem-products-most-expensive-items-field"
                      customInput={TextField}
                      thousandSeparator={locale.thousandSeparator}
                      decimalSeparator={locale.decimalSeparator}
                      decimalScale={0}
                      fixedDecimalScale
                      allowNegative={false}
                      onValueChange={async (v) => {
                        const value = parseInt(v.value)
                        dispatch({
                          type: 'SET_PROBLEM_PRODUCTS_SETTINGS_SECTION',
                          payload: {
                            ...state.problemProductsConfig,
                            mostExpensiveProductsLength: value,
                          },
                        })
                      }}
                      autoComplete="off"
                      value={
                        state.problemProductsConfig.mostExpensiveProductsLength
                      }
                      size="small"
                      InputProps={{
                        sx: { '& input': { textAlign: 'right' } },
                      }}
                      sx={{
                        ...editFieldTextFieldStyle,
                      }}
                      disabled={!isEditingProblemProductsSettings}
                    />
                    <Box component="label">
                      {translations.MostExpensiveItemsAmountLabel}
                    </Box>
                    <InfoTooltip
                      text={translations.MostExpensiveItemsAmountInfo}
                      sx={{ marginRight: theme.spacing(1) }}
                    />
                  </Box>
                </EditableSection>
              </Box>
            </Box>
          </>
        </AutoSizingBox>
      </Box>
    </Box>
  )
}

export default CompanySettings
